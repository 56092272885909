import React from "react";
import CarDataServiceApi from "API/Services/CarDataService";
import { CitationInfoIcon, CitationErrorIcon } from "Assets/Icons";
import { useCarDataContext } from "Contexts";
import { AppButton, AppTypography } from "Layout/Components";
import { Redirect } from "react-router-dom";
import { routes } from "Routing/routes";
import { PaymentStatusPaper } from ".";
import styled from "styled-components";

interface StatusProps {
  isSuccess: boolean;
}
const SuccessMessageWrapper = styled.div`
  > div {
    margin: 4px;
  }
`;
export const Status: React.FC<StatusProps> = ({ isSuccess }) => {
  const storageCar = CarDataServiceApi.getStorageCar();
  const { setCarData, checkCarData } = useCarDataContext();
  React.useEffect(() => {
    storageCar && setCarData(storageCar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return storageCar ? (
    <PaymentStatusPaper>
      {isSuccess ? <CitationInfoIcon /> : <CitationErrorIcon />}
      <AppTypography
        variant="h3"
        weight="bold"
        color={isSuccess ? "textPrimary" : "error"}
        translation={
          isSuccess ? "COMMON_DIALOGS_SUCCESS" : "COMMON_PAGES_UNPAIDCITATION"
        }
      />
      {isSuccess ? (
        <SuccessMessageWrapper>
          <AppTypography
            align="center"
            translation="COMMON_PAGES_YOURCITATIONSUCCESSFULLYPAID"
          />
          <AppTypography
            align="center"
            translation="COMMON_PAGES_RECEIPTHASBEENEMAILED"
          />
        </SuccessMessageWrapper>
      ) : (
        <AppTypography
          variant="body1"
          align="center"
          translation="COMMON_PAGES_THEREAREUNPAIDCITATIONSISSUED"
        >
          {` ${storageCar?.plate ? storageCar?.plate : ""}`}
        </AppTypography>
      )}
      {!isSuccess && (
        <AppButton
          variant="outlined"
          translation="COMMON_BUTTONS_GOBACKTOCITATIONPAYMENT"
          onClick={() => checkCarData(storageCar)}
        />
      )}
    </PaymentStatusPaper>
  ) : (
    <Redirect to={routes.root} />
  );
};

export default Status;
