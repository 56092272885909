import { Box, Button, CircularProgress } from "@material-ui/core";
import { IAppealDetails } from "API/Interfaces";
import { DownloadIcon } from "Assets/Icons/Icons";
import { format } from "date-fns";
import { useTranslate } from "Hooks";
import { useDownloadFile } from "Hooks/useDownloadFile";
import { AppTypography } from "Layout/Components";
import AppDialog, { AppDialogProps } from "Layout/Components/AppDialog";
import { InlineWrapper } from "Layout/Components/InlineWrapper";
import React from "react";
import styled from "styled-components";

export interface DisputDetailsProps extends AppDialogProps {
  appealDetails: IAppealDetails | null;
}

const StyledInlineWrapper = styled(InlineWrapper)`
  justify-content: space-between;
  padding: 10px 0px;
`;

const StyledSeparator = styled.div`
  margin-top: 10px;
  border: 0.5px solid ${(_) => _.theme.palette.neutral.main};
`;

const StyledText = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  padding-left: 5px;
`;

const DisputeDetails: React.FC<DisputDetailsProps> = ({
  appealDetails,
  ...rest
}) => {
  const t = useTranslate();
  const { downloadAttachment } = useDownloadFile();

  return (
    <AppDialog title={t("COMMON_FORMS_DISPUTEDETAILS")} {...rest}>
      {appealDetails ? (
        <>
          <StyledInlineWrapper>
            <AppTypography weight={700} translation="COMMON_FORMS_DISPUTE" />
            <AppTypography>
              {format(appealDetails.appeal.createdDate, "MM/dd/yyyy HH:MM")}
            </AppTypography>
          </StyledInlineWrapper>
          <AppTypography>{appealDetails.appeal.reason}</AppTypography>
          {appealDetails.attachments && (
            <>
              <Box display="flex" justifyContent="space-between">
                {appealDetails.attachments &&
                  appealDetails.attachments.map((el) => (
                    <Button
                      onClick={() => downloadAttachment(el.id, el.fileName)}
                    >
                      <DownloadIcon />
                      <StyledText>
                        {el.fileName.length > 20
                          ? `${el.fileName.slice(0, 20)}...`
                          : el.fileName}
                      </StyledText>
                    </Button>
                  ))}
              </Box>
            </>
          )}
          {appealDetails.answer?.reason && appealDetails.answer.createdDate && (
            <>
              <StyledSeparator />
              <StyledInlineWrapper>
                <AppTypography weight={700} translation="COMMON_FORMS_ANSWER" />
                <AppTypography>
                  {format(appealDetails.answer.createdDate, "MM/dd/yyyy HH:MM")}
                </AppTypography>
              </StyledInlineWrapper>
              <AppTypography>{appealDetails.answer.reason}</AppTypography>
            </>
          )}
        </>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </AppDialog>
  );
};

export default DisputeDetails;
