import React from "react";
import styled from "styled-components";
import { themeComponents } from "../theme";
import Paginations from "@material-ui/lab/Pagination";

export interface PaginationProps {
  pages: number;
  currentPage: number;
  callback(page?: number): void;
  display?: boolean;
  topPadding?: number;
}

const PaginationContainer = styled.div<{ topPadding?: number }>`
  width: 100%;
  padding-top: ${(_) => _.theme.spacing(_.topPadding)}px;
`;
PaginationContainer.defaultProps = {
  topPadding: 0,
};
const PaginationStyled = styled(Paginations)`
  & .MuiPagination-ul {
    justify-content: flex-end;
  }
  & .MuiPaginationItem-root {
    font-weight: 700;
    color: ${themeComponents.primary};
  }
  & .MuiPaginationItem-icon {
    fill: ${themeComponents.primary};
  }
  & .Mui-selected {
    font-weight: 700;
    color: #fff;
  }
`;

export const AppPagination: React.FC<PaginationProps> = (props) => {
  const { display, currentPage, pages, callback, topPadding, ...rest } = props;
  return (
    <PaginationContainer topPadding={topPadding}>
      <PaginationStyled
        hidden={display}
        page={currentPage}
        count={pages}
        onChange={(_, p) => callback(p)}
        color="primary"
        {...rest}
      />
    </PaginationContainer>
  );
};

export default AppPagination;
