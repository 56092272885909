import { AppPaper } from "Layout/Components";
import styled from "styled-components";

interface SearchPaperProps {}

const StyledPaper = styled(AppPaper)`
  max-width: 100%;
  margin: 0 20px;
  width: calc(100% - 20px);
  padding: 5px;
  ${(_) => _.theme.breakpoints.up("lg")} {
    width: 1100px;
  }
`;

export const SearchPaper: React.FC<SearchPaperProps> = ({ children }) => {
  return <StyledPaper>{children}</StyledPaper>;
};

export default SearchPaper;
