import React from "react";
import styled from "styled-components";
import AppPaper from "Layout/Components/AppPaper";

const CitationListPaper = styled(AppPaper)`
  width: 100%;
  max-width: ${(_) => _.theme.breakpoints.values.xl}px;
  padding: 10px 12px;
  ${(_) => _.theme.breakpoints.up("md")} {
    padding: 16px;
  }
`;

export const CitationListWrapper: React.FC = (props) => {
  return <CitationListPaper>{props.children}</CitationListPaper>;
};

export default CitationListWrapper;
