import { apiClient, IResponseApi, urls } from "API/Client";

const EmailServiceApi = {
  /*
  API: [POST] redirect/mail-unassignment/{token}
  DOC: https://confluence.lbpro.pl/pages/viewpage.action?pageId=138347802
  */
  unassign: async (token: string) => {
    const res = await apiClient.post<IResponseApi<void>>(
      urls.citations.mailUnassignment(token)
    );
    return res.data.data;
  },
};

export default EmailServiceApi;
