import { apiClient, IResponseApi, urls } from "API/Client";
import {
  IPaymentResult,
  IPaymentStatusResult,
  IPaymentData,
} from "API/Interfaces";

export const PaymentServiceApi = {
  /*
  API: [POST] /citations/payment
  DOC: https://confluence.lbpro.pl/pages/viewpage.action?pageId=133007166
  */
  sendPaymentData: async (paymentData: IPaymentData): Promise<string> => {
    const res = await apiClient.post<IResponseApi<string>>(
      urls.citations.payment,
      paymentData
    );
    return res.data.data;
  },
  redirectToTezPay: async (paymentId: string): Promise<IPaymentResult> => {
    const res = await apiClient.get<IResponseApi<IPaymentResult>>(
      urls.citations.paymentRedirect(paymentId)
    );
    return res.data.data;
  },
  /*
  API: [GET] /citations/payment/{paymentId}/status
  DOC: https://confluence.lbpro.pl/pages/viewpage.action?pageId=133007182
  */
  getPaymentStatus: async (
    paymentId: string
  ): Promise<IPaymentStatusResult> => {
    const res = await apiClient.get<IResponseApi<IPaymentStatusResult>>(
      urls.citations.paymentStatus(paymentId)
    );
    return res.data.data;
  },
};

export default PaymentServiceApi;
