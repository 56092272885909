export const CITATIONS = "citations";
export const REDIRECT = "redirect";
export const PAY = "pay";
export const QR = "qr";

export const routes = {
  root: "/",
  citation: `/${CITATIONS}`,
  pay: `/${CITATIONS}/${PAY}`,
  payStatus: `/${CITATIONS}/${PAY}/:id`,
  qr: `/${QR}/:id`,
  redirect: `/${REDIRECT}`,
  mailUnassignment: `/mail-unassignment/:token`,
};

export const generateRoute = {
  payStatus: (id: string) => `/${CITATIONS}/${PAY}/${id}`,
  qr: (id: string) => `/${QR}/${id}`,
};
