import React from "react";
import { TranslationKeys } from "Translation";
import {
  FormHelperText as HelperText,
  FormHelperTextProps as HelperTextProps,
} from "@material-ui/core";
import { useTranslate } from "Hooks";

interface FormHelperTextProps extends HelperTextProps {
  translationLabel?: TranslationKeys;
}

export const FormHelperText: React.FC<FormHelperTextProps> = ({
  translationLabel,
  children,
  ...rest
}) => {
  const t = useTranslate();
  return (
    <HelperText {...rest}>
      {t(translationLabel)}
      {children}
    </HelperText>
  );
};

export default FormHelperText;
