import { IAddress } from "./";
export enum PaymentStatus {
  NotStarted,
  Started,
  Successful,
  Rejected,
  Failed,
  Cancelled,
}
export interface IPaymentData {
  contactDetails: {
    firstName: string;
    lastName: string;
    contact: IContact;
    addressDetails: IAddress;
  };
  isRegisteredOwner: boolean;
  citationIds: string[];
  plate: string;
}

export interface IDisputeData {
  reason: string;
  firstName: string;
  lastName: string;
  contact: IContact;
  addressDetails: IAddress;
  attachmentIds: string[];
}

export interface IContact {
  emailAddress: string;
  mobilePhoneNumber: string;
  mobilePhoneCountryCodeId: number;
}

export interface IPaymentResult {
  redirectionToken: string;
  redirectUrl: string;
}

export interface IPaymentStatusResult {
  transactionStatus: PaymentStatus;
}
