import { Paper, PaperProps } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";

export interface AppPaperProps {
  /** MUI spacing */
  padding?: number;
  /** in pixels */
  maxWidth?: number;
  /** in pixels */
  borderRadius?: number;
  fullWidth?: boolean;
}

const StyledPaper = styled(Paper)<{
  $padding?: number;
  $maxWidth?: number;
  $borderRadius?: number;
  $fullWidth?: boolean;
}>`
  padding: ${(_) => _.theme.spacing(_.$padding)}px;
  max-width: ${(_) => (_.$fullWidth ? "100%" : `${_.$maxWidth}px`)};
  ${(_) =>
    _.$borderRadius &&
    css`
      border-radius: ${_.$borderRadius}px;
    `}
`;
StyledPaper.defaultProps = {
  $padding: 7,
  $maxWidth: 450,
  $fullWidth: false,
};
export type Props = AppPaperProps & PaperProps;
export const AppPaper: React.FC<Props> = ({
  padding,
  borderRadius,
  maxWidth,
  fullWidth,
  ...props
}) => {
  return (
    <StyledPaper
      $padding={padding}
      $borderRadius={borderRadius}
      $maxWidth={maxWidth}
      $fullWidth={fullWidth}
      {...props}
    >
      {props.children}
    </StyledPaper>
  );
};

export default AppPaper;
