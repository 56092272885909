import { useTranslation } from "react-i18next";
import Translations from "Translation/translations";
import React from "react";

export const useTranslate = () => {
  const { t } = useTranslation();
  const translate = React.useCallback(
    (translation?: keyof typeof Translations) => {
      if (!translation) return "";
      return t(Translations[translation]);
    },
    [t]
  );
  return translate;
};

export default useTranslate;
