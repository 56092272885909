import { ErrorCodes } from "API/Interfaces/Errors";
import CitationServiceApi from "API/Services/CitationServiceApi";
import { useCarDataContext } from "Contexts";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "Routing/routes";

const getCitationIds = (query: string) =>
  new URLSearchParams(query).getAll("citationIds");

const getCitationNumber = (query: string) =>
  new URLSearchParams(query).get("citationNumber");

const EnforcementRedirect: React.FC = () => {
  const params = useLocation().search;
  const citationNumber = getCitationNumber(params);
  const citationIds = getCitationIds(params);
  const { getCarData, setStatusError, setCitationFromLink } =
    useCarDataContext();

  const { push } = useHistory();
  useEffect(() => {
    const handleRedirect = async (citationNumber: string) => {
      try {
        const location = await CitationServiceApi.getSelectedCitations(
          citationIds
        );
        await getCarData(citationNumber, false);
        push(routes.pay, { location });
      } catch {
        setStatusError(ErrorCodes.ResourceNotFound);
        push(routes.root, { noInit: true });
      } finally {
        setCitationFromLink(false);
      }
    };
    if (citationNumber && citationIds?.length) {
      setCitationFromLink(true);
      handleRedirect(citationNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default EnforcementRedirect;
