import { useCitationContext } from "Contexts";
import { Formik } from "formik";
import React from "react";
import styled from "styled-components";
import {
  CitationDetails,
  initialData,
  IPaymentDetailForm,
  PaymentDetailsPaper,
  PaymentForm,
  validationSchema,
} from "./Components";
import * as yup from "yup";
import { FormSubmitButton } from "Layout/FormComponents";
import useSendPayment from "./Hooks/useSendPayment";
import MultipleCitationDetails from "./Components/CitationDetails/MultipleCitationDetails";
import { AppealStatus, CheckboxType } from "API/Interfaces";
import AppTooltip from "Layout/Components/AppTooltip";
import { i18nt } from "Translation";

interface PaymentDetailsProps {}
const Form = styled.div`
  order: 2;
  width: 100%;
  ${(_) => _.theme.breakpoints.up("md")} {
    order: 1;
    margin-right: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(_) => _.theme.breakpoints.up("md")} {
    flex-direction: row;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export const PaymentDetails: React.FC<PaymentDetailsProps> = (props) => {
  const { selectedLocation } = useCitationContext();
  const [sendPayment, paymentStatus] = useSendPayment();

  const handleSendPayment = (e: IPaymentDetailForm) => {
    if (selectedLocation) {
      const citations = selectedLocation.citations.map((e) => e.id);
      sendPayment(e, citations);
    }
  };
  const isDisputeOpen = selectedLocation?.citations.some(
    (c) => c.appealStatus === AppealStatus.Open
  );

  return (
    <PaymentDetailsPaper>
      <Formik
        onSubmit={(e: IPaymentDetailForm) => handleSendPayment(e)}
        initialValues={initialData}
        validationSchema={yup.object(validationSchema)}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Wrapper>
              <Form>
                <PaymentForm checkbox={CheckboxType.HandleDispute} />
              </Form>
              {selectedLocation &&
                (selectedLocation.citations.length > 1 ? (
                  <MultipleCitationDetails
                    phoneNumber={selectedLocation.phoneNumber}
                    citations={selectedLocation.citations}
                  />
                ) : (
                  <CitationDetails
                    phoneNumber={selectedLocation.phoneNumber}
                    citation={selectedLocation.citations[0]}
                  />
                ))}
            </Wrapper>
            <ButtonWrapper>
              <AppTooltip
                disableHoverListener={!isDisputeOpen}
                enterTouchDelay={0}
                title={i18nt("COMMON_FORMS_CITATIONCANNOTBEPAID")}
                placement="top"
                arrow
              >
                <div>
                  <FormSubmitButton
                    disabled={isDisputeOpen || paymentStatus.loading}
                    translation="COMMON_BUTTONS_PAY"
                    loading={paymentStatus.loading}
                  />
                </div>
              </AppTooltip>
            </ButtonWrapper>
          </form>
        )}
      </Formik>
    </PaymentDetailsPaper>
  );
};

export default PaymentDetails;
