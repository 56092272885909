import { createTheme } from "@material-ui/core/styles";

export const themeComponents = {
  primary: "#00C3FF",
  primaryLight: "#99e7ff",
  primaryDark: "#0896C1",
  secondary: "#7B59FB",
  titleText: "#000000",
  neutral: "#C4C4C4",
  secoundNeutral: "#DBDBDB",
  lightNeutral: "#F2F2F2",
  darkNeutral: "#858585",
  fontFamily: '"Montserrat", sans-serif',
};

export const mobileTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});

export const theme = createTheme({
  palette: {
    primary: {
      main: themeComponents.primary,
      light: themeComponents.primaryLight,
      dark: themeComponents.primaryDark,
      contrastText: "#fff",
    },
    secondary: {
      main: themeComponents.secondary,
    },
    neutral: {
      light: themeComponents.lightNeutral,
      main: themeComponents.neutral,
      secound: themeComponents.secoundNeutral,
      dark: themeComponents.darkNeutral,
    },
  },
  typography: {
    fontFamily: themeComponents.fontFamily,
    h1: {
      [mobileTheme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
      [mobileTheme.breakpoints.up("md")]: {
        fontSize: "1.5rem",
      },
    },
    h2: {
      fontSize: "1.25rem",
    },
    h3: {
      [mobileTheme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
      [mobileTheme.breakpoints.up("md")]: {
        fontSize: "1.25rem",
      },
    },
    body1: {
      fontSize: "0.875rem",
    },
    subtitle2: {
      fontSize: "0.875rem",
    },
  },

  breakpoints: mobileTheme.breakpoints,
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 16,
        [mobileTheme.breakpoints.up("md")]: {
          borderRadius: 20,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 22,
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: "0 0 0 0",
        "&.Mui-expanded": {
          margin: 0,
        },
        "&.Mui-expanded:before": {
          opacity: "1 !important",
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        "&.Mui-expanded": {
          margin: "12px 0",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: "#FFF",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
      edgeEnd: {
        marginRight: 0,
      },
    },
    MuiInputLabel: {
      asterisk: {
        color: "#f82222",
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: 8,
      },
    },
    MuiInputBase: {
      root: {
        height: 40,
      },
    },
    MuiDialog: {
      paper: {
        margin: "0",
        maxWidth: "500px",
      },
      paperWidthXs: {
        maxWidth: "500px",
      },
    },
    MuiDialogTitle: {
      root: {
        fontFamily: themeComponents.fontFamily,
        fontWeight: 700,
        fontSize: "1.25rem",
        borderBottom: "1px solid #C4C4C4",
        padding: "24px 50px",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "39px 50px 15px 50px",
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: "center",
        padding: "30px 0",
        "&.MuiPickersModal-withAdditionalAction": {
          paddingLeft: 10,
          paddingRight: 10,
          "&>*": {
            fontWeight: 500,
          },
        },
      },
    },
  },
});
