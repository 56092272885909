import { apiClient, IResponseApi, urls } from "API/Client";
import { IDictionaries } from "API/Interfaces";

const DictionaryServiceApi = {
  /*
  API: [GET] /general/dictionaries
  DOC: https://confluence.lbpro.pl/pages/viewpage.action?pageId=133007133
  */
  getDictionaries: async () => {
    const res = await apiClient.get<IResponseApi<IDictionaries>>(
      urls.dictionaries
    );
    return res.data.data;
  },
};

export default DictionaryServiceApi;
