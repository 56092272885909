import { useState, useRef, useEffect, useCallback } from "react";
import { IPages, IPagination } from "API/Interfaces/IPagination";
import { useLoaderContext } from "Contexts";

export const useGenericList = <T,>(
  getFromService: (p: number, s: string, ...args: any) => Promise<IPages<T>>,
  params?: any
) => {
  const [data, setData] = useState<T[] | null>(null);
  const dataPages = useRef<IPagination<T>[]>([]);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [restData, setRestData] = useState<any>(null);
  const { setLoading } = useLoaderContext();

  const getData = useCallback(
    async (startingPage = 1, searchPhrase = "", params?: any): Promise<T[]> => {
      const savedData = dataPages.current.find(
        (l) => l.pageNumber === startingPage
      );
      if (savedData) {
        setData(savedData.listOfElements);
        setPage(savedData.pageNumber);
        return savedData.listOfElements;
      } else {
        setLoading(true);
        try {
          const res = await getFromService(startingPage, searchPhrase, params);
          const { pages, numberOfPagesTotal, ...rest } = res;
          setRestData(rest);
          setNumberOfPages(numberOfPagesTotal);
          dataPages.current = [...dataPages.current, ...pages];
          setData(pages[0]?.listOfElements || []);
          setPage(pages[0]?.pageNumber || 1);
          return pages[0]?.listOfElements;
        } catch (err) {
          console.error(err);
          // setPopup({ variant: "error" });
          // showPopup();
          return [];
        } finally {
          setLoading(false);
        }
      }
    },
    [getFromService, setLoading]
  );

  const getClearData = useCallback(
    async (params?: any): Promise<T[]> => {
      dataPages.current = [];
      return await getData(1, search, params);
    },
    [getData, search]
  );

  useEffect(() => {
    getClearData(params);
  }, [getClearData, params]);

  return {
    data,
    page,
    search,
    setSearch,
    numberOfPages,
    getData,
    getClearData,
    restData,
  };
};

export default useGenericList;
