import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TimerIcon } from "Assets/Icons";
import { i18nt } from "Translation";
import AppTooltip from "./AppTooltip";
import { useTimerContext } from "Contexts/TimerContext";
import { getTimeToCountdown } from "Utils/utils";

interface CountdownTimerProps {}

const TimerContainer = styled.div`
  background-color: ${(_) => _.theme.palette.primary.main}1a;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  height: 31px;
  padding: 5px 6px;
  ${(_) => _.theme.breakpoints.up("md")} {
    height: 41px;
    padding: 10px;
    margin-right: 20px;
  }
`;

const Timer = styled.div`
  color: ${(_) => _.theme.palette.primary.main};
  width: 28px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  display: flex;
  align-items: center;
  line-height: 14px;
`;

export const CountdownTimer: React.FC<CountdownTimerProps> = () => {
  const [timer, setTimer] = useState<string>("");

  const { timerExpire } = useTimerContext();
  const [timeLeft, setTimeLeft] = useState<number>(
    getTimeToCountdown(timerExpire)
  );

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTimeLeft((prev) => prev - 1000);
    }, 1000);
    const timeout = setTimeout(() => {
      clearInterval(countdownInterval);
    }, timeLeft);
    return () => {
      clearInterval(countdownInterval);
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    setTimer(`${minutes}:${seconds < 10 ? "0" + seconds : seconds}`);
  }, [timeLeft]);

  return (
    <AppTooltip
      title={i18nt("COMMON_PAGES_COUNTDOWNTOOLTIPMSG")}
      placement="bottom"
      arrow
    >
      <TimerContainer>
        <TimerIcon />
        <Timer>{timer}</Timer>
      </TimerContainer>
    </AppTooltip>
  );
};

export default CountdownTimer;
