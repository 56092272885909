import React from "react";
import {
  Radio,
  FormControlLabel,
  FormControlLabelProps as MUIFormControlLabelProps,
} from "@material-ui/core";
import styled from "styled-components";
const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
`;
const StyledRadio = styled(Radio)`
  color: ${(_) => _.theme.palette.primary.main};
  &checked {
    color: ${(_) => _.theme.palette.primary.main};
  }
`;
export interface AppRadioButtonProps
  extends Omit<MUIFormControlLabelProps, "control"> {
  control?: any;
}

const AppRadioButton: React.FC<AppRadioButtonProps> = (props) => {
  return (
    <StyledFormControlLabel
      control={<StyledRadio color="default" />}
      {...props}
    />
  );
};

export default AppRadioButton;
