export const TezLogo = () => {
  return (
    <svg
      width="84"
      height="55"
      viewBox="0 0 84 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.3307 23.4206C41.3134 23.4206 43.2993 23.4206 45.2853 23.4206C46.0424 23.4206 46.3729 23.2056 46.0649 22.4003C45.0254 19.6958 42.2983 17.8831 39.2344 17.8799C36.1705 17.8766 33.8284 19.5514 32.7055 22.3715C32.3847 23.1543 32.5419 23.4206 33.3728 23.411C35.3556 23.3917 37.3415 23.411 39.3307 23.411V23.4206ZM53.4119 28.5988H32.3205C32.6125 32.4038 35.763 35.0154 39.613 34.7395C41.7786 34.5951 43.6137 33.8123 44.958 32.022C45.1711 31.7193 45.4951 31.513 45.8595 31.4478C48.2626 31.3996 50.2613 31.4189 52.6515 31.4189C53.1713 31.4189 53.075 31.6884 53.0686 31.8295C52.9146 34.7587 48.577 39.2985 44.5377 40.7101C37.8003 43.0746 30.5945 40.7422 26.8183 34.9641C22.9908 29.1314 23.6677 20.963 28.4032 15.8939C32.9429 11.0301 40.8033 9.86231 46.6905 13.1957C52.3243 16.404 54.7658 22.9008 53.8354 28.14C53.8065 28.3004 53.7616 28.6212 53.4215 28.6212"
        fill="#7A59FA"
      />
      <path
        d="M68.1248 33.716H79.5239C79.8223 33.716 80.0533 33.947 80.0533 34.3962V40.1037C80.0589 40.183 80.0476 40.2626 80.02 40.3371C79.9924 40.4116 79.9492 40.4794 79.8933 40.5358C79.8374 40.5923 79.7701 40.6362 79.6959 40.6646C79.6217 40.693 79.5423 40.7053 79.463 40.7005H57.268C56.2798 40.6716 56.7867 39.5359 57.0626 39.061C60.7094 32.8369 64.3711 26.6193 68.0479 20.408C68.2339 20.0872 68.3943 19.7663 68.6895 19.237C68.0992 19.1953 67.7046 19.1439 67.3099 19.1439C64.5925 19.1439 62.7574 19.1439 60.0367 19.1439C58.5673 19.1439 58.9972 18.5793 58.2401 17.1355C56.883 14.54 55.9141 13.3594 55.9141 13.0225C55.9141 12.3295 56.1547 12.0825 56.8509 12.0857C63.7423 12.0857 72.3887 12.0857 79.2801 12.0857C80.2233 12.0857 80.464 12.6599 79.8961 13.6898C78.9336 15.3902 77.9358 17.0553 76.9445 18.7333C74.3072 23.2056 71.6679 27.678 69.0264 32.1504C68.7793 32.5707 68.5516 33.0006 68.1409 33.7545"
        fill="#7A59FA"
      />
      <path
        d="M0.830893 18.252C-0.426761 18.1782 -0.0353482 17.2189 0.561396 16.4842C4.02636 12.2397 7.81215 7.98225 10.9499 4.37291C11.8546 3.33342 13.2278 2.73347 13.2278 4.59108C13.2278 6.44868 13.2278 8.64637 13.2278 10.5842C13.2278 12.0696 13.2278 12.0696 14.7453 12.0696C16.5066 12.0696 20.4593 12.0696 22.2206 12.0696C22.7275 12.0696 23.2024 12.3905 22.8174 13.0321C22.6505 13.3177 21.0271 15.5506 20.3277 17.7996C20.2937 17.9372 20.2152 18.0596 20.1044 18.1478C19.9935 18.236 19.8566 18.2851 19.7149 18.2873C17.7098 18.2873 16.9783 18.2616 14.5817 18.2616C13.5005 18.2616 13.2117 18.5825 13.231 19.6284C13.2887 22.9554 13.231 26.2856 13.2566 29.619C13.2566 32.2145 14.7485 33.7513 17.3472 33.8668C18.3097 33.9085 19.2722 33.9214 20.2347 33.8893C21.0015 33.8604 21.3544 34.1138 21.3351 34.9352C21.2934 36.581 21.3544 38.2269 21.2966 39.8695C21.2966 40.1904 21.1362 40.7326 20.2186 40.7326C17.0296 40.8288 14.5207 41.1914 11.5177 40.0717C8.41531 38.9295 6.41654 36.735 5.95455 33.5075C5.57597 30.8799 5.63372 28.1849 5.56634 25.522C5.5118 23.5297 5.50218 21.5373 5.56634 19.5482C5.60805 18.5087 5.24551 18.2937 4.24452 18.2648C2.88741 18.2392 2.08855 18.2392 0.821268 18.2392"
        fill="#7A59FA"
      />
      <path
        d="M7.37905 48.9972H5.90965C5.8617 48.9963 5.81601 48.9767 5.7824 48.9425C5.7488 48.9083 5.72997 48.8622 5.72998 48.8143V48.0315C5.72997 47.9835 5.7488 47.9375 5.7824 47.9033C5.81601 47.8691 5.8617 47.8494 5.90965 47.8486H10.0804C10.1043 47.8486 10.1279 47.8533 10.1499 47.8626C10.1719 47.8718 10.1919 47.8853 10.2086 47.9023C10.2253 47.9194 10.2385 47.9395 10.2474 47.9617C10.2562 47.9839 10.2605 48.0076 10.2601 48.0315V48.8143C10.2605 48.8381 10.2562 48.8619 10.2474 48.884C10.2385 48.9062 10.2253 48.9264 10.2086 48.9434C10.1919 48.9604 10.1719 48.9739 10.1499 48.9832C10.1279 48.9924 10.1043 48.9972 10.0804 48.9972H8.62387V54.355C8.62232 54.4025 8.60298 54.4476 8.5697 54.4815C8.53642 54.5154 8.49163 54.5355 8.4442 54.5379H7.5555C7.50751 54.5363 7.46192 54.5165 7.42797 54.4826C7.39401 54.4486 7.37423 54.403 7.37263 54.355L7.37905 48.9972Z"
        fill="black"
      />
      <path
        d="M12.7915 48.0315C12.7915 47.9835 12.8103 47.9375 12.8439 47.9033C12.8775 47.8691 12.9232 47.8494 12.9712 47.8486H16.8596C16.9081 47.8486 16.9546 47.8678 16.9889 47.9021C17.0232 47.9364 17.0425 47.983 17.0425 48.0315V48.8143C17.0425 48.8628 17.0232 48.9093 16.9889 48.9436C16.9546 48.9779 16.9081 48.9972 16.8596 48.9972H14.0331V50.5628H16.3912C16.4392 50.5644 16.4848 50.5842 16.5188 50.6181C16.5527 50.6521 16.5725 50.6977 16.5741 50.7457V51.5381C16.5741 51.562 16.5693 51.5856 16.5601 51.6076C16.5509 51.6296 16.5374 51.6496 16.5203 51.6663C16.5033 51.683 16.4831 51.6962 16.461 51.705C16.4388 51.7139 16.4151 51.7182 16.3912 51.7178H14.0331V53.3925H16.8596C16.8835 53.3921 16.9072 53.3964 16.9294 53.4053C16.9515 53.4141 16.9717 53.4273 16.9887 53.444C17.0058 53.4607 17.0193 53.4807 17.0285 53.5027C17.0378 53.5247 17.0425 53.5483 17.0425 53.5722V54.355C17.0425 54.4035 17.0232 54.45 16.9889 54.4843C16.9546 54.5186 16.9081 54.5379 16.8596 54.5379H12.9712C12.9232 54.537 12.8775 54.5174 12.8439 54.4832C12.8103 54.449 12.7915 54.403 12.7915 54.355V48.0315Z"
        fill="black"
      />
      <path
        d="M22.4899 47.7555C22.9145 47.7442 23.3372 47.8169 23.7336 47.9694C24.13 48.1219 24.4924 48.3513 24.7999 48.6442C24.819 48.6608 24.8342 48.6813 24.8447 48.7042C24.8552 48.7272 24.8606 48.7521 24.8606 48.7774C24.8606 48.8026 24.8552 48.8276 24.8447 48.8505C24.8342 48.8735 24.819 48.894 24.7999 48.9105L24.2192 49.5137C24.2045 49.5312 24.186 49.5453 24.1653 49.555C24.1445 49.5647 24.1218 49.5697 24.0989 49.5697C24.076 49.5697 24.0533 49.5647 24.0325 49.555C24.0118 49.5453 23.9933 49.5312 23.9786 49.5137C23.573 49.1543 23.0511 48.9538 22.5092 48.949C21.9212 48.949 21.3573 49.1826 20.9416 49.5983C20.5258 50.0141 20.2923 50.578 20.2923 51.166C20.2923 51.7539 20.5258 52.3178 20.9416 52.7336C21.3573 53.1493 21.9212 53.3829 22.5092 53.3829C23.0445 53.3767 23.5608 53.1838 23.969 52.8375C24.0016 52.807 24.0446 52.7901 24.0893 52.7901C24.1339 52.7901 24.1769 52.807 24.2096 52.8375L24.7999 53.4471C24.8171 53.4641 24.8307 53.4843 24.84 53.5066C24.8493 53.5289 24.8541 53.5528 24.8541 53.577C24.8541 53.6012 24.8493 53.6251 24.84 53.6474C24.8307 53.6697 24.8171 53.6899 24.7999 53.7069C24.1793 54.31 23.3456 54.6432 22.4803 54.6341C21.5682 54.6341 20.6934 54.2718 20.0484 53.6268C19.4034 52.9818 19.041 52.107 19.041 51.1948C19.041 50.2827 19.4034 49.4079 20.0484 48.7629C20.6934 48.1179 21.5682 47.7555 22.4803 47.7555"
        fill="black"
      />
      <path
        d="M27.2095 48.0315C27.2111 47.9835 27.2309 47.9379 27.2648 47.9039C27.2988 47.87 27.3444 47.8502 27.3923 47.8486H28.281C28.3049 47.8486 28.3285 47.8533 28.3505 47.8626C28.3726 47.8718 28.3925 47.8853 28.4092 47.9023C28.426 47.9194 28.4391 47.9395 28.448 47.9617C28.4568 47.9839 28.4611 48.0076 28.4607 48.0315V50.5628H31.576V48.0315C31.576 47.983 31.5952 47.9364 31.6295 47.9021C31.6638 47.8678 31.7103 47.8486 31.7588 47.8486H32.6379C32.6853 47.851 32.7301 47.8711 32.7634 47.905C32.7967 47.9388 32.816 47.984 32.8176 48.0315V54.355C32.816 54.4025 32.7967 54.4476 32.7634 54.4815C32.7301 54.5154 32.6853 54.5355 32.6379 54.5379H31.7621C31.7135 54.5379 31.667 54.5186 31.6327 54.4843C31.5984 54.45 31.5792 54.4035 31.5792 54.355V51.7178H28.4607V54.355C28.4611 54.3789 28.4568 54.4026 28.448 54.4248C28.4391 54.4469 28.426 54.4671 28.4092 54.4841C28.3925 54.5012 28.3726 54.5147 28.3505 54.5239C28.3285 54.5331 28.3049 54.5379 28.281 54.5379H27.3923C27.3444 54.5363 27.2988 54.5165 27.2648 54.4826C27.2309 54.4486 27.2111 54.403 27.2095 54.355V48.0315Z"
        fill="black"
      />
      <path
        d="M35.7051 47.9256C35.7068 47.8793 35.7265 47.8356 35.7601 47.8038C35.7937 47.772 35.8385 47.7547 35.8847 47.7555H36.1254L40.1037 51.9745V48.0282C40.1036 47.9803 40.1225 47.9343 40.1561 47.9001C40.1897 47.8658 40.2354 47.8462 40.2833 47.8454H41.1624C41.2104 47.847 41.256 47.8668 41.2899 47.9007C41.3239 47.9347 41.3437 47.9803 41.3453 48.0282V54.4448C41.3436 54.4919 41.3235 54.5365 41.2893 54.5689C41.2551 54.6013 41.2095 54.619 41.1624 54.6181H40.9346L36.9403 50.2323V54.339C36.9403 54.3869 36.9215 54.433 36.8878 54.4672C36.8542 54.5014 36.8086 54.521 36.7606 54.5218H35.888C35.8405 54.5195 35.7957 54.4993 35.7624 54.4655C35.7292 54.4316 35.7098 54.3864 35.7083 54.339L35.7051 47.9256Z"
        fill="black"
      />
      <path
        d="M47.1909 53.3925C47.6264 53.3944 48.0526 53.2669 48.4155 53.0262C48.7784 52.7854 49.0616 52.4423 49.2291 52.0403C49.3966 51.6383 49.441 51.1957 49.3565 50.7685C49.272 50.3413 49.0625 49.9488 48.7545 49.6409C48.4466 49.3329 48.0541 49.1234 47.6269 49.0389C47.1997 48.9544 46.7571 48.9988 46.3551 49.1663C45.9531 49.3338 45.61 49.617 45.3692 49.9799C45.1285 50.3428 45.001 50.769 45.0029 51.2045C45.0071 51.7835 45.239 52.3376 45.6484 52.747C46.0578 53.1564 46.6119 53.3883 47.1909 53.3925ZM47.1909 47.7555C47.8712 47.7536 48.5367 47.9535 49.1033 48.3298C49.67 48.7062 50.1123 49.2421 50.3744 49.8698C50.6364 50.4975 50.7065 51.1888 50.5756 51.8564C50.4448 52.5239 50.1189 53.1376 49.6393 53.62C49.1597 54.1023 48.5478 54.4316 47.881 54.5661C47.2142 54.7007 46.5225 54.6345 45.8933 54.376C45.2641 54.1174 44.7257 53.6781 44.3462 53.1136C43.9668 52.5491 43.7632 51.8847 43.7613 51.2045C43.7592 50.7526 43.8463 50.3047 44.0177 49.8866C44.189 49.4684 44.4413 49.0882 44.7599 48.7678C45.0786 48.4474 45.4573 48.193 45.8745 48.0193C46.2917 47.8456 46.739 47.756 47.1909 47.7555Z"
        fill="black"
      />
      <path
        d="M52.8374 48.0315C52.8374 47.983 52.8567 47.9364 52.891 47.9021C52.9253 47.8678 52.9718 47.8486 53.0203 47.8486H53.8993C53.947 47.8502 53.9923 47.8701 54.0257 47.9041C54.0591 47.9382 54.0782 47.9838 54.079 48.0315V53.3925H56.5173C56.565 53.3925 56.6107 53.4115 56.6444 53.4451C56.6781 53.4788 56.697 53.5245 56.697 53.5722V54.355C56.697 54.403 56.6782 54.449 56.6446 54.4832C56.611 54.5174 56.5653 54.537 56.5173 54.5379H53.0203C52.9718 54.5379 52.9253 54.5186 52.891 54.4843C52.8567 54.45 52.8374 54.4035 52.8374 54.355V48.0315Z"
        fill="black"
      />
      <path
        d="M61.3135 53.3925C61.749 53.3944 62.1752 53.2669 62.5381 53.0262C62.901 52.7854 63.1841 52.4423 63.3517 52.0403C63.5192 51.6383 63.5635 51.1957 63.479 50.7685C63.3945 50.3413 63.185 49.9488 62.8771 49.6409C62.5692 49.3329 62.1767 49.1234 61.7495 49.0389C61.3223 48.9544 60.8796 48.9988 60.4777 49.1663C60.0757 49.3338 59.7326 49.617 59.4918 49.9799C59.2511 50.3428 59.1235 50.769 59.1254 51.2045C59.1296 51.7835 59.3615 52.3376 59.7709 52.747C60.1804 53.1564 60.7345 53.3883 61.3135 53.3925ZM61.3135 47.7555C61.9937 47.7536 62.6592 47.9535 63.2259 48.3298C63.7925 48.7062 64.2348 49.2421 64.4969 49.8698C64.759 50.4975 64.829 51.1888 64.6982 51.8564C64.5673 52.5239 64.2415 53.1376 63.7619 53.62C63.2822 54.1023 62.6703 54.4316 62.0035 54.5661C61.3367 54.7007 60.645 54.6345 60.0159 54.376C59.3867 54.1174 58.8483 53.6781 58.4688 53.1136C58.0893 52.5491 57.8857 51.8847 57.8838 51.2045C57.8817 50.7526 57.9688 50.3047 58.1402 49.8866C58.3116 49.4684 58.5638 49.0882 58.8825 48.7678C59.2011 48.4474 59.5799 48.193 59.9971 48.0193C60.4142 47.8456 60.8616 47.756 61.3135 47.7555Z"
        fill="black"
      />
      <path
        d="M70.1043 47.7555C70.9626 47.7652 71.7895 48.0803 72.4367 48.6442C72.4545 48.6616 72.4686 48.6823 72.4782 48.7051C72.4878 48.728 72.4928 48.7526 72.4928 48.7774C72.4928 48.8022 72.4878 48.8268 72.4782 48.8496C72.4686 48.8725 72.4545 48.8932 72.4367 48.9105L71.8656 49.5329C71.8497 49.5502 71.8304 49.5641 71.8088 49.5735C71.7873 49.583 71.764 49.5878 71.7405 49.5878C71.717 49.5878 71.6937 49.583 71.6722 49.5735C71.6506 49.5641 71.6313 49.5502 71.6154 49.5329C71.2203 49.1762 70.7071 48.9785 70.1748 48.9779C69.6053 48.9996 69.0662 49.2412 68.6709 49.6519C68.2756 50.0625 68.0548 50.6104 68.0548 51.1804C68.0548 51.7504 68.2756 52.2982 68.6709 52.7089C69.0662 53.1196 69.6053 53.3612 70.1748 53.3829C70.5656 53.3783 70.9516 53.2965 71.3106 53.1423V52.3306H70.547C70.5236 52.3314 70.5002 52.3276 70.4783 52.3193C70.4563 52.311 70.4362 52.2985 70.4192 52.2823C70.4022 52.2662 70.3885 52.2468 70.3791 52.2254C70.3696 52.2039 70.3646 52.1808 70.3641 52.1573V51.4034C70.3632 51.3793 70.3673 51.3552 70.3762 51.3328C70.3851 51.3103 70.3986 51.29 70.4158 51.2731C70.4331 51.2562 70.4536 51.2431 70.4762 51.2345C70.4988 51.226 70.5229 51.2224 70.547 51.2237H72.3822C72.4284 51.2254 72.4721 51.2451 72.5039 51.2788C72.5357 51.3124 72.5531 51.3571 72.5522 51.4034V53.8481C72.5527 53.8781 72.546 53.9077 72.5326 53.9345C72.5192 53.9613 72.4995 53.9845 72.4752 54.0021C71.7528 54.4102 70.9372 54.6246 70.1075 54.6245C69.2148 54.5972 68.3678 54.2234 67.7459 53.5823C67.1241 52.9412 66.7764 52.0831 66.7764 51.19C66.7764 50.2969 67.1241 49.4389 67.7459 48.7977C68.3678 48.1566 69.2148 47.7828 70.1075 47.7555"
        fill="black"
      />
      <path
        d="M76.3834 51.3328L74.2049 48.1245C74.1857 48.0976 74.1743 48.0659 74.1722 48.0328C74.1701 47.9998 74.1774 47.9669 74.1931 47.9378C74.2088 47.9087 74.2324 47.8846 74.2611 47.8683C74.2899 47.8519 74.3227 47.844 74.3557 47.8454H75.3182C75.3489 47.8465 75.3789 47.8549 75.4057 47.87C75.4325 47.885 75.4553 47.9063 75.4722 47.932L76.9994 50.1297L78.5297 47.932C78.5459 47.9064 78.5681 47.8851 78.5944 47.87C78.6206 47.8549 78.6502 47.8464 78.6805 47.8454H79.6655C79.6983 47.8446 79.7308 47.8528 79.7593 47.8692C79.7878 47.8855 79.8113 47.9094 79.8272 47.9382C79.8431 47.967 79.8507 47.9996 79.8494 48.0324C79.848 48.0653 79.8377 48.0971 79.8194 48.1245L77.641 51.3071V54.355C77.6394 54.403 77.6196 54.4486 77.5857 54.4825C77.5517 54.5165 77.5061 54.5363 77.4581 54.5379H76.5791C76.5311 54.537 76.4854 54.5174 76.4518 54.4832C76.4182 54.449 76.3994 54.403 76.3994 54.355L76.3834 51.3328Z"
        fill="black"
      />
      <path
        d="M16.6832 3.29643e-07C16.5179 -0.00016711 16.3589 0.0634583 16.2394 0.177617C16.1198 0.291776 16.0489 0.447661 16.0415 0.612786C16.0415 1.2352 16.5709 1.3154 16.8725 1.34749C20.4016 1.74211 22.8271 4.64562 22.9939 7.501C23.0228 7.98225 23.2955 8.36404 23.7478 8.36404C24.2002 8.36404 24.3895 7.74483 24.367 7.26038C24.1842 3.64463 20.5331 0.060958 16.6832 3.29643e-07Z"
        fill="black"
      />
      <path
        d="M21.367 7.68068C21.3221 5.16216 18.9704 2.98694 16.7118 2.97731C16.4262 2.97731 16.0252 3.23397 16.0252 3.56443C16.0108 3.75092 16.0657 3.93616 16.1794 4.08472C16.293 4.23328 16.4574 4.33475 16.6412 4.36971C18.6913 4.64883 19.7147 5.72361 20.0452 7.74484C20.0645 7.91497 20.148 8.07134 20.2785 8.18219C20.409 8.29303 20.5768 8.35002 20.7478 8.34159C21.0943 8.34159 21.3157 8.12984 21.367 7.67426"
        fill="black"
      />
      <path
        d="M18.6563 7.69992C18.6753 7.43244 18.6361 7.16402 18.5415 6.9131C18.447 6.66219 18.2992 6.43472 18.1084 6.24633C17.9175 6.05794 17.6882 5.9131 17.4361 5.82176C17.184 5.73042 16.9151 5.69475 16.6479 5.71719C16.4986 5.72268 16.3552 5.77671 16.2394 5.87109C16.1236 5.96547 16.0417 6.09503 16.0062 6.24014C15.9765 6.3793 15.9999 6.52453 16.0717 6.64737C16.1435 6.77021 16.2586 6.86182 16.3944 6.90426C17.0746 7.07109 17.2895 7.15771 17.466 7.86675C17.5152 7.99805 17.6027 8.11161 17.717 8.1928C17.8314 8.27399 17.9674 8.3191 18.1076 8.32233C18.2547 8.29313 18.3881 8.21638 18.4872 8.10391C18.5863 7.99143 18.6457 7.84948 18.6563 7.69992Z"
        fill="black"
      />
      <path
        d="M83.9999 7.21547C84.0018 7.57466 83.8969 7.92632 83.6986 8.22582C83.5003 8.52532 83.2175 8.75915 82.8861 8.89763C82.5546 9.03612 82.1895 9.07302 81.8371 9.00364C81.4847 8.93427 81.1608 8.76175 80.9066 8.50799C80.6524 8.25422 80.4793 7.93065 80.4093 7.57834C80.3393 7.22603 80.3755 6.86086 80.5134 6.52919C80.6513 6.19752 80.8846 5.91429 81.1838 5.71545C81.4829 5.51662 81.8344 5.41114 82.1936 5.41241C82.432 5.40717 82.6689 5.4502 82.8902 5.53891C83.1115 5.62763 83.3126 5.7602 83.4813 5.92864C83.6501 6.09708 83.783 6.29791 83.8721 6.51906C83.9612 6.74021 84.0047 6.9771 83.9999 7.21547ZM83.8042 7.21547C83.8081 7.00373 83.7693 6.79338 83.69 6.59698C83.6108 6.40059 83.4928 6.22219 83.343 6.07244C83.1933 5.92269 83.0149 5.80468 82.8185 5.72545C82.6221 5.64622 82.4117 5.6074 82.2 5.61132C81.8773 5.60752 81.5608 5.69997 81.2909 5.87688C81.021 6.05379 80.81 6.30712 80.6847 6.60453C80.5595 6.90194 80.5257 7.22993 80.5877 7.54662C80.6498 7.86332 80.8048 8.15434 81.033 8.38252C81.2611 8.61071 81.5522 8.76572 81.8689 8.82774C82.1855 8.88977 82.5135 8.856 82.811 8.73075C83.1084 8.6055 83.3617 8.39445 83.5386 8.12455C83.7155 7.85465 83.808 7.53816 83.8042 7.21547ZM82.6395 7.5363L83.1208 8.27421H82.8449L82.3989 7.58122C82.3594 7.5844 82.3197 7.5844 82.2802 7.58122H81.7444V8.26779H81.491V6.18882H82.2931C82.8 6.18882 83.1144 6.4551 83.1144 6.87539C83.1208 7.02133 83.0773 7.1651 82.9909 7.28294C82.9046 7.40078 82.7806 7.48563 82.6395 7.52347V7.5363ZM82.8385 6.89464C82.8385 6.60269 82.6299 6.43265 82.2642 6.43265H81.7412V7.34701H82.2642C82.6299 7.34701 82.8385 7.17376 82.8385 6.87539V6.89464Z"
        fill="black"
      />
    </svg>
  );
};

export default TezLogo;
