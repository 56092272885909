import { useFormikContext } from "formik";
import React from "react";
import { NumberFormatProps } from "react-number-format";
import { FormTextFieldProps } from ".";
import FormNumberInput from "./FormNumberInput";

export interface FormCurrencyInputProps extends NumberFormatProps<any> {
  fieldName: string;
  textFieldProps?: Partial<FormTextFieldProps>;
}

export const FormCurrencyInput: React.FC<FormCurrencyInputProps> = ({
  fieldName,
  textFieldProps,
  ...props
}) => {
  const ref = React.useRef<number | null>(null);
  const { setFieldValue, validateField } = useFormikContext<any>();

  return (
    <FormNumberInput
      fieldName={fieldName}
      allowedDecimalSeparators={[".", ","]}
      decimalScale={2}
      allowNegative={false}
      fixedDecimalScale
      prefix={"$"}
      placeholder={`$0.00`}
      onValueChange={(e) => {
        if (typeof e.floatValue !== "number") {
          return (ref.current = null);
        }
        ref.current = e.floatValue;
      }}
      onBlur={() => {
        setFieldValue(fieldName, ref.current);
        validateField(fieldName);
      }}
      textFieldProps={textFieldProps}
      {...props}
    />
  );
};

export default FormCurrencyInput;
