import React, { createContext, useContext, useState, useEffect } from "react";
import { IDictionaries } from "API/Interfaces";
import { useFetchStatus } from "Hooks";
import { useLoaderContext } from "Contexts";
import DictionaryServiceApi from "API/Services/DictionaryServiceApi";
import { sortBy, remove } from "lodash";

export interface DictionariesContextType {
  dictionaries: IDictionaries;
}

export const DictionariesContext = createContext<DictionariesContextType>(
  {} as DictionariesContextType
);

export const useDictionariesContext = () => useContext(DictionariesContext);

export const DictionariesProvider: React.FC = ({ children }) => {
  const { setLoading } = useLoaderContext();
  const [status, setStatus] = useFetchStatus();
  const [dictionaries, setDictionaries] = useState<IDictionaries>({
    carMakes: [],
    countryCodes: [],
    phoneCountryCodes: [],
    states: [],
  });

  useEffect(() => {
    setLoading(true);
    DictionaryServiceApi.getDictionaries()
      .then((res) => {
        const otherStates = remove(res.states, (s) => s.symbol === null);
        setDictionaries({
          ...res,
          carMakes: sortBy(res.carMakes, (o) => o.name),
          states: [...sortBy(res.states, (s) => s.name), ...otherStates],
        });
        setStatus.success();
      })
      .catch(() => setStatus.error())
      .finally(() => setLoading(false));
  }, [setLoading, setStatus]);

  return (
    <DictionariesContext.Provider value={{ dictionaries }}>
      {status.success && children}
      {/*TODO: {status.error && <ErrorComponent>Sorry, an unexpected error occured. Please try again later.</ErrorComponent>} */}
    </DictionariesContext.Provider>
  );
};
