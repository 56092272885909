import { AppPaper } from "Layout/Components";
import React from "react";
import styled from "styled-components";

interface PaymentDetailsPaperProps {}

const StyledPaper = styled(AppPaper)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: calc(100% - 20px);
  padding: 16px 20px;
  ${(_) => _.theme.breakpoints.up("md")} {
    flex-direction: row;
  }
  ${(_) => _.theme.breakpoints.up("lg")} {
    width: 1100px;
  }
`;

export const PaymentDetailsPaper: React.FC<PaymentDetailsPaperProps> = ({
  children,
}) => {
  return <StyledPaper>{children}</StyledPaper>;
};

export default PaymentDetailsPaper;
