import { ICitationFilters } from "API/Interfaces";

export const citationFiltersFields: { [K in keyof ICitationFilters]: string } =
  {
    citationNumber: "citationNumber",
    locationSearchPhrase: "locationSearchPhrase",
    violationSearchPhrase: "violationSearchPhrase",
    amountFrom: "amountFrom",
    amountTo: "amountTo",
    issueDateFrom: "issueDateFrom",
    issueDateTo: "issueDateTo",
  };

export const citationFiltersInit: ICitationFilters = {
  citationNumber: "",
  locationSearchPhrase: "",
  violationSearchPhrase: "",
  amountFrom: null,
  amountTo: null,
  issueDateFrom: null,
  issueDateTo: null,
};
