import { IconButton } from "@material-ui/core";
import { AppealStatus, ICitation } from "API/Interfaces/ICitations";
import { AppealBlueIcon, AppealYellowIcon, AppealRedIcon } from "Assets/Icons";
import { useAppealContext } from "Contexts/AppealContext";
import useAppealDetails from "Pages/CitationList/Hooks/useAppealDetails";
import React from "react";

export const appealIcons = {
  [AppealStatus.Open]: { color: "#FCD735", Icon: <AppealYellowIcon /> },
  [AppealStatus.Closed]: { color: "#F82222", Icon: <AppealRedIcon /> },
};

export interface AppealIconProps {
  citation: ICitation;
}

export const AppealIcon: React.FC<AppealIconProps> = ({
  citation,
  children,
}) => {
  const { showAppealFormPopup } = useAppealContext();
  const { showAppealDetailsDialog, AppealDetailsDialog } = useAppealDetails();

  return (
    <>
      {citation.canOpenDispute === false && citation.appealStatus === null ? (
        <></>
      ) : (
        <IconButton
          onClick={() =>
            citation.canOpenDispute && citation.appealStatus === null
              ? showAppealFormPopup(citation.id)
              : showAppealDetailsDialog(citation.id)
          }
        >
          {citation.appealStatus ? (
            appealIcons[citation.appealStatus].Icon
          ) : (
            <AppealBlueIcon />
          )}
          {children}
        </IconButton>
      )}
      {AppealDetailsDialog}
    </>
  );
};

export default AppealIcon;
