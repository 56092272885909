import React from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { CitationType, ICitation, ILocation } from "API/Interfaces";
import { ExpandedLocationArrow } from "Assets/Icons";
import { AppTypography } from "Layout/Components";
import styled, { css } from "styled-components";
import AppRadioButton from "Layout/Components/AppRadioButton";
import { useFormikContext } from "formik";
import { useTranslate } from "Hooks";
import { isEqual } from "lodash";
import { Box, Button } from "@material-ui/core";

export interface LocationAccordionProps {
  location: ILocation;
}

const StyledAccordionSummary = styled(AccordionSummary)<{
  $selected?: boolean;
}>`
  background: ${(_) => _.theme.palette.neutral.light};
  color: ${(_) => _.theme.palette.neutral.dark};
  border-radius: 8px;
  display: flex;
  ${(_) =>
    _.$selected &&
    css`
      background: rgba(0, 195, 255, 0.15);
      color: ${() => _.theme.palette.primary.main};
    `}
`;

const LocationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(_) => _.theme.breakpoints.up("md")} {
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const ForQuestionsSpan = styled.span`
  display: none;
  ${(_) => _.theme.breakpoints.up("md")} {
    display: inline;
    margin-right: 6px;
  }
`;

const SelectAll = styled(Button)`
  display: none;
  ${(_) => _.theme.breakpoints.up("md")} {
    display: inline;
    padding: 2px 16px;
    font-size: 12px;
    font-weight: 700;
    background-color: #fff;
    color: ${(_) => _.theme.palette.primary.main};
    border: 1px solid ${(_) => _.theme.palette.primary.main};
    margin-left: 24px;
  }
`;

const LocationAccordion: React.FC<LocationAccordionProps> = ({ location }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const t = useTranslate();

  return (
    <StyledAccordionSummary
      expandIcon={<ExpandedLocationArrow size={24} />}
      aria-controls={`${location.name}-content`}
      id={`${location.name}-header`}
      $selected={isEqual(values.location, location)}
    >
      <AppRadioButton
        value={JSON.stringify(location)}
        label=""
        onClick={(e) => e.stopPropagation()}
      />
      <LocationInfoWrapper>
        <Box display="flex">
          <AppTypography weight={700}>{location.name}</AppTypography>
          {!location.citations.some(
            (c: ICitation) => c.citationType === CitationType.BootingCitation
          ) && (
            <SelectAll
              onClick={(e) => {
                e.stopPropagation();
                setFieldValue("location", location);
                setFieldValue(
                  "citations",
                  values.citations.length >= 1
                    ? []
                    : location.citations.filter((c) => c.appealStatus === null)
                );
              }}
            >
              {t(
                values.citations.length >= 1
                  ? "COMMON_BUTTONS_UNSELECTALL"
                  : "COMMON_BUTTONS_SELECTALL"
              )}
            </SelectAll>
          )}
        </Box>
        <AppTypography weight={700} paddingRight={4}>
          <ForQuestionsSpan>
            {t("COMMON_PAGES_FORQUESTIONSCALL")}
          </ForQuestionsSpan>
          {location.phoneNumber}
        </AppTypography>
      </LocationInfoWrapper>
    </StyledAccordionSummary>
  );
};

export default LocationAccordion;
