import { PaymentServiceApi } from "API/Services/PaymentServiceApi";
import { useCarDataContext } from "Contexts";
import { usePopupContext } from "Contexts/PopupContext";
import { useFetchStatus } from "Hooks";
import {
  paymentFormToRequest,
  IPaymentDetailForm,
} from "../Components/PaymentForm/FormFields";

export const useSendPayment = () => {
  const { sendPaymentData, redirectToTezPay } = PaymentServiceApi;
  const [status, setStatus] = useFetchStatus();
  const { setPopup, showPopup } = usePopupContext();
  const { carData } = useCarDataContext();

  const handler = async (
    paymentData: IPaymentDetailForm,
    citations: string[]
  ) => {
    setStatus.loading();
    try {
      const paymentId = await sendPaymentData(
        paymentFormToRequest(paymentData, citations, carData.plate)
      );
      const { redirectUrl, redirectionToken } = await redirectToTezPay(
        paymentId
      );
      const a = document.createElement("a");
      const host = redirectUrl.endsWith("/") ? redirectUrl : redirectUrl + "/";
      a.setAttribute("href", `${host}${redirectionToken}`);
      a.click();
      setStatus.success();
    } catch (err) {
      setStatus.error();
      console.error(err);
      setPopup({ variant: "error" });
      showPopup(true);
    }
  };
  return [handler, status] as const;
};

export default useSendPayment;
