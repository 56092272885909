import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PaymentStatus } from "API/Interfaces/IPayment";
import { Status } from "./Components";
import { useLoaderContext } from "Contexts";
import PaymentServiceApi from "API/Services/PaymentServiceApi";

interface PaymentResultProps {}

export const PaymentResult: React.FC<PaymentResultProps> = () => {
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(
    null
  );
  const { id } = useParams<{ id: string }>();
  const { setLoading } = useLoaderContext();
  const { getPaymentStatus } = PaymentServiceApi;

  useEffect(() => {
    setLoading(true);
    getPaymentStatus(id)
      .then((e) => setPaymentStatus(e.transactionStatus))
      .catch(() => setPaymentStatus(PaymentStatus.Failed))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  return paymentStatus ? (
    <Status isSuccess={paymentStatus === PaymentStatus.Successful} />
  ) : (
    <></>
  );
};

export default PaymentResult;
