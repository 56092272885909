import * as yup from "yup";
import { i18nt } from "Translation";

export interface ICitationIdSearchForm {
  citationId: string;
}

export type CitationIdSearchKeys = keyof ICitationIdSearchForm;

export interface CitationIdSearchFieldNamesType {
  citationId: CitationIdSearchKeys;
}

export const citationIdSearchFieldNames: CitationIdSearchFieldNamesType = {
  citationId: "citationId",
};

export const validationSchema = {
  citationId: yup
    .string()
    .max(50, i18nt("COMMON_FORMS_MAXCHARACTER50"))
    .required(i18nt("COMMON_FORMS_FIELDISREQUIRED")),
};

export const initialData: ICitationIdSearchForm = {
  citationId: "",
};
