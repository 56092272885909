import * as yup from "yup";
import { i18nt } from "Translation";

export interface IPlateNumberForm {
  plate: string;
  makeId: number | string;
  stateId: string;
}

export interface PlateNumberFieldNamesType {
  plate: string;
  makeId: string;
  stateId: string;
}

export const plateNumberSearchFieldNames: PlateNumberFieldNamesType = {
  plate: "plate",
  makeId: "makeId",
  stateId: "stateId",
};

export const validationSchema = {
  plate: yup
    .string()
    .max(50, i18nt("COMMON_FORMS_MAXCHARACTER50"))
    .required(i18nt("COMMON_FORMS_FIELDISREQUIRED")),
  makeId: yup.number().required(i18nt("COMMON_FORMS_FIELDISREQUIRED")),
  stateId: yup.string().required(i18nt("COMMON_FORMS_FIELDISREQUIRED")),
};

export const initialData: IPlateNumberForm = {
  plate: "",
  makeId: "",
  stateId: "",
};
