import React from "react";
import { CitationTab } from "./";
import Tabs, { TabsProps } from "@material-ui/core/Tabs";
import styled from "styled-components";

interface CitationSwitchProps extends TabsProps {
  handleChange: (arg: number) => void;
}

const StyledTabs = styled(Tabs)`
  * > .MuiButtonBase-root {
    :nth-child(1) {
      border-radius: 10px 0 0 10px;
    }
    :nth-last-child(1) {
      border-radius: 0 10px 10px 0;
    }
  }
`;

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(_) => _.theme.breakpoints.up("md")} {
    margin-top: -40px;
  }
`;

export const CitationSwitch: React.FC<CitationSwitchProps> = ({
  handleChange,
  ...props
}) => {
  return (
    <TabWrapper>
      <StyledTabs
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        onChange={(e, v) => handleChange(v)}
        {...props}
      >
        <CitationTab translationLabel="COMMON_FORMS_CITATIONID" />
        <CitationTab translationLabel="COMMON_FORMS_PLATENUMBER" />
      </StyledTabs>
    </TabWrapper>
  );
};

export default CitationSwitch;
