import styled, { css } from "styled-components";
export const InlineWrapper = styled.div<{
  marginsRight?: number;
  minWidthFirstChild?: number;
}>`
  display: flex;
  > *:not(:last-child) {
    margin-right: ${(_) => _.theme.spacing(_.marginsRight)}px;
  }
  ${(_) =>
    _.minWidthFirstChild &&
    css`
      > *:first-child {
        min-width: ${_.minWidthFirstChild}px;
      }
    `}
`;
InlineWrapper.defaultProps = {
  marginsRight: 2,
};
