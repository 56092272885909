import React, { useContext, useState } from "react";

export type TimerContextType = {
  timerExpire: number;
  setTimerExpire: (arg: number) => void;
};

export const TimerContext = React.createContext<TimerContextType>({
  timerExpire: 0,
  setTimerExpire: () => undefined,
});

export const useTimerContext = () => useContext(TimerContext);

export const TimerContextProvider: React.FC = (props) => {
  const [timerExpire, setTimerExpire] = useState<number>(0);

  return (
    <TimerContext.Provider
      value={{
        timerExpire,
        setTimerExpire,
      }}
    >
      {props.children}
    </TimerContext.Provider>
  );
};

export default TimerContextProvider;
