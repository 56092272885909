import { apiClient, IResponseApi, urls } from "API/Client";
import { ICarData } from "API/Interfaces";

const CarDataServiceApi = {
  /*
  API: /citations/search-data
  DOC: https://confluence.lbpro.pl/pages/viewpage.action?pageId=133007281
  */
  getCarData: async (citationNumber: string) => {
    const res = await apiClient.get<IResponseApi<ICarData>>(
      urls.citations.searchData,
      {
        params: { citationNumber },
      }
    );
    return res.data.data;
  },
  /*
  API: /citations/search-data
  DOC: https://confluence.lbpro.pl/pages/viewpage.action?pageId=133007281
  */
  checkCarData: async (params: ICarData) => {
    const res = await apiClient.get<IResponseApi<ICarData>>(
      urls.citations.searchData,
      {
        params,
      }
    );
    return res.data.data;
  },
  getStorageCar: (): ICarData | null => {
    const carStorage = window.sessionStorage.getItem("CarData");
    return carStorage ? (JSON.parse(carStorage) as ICarData) : null;
  },
  setStorageCar: (carData: ICarData): void =>
    window.sessionStorage.setItem("CarData", JSON.stringify(carData)),
  clearStorageCar: (): void => window.sessionStorage.removeItem("CarData"),
};

export default CarDataServiceApi;
