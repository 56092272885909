import Tab, { TabProps } from "@material-ui/core/Tab";
import styled from "styled-components";
import { TranslationKeys } from "Translation/translations";
import { useTranslate } from "Hooks";

interface CitationTabProps extends TabProps {
  translationLabel?: TranslationKeys;
}

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    min-height: unset;
    color: ${(_) => _.theme.palette.primary.main};
    border: 2px solid ${(_) => _.theme.palette.primary.main};
    font-weight: bold;
    text-transform: capitalize;
    background-color: #fff;
  }
  &.Mui-selected {
    background-color: ${(_) => _.theme.palette.primary.main};
    padding: 3px 12px;
    color: #fff;
    ::before {
      content: "✓"; //TODO: Change for svg component
      margin: 0 5px;
    }
  }
  > .MuiTab-wrapper {
    width: unset;
  }
  &.MuiTab-textColorInherit {
    opacity: 1;
  }
`;

export const CitationTab: React.FC<CitationTabProps> = ({
  label,
  translationLabel,
  ...rest
}) => {
  const t = useTranslate();
  return <StyledTab label={label || t(translationLabel)} {...rest}></StyledTab>;
};

export default CitationTab;
