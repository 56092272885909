import { CriteriaType, IUpchargeDetails, ILocation } from "API/Interfaces";
import { i18nt } from "Translation";
const { NumberOfUnpaidCitations } = CriteriaType;

export const checkUpchargeSummary = (
  locations: ILocation[],
  selectedLocation: ILocation | null
) => {
  let isUpchargeRequired = false, //describe if show upcharge in summary
    description = "", //tooltip description
    upchargeAmount = 0; //upcharge value to pay if isUpchargeRequired is true

  if (locations && selectedLocation) {
    const originalLocation = locations.find(
      (location) => location.name === selectedLocation?.name
    );
    if (originalLocation?.upchargeDetails) {
      const { upchargeDetails, citations } = originalLocation;
      const amount =
        upchargeDetails.criteria.criteria === NumberOfUnpaidCitations
          ? citations.length
          : citations
              .map((c) => c.paymentDetails.amount)
              .reduce((prev, curr) => prev + curr, 0);
      isUpchargeRequired = amount > upchargeDetails.criteria.value;
      if (isUpchargeRequired) {
        upchargeAmount = upchargeDetails.upchargeAmount;
        description = upchargeTooltipDescription(upchargeDetails);
      }
    }
  }
  return { isUpchargeRequired, description, upchargeAmount };
};

export const upchargeTooltipDescription = (
  upcharge: IUpchargeDetails
): string => {
  const isMoreThanNumber =
    upcharge.criteria.criteria === NumberOfUnpaidCitations;
  const amount = isMoreThanNumber
    ? upcharge.criteria.value
    : `$${upcharge.criteria.value / 100}`;
  const citations = i18nt(
    isMoreThanNumber
      ? "COMMON_DIALOGS_UNPAIDCITATIONS"
      : "COMMON_DIALOGS_INUNPAIDCITATIONS"
  );
  return `${i18nt("COMMON_DIALOGS_MORETHAN")} ${amount} ${citations}`;
};
