import { useFormikContext } from "formik";
import { useTranslate } from "Hooks";
import React from "react";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import { TranslationKeys } from "Translation";
import styled from "styled-components";
export interface FormDatePickerProps {
  fieldName: string;
  placeholderTranslation?: TranslationKeys;
}

const StyledTextfield = styled(TextField)`
  > label {
    color: ${(_) => _.theme.palette.neutral.main};
  }
`;

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  fieldName,
  placeholderTranslation,
  ...props
}) => {
  const t = useTranslate();
  const { setFieldValue, values, errors } = useFormikContext<any>();

  return (
    <DatePicker
      clearable
      value={values[fieldName]}
      onChange={(value) => setFieldValue(fieldName, value)}
      renderInput={(params) => (
        <StyledTextfield
          name={fieldName}
          error={!!errors[fieldName]}
          size="small"
          fullWidth
          helperText={errors[fieldName]}
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder: t(placeholderTranslation),
          }}
        />
      )}
      {...props}
    />
  );
};

export default FormDatePicker;
