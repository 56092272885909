import FileServiceApi from "API/Services/FileService";

export const useDownloadFile = () => {
  const downloadAttachment = (id: string, name: string) => {
    FileServiceApi.getFile(id).then((res) => {
      if (res) {
        const url = window.URL.createObjectURL(
          new Blob([res], { type: res.type })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      }
    });
  };
  return { downloadAttachment };
};
