import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps as MUIDialogProps,
} from "@material-ui/core";
import styled, { css } from "styled-components";
import AppButton from "./AppButton";
import AppTypography from "./AppTypography";

export interface AppDialogProps extends MUIDialogProps {
  handleOpen?: any;
  agreeAction?: any;
  cancelButton?: string;
  agreeButton?: string;
  descriptionTitle?: string;
  description?: string;
  Icon?: React.FC;
  maxHeight?: number;
  cancelAction?: () => void;
}
const IconWrapper = styled.div`
  padding-bottom: 12px;
  > * {
    margin: auto;
    display: block;
    width: auto;
    height: auto;
  }
`;

export const AppDialogWindow = styled(Dialog)<{ $maxHeight?: number }>`
  .MuiDialogContent-root {
    padding: 20px 50px;
    &::-webkit-scrollbar {
      width: 0.4em;
    }
    &::-webkit-scrollbar-track {
      background-color: ${(_) => _.theme.palette.neutral.main};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${(_) => _.theme.palette.primary.main};
      border-radius: 20px;
    }
  }
  .MuiDialog-paper {
    width: 100%;
    ${(_) =>
      _.$maxHeight &&
      css`
        max-height: ${_.$maxHeight}px;
      `}
  }
`;
export const AppDialog: React.FC<AppDialogProps> = ({
  handleOpen,
  cancelButton,
  agreeAction,
  agreeButton,
  Icon,
  descriptionTitle,
  maxHeight,
  description,
  cancelAction,
  ...props
}) => {
  return (
    <AppDialogWindow
      onClose={() => {
        handleOpen(false);
        cancelAction && cancelAction();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      $maxHeight={maxHeight}
      scroll="paper"
      {...props}
    >
      {props.title && (
        <DialogTitle id="alert-dialog-title" disableTypography={true}>
          {props.title}
        </DialogTitle>
      )}
      {!!(Icon || descriptionTitle || description || props.children) && (
        <DialogContent>
          {Icon && (
            <IconWrapper>
              <Icon />
            </IconWrapper>
          )}
          {descriptionTitle && (
            <AppTypography
              align="center"
              variant="h2"
              weight={700}
              gutterBottom
            >
              {descriptionTitle}
            </AppTypography>
          )}
          {description && (
            <AppTypography align="center">{description}</AppTypography>
          )}
          {props.children}
        </DialogContent>
      )}
      {(cancelButton || agreeButton) && (
        <DialogActions>
          {cancelButton && (
            <AppButton
              onClick={() => {
                handleOpen(false);
                cancelAction && cancelAction();
              }}
              color="primary"
              variant="outlined"
            >
              {cancelButton}
            </AppButton>
          )}
          {agreeButton && (
            <AppButton
              onClick={agreeAction}
              color="primary"
              autoFocus
              variant="contained"
            >
              {agreeButton}
            </AppButton>
          )}
        </DialogActions>
      )}
    </AppDialogWindow>
  );
};
AppDialog.defaultProps = {
  agreeAction: () => undefined,
  handleOpen: () => undefined,
};
export default AppDialog;
