import { ICitation, ILocation } from "API/Interfaces";
import * as Yup from "yup";

export const citationListFields = {
  location: "location",
  citations: "citations",
};

export interface ICitationListForm {
  location: ILocation | null;
  citations: ICitation[];
}

export const citationListSchema = Yup.object().shape({
  location: Yup.string().required(),
  citations: Yup.array().min(1),
});

export const initialCitationListForm: ICitationListForm = {
  location: null,
  citations: [],
};
export default citationListSchema;
