import { apiClient, config, urls } from "API/Client";

export interface AddAttachmentResponse {
  data: AddedAttachments[];
}

export interface AddedAttachments {
  fileName: string;
  fileId: string;
  isRejected?: boolean;
}

const FileServiceApi = {
  addAttachment: async (file: File): Promise<AddAttachmentResponse> => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const res = await apiClient.post<AddAttachmentResponse>(
      urls.files.attachment,
      formData
    );
    return res.data;
  },

  getFile: async (id: string) => {
    const res = await apiClient.get<File>(`${urls.files.files}/${id}`, {
      headers: {
        SecretKey: config.EXTERNAL_SECRET_KEY,
      },
      responseType: "blob",
    });
    return new Blob([res.data]);
  },
};

export default FileServiceApi;
