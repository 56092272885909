import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTranslate } from "Hooks";
import { TranslationKeys } from "Translation";

interface Props {
  fieldName: string;
  translationLabel?: TranslationKeys;
  untouchedError?: boolean;
  inputRequired?: boolean;
}
export type FormSelectFieldProps = Props & SelectProps;

export const FormSelectField: React.FC<FormSelectFieldProps> = ({
  fieldName,
  translationLabel,
  untouchedError,
  inputRequired,
  ...props
}) => {
  const t = useTranslate();
  const { values, handleChange, errors, touched, handleBlur } =
    useFormikContext<any>();

  const isTouched = () => (untouchedError ? true : touched[fieldName]);

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel id={fieldName} required={inputRequired}>
        {t(translationLabel)}
      </InputLabel>
      <Select
        onBlur={handleBlur}
        labelId={fieldName}
        id={fieldName}
        name={fieldName}
        label={t(translationLabel)}
        value={values[fieldName]}
        onChange={handleChange}
        error={isTouched() && Boolean(errors[fieldName])}
        {...props}
      >
        {props.children}
      </Select>
      {isTouched() && Boolean(errors[fieldName]) && (
        <FormHelperText error={Boolean(errors[fieldName])}>
          {errors[fieldName]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormSelectField;
