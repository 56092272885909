import { Redirect, Route, Switch } from "react-router-dom";
import { CitationSearch, PaymentDetails, PaymentResult } from "Pages";
import { routes } from "./routes";
import { PageLayout } from "Layout/PageLayout";
import { CitationContextProvider, useCarDataContext } from "Contexts";
import CitationList from "Pages/CitationList/CitationList";
import EnforcementRedirect from "Pages/Misc/EnforcementRedirect/EnforcementRedirect";
import UnsubscribeEmail from "Pages/Misc/UnsubscribeEmail/UnsubscribeEmail";

export const AppRouter: React.FC = () => {
  const { status } = useCarDataContext();
  return (
    <PageLayout>
      <Switch>
        <Route
          exact
          path={[routes.qr, routes.root]}
          component={CitationSearch}
        />
        {status.success && (
          <>
            <CitationContextProvider>
              <Route path={routes.citation} exact component={CitationList} />
              <Route path={routes.pay} component={PaymentDetails} />
            </CitationContextProvider>
          </>
        )}
        <Route exact path={routes.payStatus} component={PaymentResult} />
        <Route path={routes.redirect} component={EnforcementRedirect} />
        <Route path={routes.mailUnassignment} component={UnsubscribeEmail} />
        <Redirect to={routes.root} />
      </Switch>
    </PageLayout>
  );
};

export default AppRouter;
