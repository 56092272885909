import { TextField, TextFieldProps } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { useTranslate } from "Hooks";
import { TranslationKeys } from "Translation/translations";

const StyledTextField = styled(TextField)<{
  $maxWidth?: number;
  $minWidth?: number;
}>`
  ${(_) => _.$maxWidth && `max-width: ${_.$maxWidth}px;`}
  ${(_) => _.$minWidth && `min-width: ${_.$minWidth}px;`}
`;

export type AppTextFieldProps = {
  maxWidth?: number;
  minWidth?: number;
  translationLabel?: TranslationKeys;
} & TextFieldProps;

export const AppTextField: React.FC<AppTextFieldProps> = ({
  maxWidth,
  minWidth,
  label,
  translationLabel,
  ...props
}) => {
  const t = useTranslate();

  return (
    <StyledTextField
      variant="outlined"
      size="small"
      fullWidth
      $maxWidth={maxWidth}
      $minWidth={minWidth}
      label={label || t(translationLabel)}
      {...props}
    />
  );
};

export default AppTextField;
