import { CriteriaType, IUpchargeDetails } from "API/Interfaces";
import { i18nt } from "Translation";
import { ICitationListForm } from "../schema";
const { NumberOfUnpaidCitations, AmountOfUnpaidCitations } = CriteriaType;

export const checkUpcharges = (form: ICitationListForm) => {
  const { location, citations } = form;
  let isRequired = false, //check if upcharge is required
    description = ""; //description for popup
  if (location?.upchargeDetails) {
    const { criteria } = location.upchargeDetails;
    let difference = 0,
      totalCitations = 0;
    if (criteria.criteria === NumberOfUnpaidCitations) {
      totalCitations = location.citations.length; //number of all citations in location
      const citationsNumberToPay = citations.length; //number of selected citations
      difference = totalCitations - citationsNumberToPay;
    } else if (criteria.criteria === AmountOfUnpaidCitations) {
      totalCitations = location.citations
        .map((c) => c.paymentDetails.amount)
        .reduce((prev, curr) => prev + curr, 0); //amount of all citations in location
      const amountToPay = citations
        .map((c) => c.paymentDetails.amount)
        .reduce((prev, curr) => prev + curr, 0); //amount of selected citations
      difference = totalCitations - amountToPay;
    }
    isRequired = difference > criteria.value; //check if next payment would generate upcharge
    if (isRequired) {
      description = upchargeDialogDescription(
        location.upchargeDetails,
        totalCitations
      );
    }
  }
  return { isRequired, description };
};

export const upchargeDialogDescription = (
  upcharge: IUpchargeDetails,
  totalCitations: number
): string => {
  const { criteria } = upcharge;
  const youMustPay = i18nt("COMMON_DIALOGS_YOUMUSTPAYATLEAST"); //"You must pay"
  const citationsUpcharge = //example: "$100 in citations" or "3 citations"
    criteria.criteria === AmountOfUnpaidCitations
      ? `$${(totalCitations - criteria.value) / 100} ${i18nt(
          "COMMON_DIALOGS_INCITATIONS"
        )}`
      : `${totalCitations - criteria.value} ${i18nt(
          "COMMON_DIALOGS_CITATIONS"
        )}`;
  const dueTue = i18nt("COMMON_DIALOGS_DUETOEXCESSIVE"); //"due to"
  return `${youMustPay} ${citationsUpcharge} ${dueTue}`; //concat
};
