import useBreakpoints from "Hooks/useBreakpoints";
import { AppTypography } from "Layout/Components";
import React from "react";
import styled from "styled-components";

const CitationHeadersWrapper = styled.div`
  display: none;
  ${(_) => _.theme.breakpoints.up("md")} {
    display: block;
    padding: 10px 16px;
    width: 100%;
    display: flex;
  }
`;

const AmountHeaders = styled.div`
  display: flex;
  min-width: 380px;
  margin-left: auto;
  > div {
    width: 20%;
    text-align: center;
  }
  ${(_) => _.theme.breakpoints.up("lg")} {
    min-width: 450px;
  }
  ${(_) => _.theme.breakpoints.up("xl")} {
    min-width: 530px;
  }
`;

const CitationId = styled(AppTypography)`
  min-width: 200px;
`;
const Violation = styled(AppTypography)`
  min-width: 210px;
`;
const ViolationType = styled(AppTypography)`
  min-width: 160px;
`;
const CitationHeaders: React.FC = () => {
  const { up } = useBreakpoints();
  const typoProps = {
    size: up("xl") ? undefined : 1.15,
    weight: 700,
  };
  return (
    <CitationHeadersWrapper>
      <CitationId {...typoProps} translation="COMMON_LABELS_CITATIONID" />
      <Violation {...typoProps} translation="COMMON_LABELS_VIOLATION" />
      <ViolationType {...typoProps} translation="COMMON_LABELS_VIOLATIONTYPE" />
      <AmountHeaders>
        <AppTypography {...typoProps} translation="COMMON_LABELS_AMOUNT" />
        <AppTypography {...typoProps} translation="COMMON_LABELS_FEES" />
        <AppTypography {...typoProps} translation="COMMON_LABELS_DISCOUNTS" />
        <AppTypography {...typoProps} translation="COMMON_LABELS_TOTAL" />
        <div />
      </AmountHeaders>
    </CitationHeadersWrapper>
  );
};

export default CitationHeaders;
