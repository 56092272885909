import { Box } from "@material-ui/core";
import { IPaymentDetails } from "API/Interfaces";
import { AppTypography } from "Layout/Components";
import AppDollar from "Layout/Components/AppDollar";
import AppTooltip from "Layout/Components/AppTooltip";
import useSummaryUpcharges from "Pages/PaymentDetails/Hooks/useSummaryUpcharges";
import React from "react";
import styled from "styled-components";

interface SummaryBoxProps {
  summary: IPaymentDetails;
}

const SummaryContainer = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: ${(_) => _.theme.palette.neutral.light};
`;

const SummaryDataItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  align-items: flex-end;
  content: ":";
`;

const SummaryText = styled(AppTypography)`
  :after {
    content: ":";
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: ${(_) => _.theme.palette.neutral.main};
`;

export const SummaryBox: React.FC<SummaryBoxProps> = ({ summary }) => {
  const { isUpchargeRequired, upchargeAmount, description } =
    useSummaryUpcharges();

  return (
    <SummaryContainer>
      <SummaryText
        variant="h2"
        weight="bold"
        translation="COMMON_LABELS_SUMMARY"
      />
      <SummaryDataItem>
        <SummaryText variant="body1" translation="COMMON_LABELS_AMOUNT" />
        <AppDollar amount={summary.amount} />
      </SummaryDataItem>
      <SummaryDataItem>
        <Box display="flex">
          <SummaryText
            variant="body1"
            translation="COMMON_LABELS_FEES"
            paddingRight={1}
          />
          <AppTooltip
            translation="COMMON_DIALOGS_FEESTOOLTIP"
            arrow
            placement="right"
          />
        </Box>
        <AppDollar amount={summary.fee} />
      </SummaryDataItem>
      {isUpchargeRequired && (
        <SummaryDataItem>
          <Box display="flex">
            <SummaryText
              variant="body1"
              translation="COMMON_LABELS_UPCHARGES"
              paddingRight={1}
            />
            <AppTooltip
              title={<AppTypography>{description}</AppTypography>}
              arrow
              placement="right"
            />
          </Box>

          <AppDollar amount={upchargeAmount} />
        </SummaryDataItem>
      )}
      <SummaryDataItem>
        <SummaryText variant="body1" translation="COMMON_LABELS_DISCOUNTS" />
        <AppDollar discount amount={summary.discount} />
      </SummaryDataItem>
      <Separator />
      <SummaryDataItem>
        <SummaryText
          variant="body1"
          weight="bold"
          translation="COMMON_LABELS_TOTAL"
        />
        <AppDollar
          amount={summary.total + (isUpchargeRequired ? upchargeAmount : 0)}
          typoProps={{ color: "secondary", variant: "h2", weight: "bold" }}
        />
      </SummaryDataItem>
    </SummaryContainer>
  );
};

export default SummaryBox;
