export enum Translations {
  COMMON_LABELS_AMOUNT = "common.labels.amount",
  COMMON_LABELS_FEES = "common.labels.fees",
  COMMON_LABELS_UPCHARGES = "common.labels.upcharges",
  COMMON_LABELS_DISCOUNTS = "common.labels.discounts",
  COMMON_LABELS_TOTAL = "common.labels.total",
  COMMON_LABELS_SELECTED = "common.labels.selected",
  COMMON_LABELS_VIOLATION = "common.labels.violation",
  COMMON_LABELS_LOCATION = "common.labels.location",
  COMMON_LABELS_CITATIONID = "common.labels.citationId",
  COMMON_LABELS_SUMMARY = "common.labels.summary",
  COMMON_LABELS_FORAUESTIONSCALL = "common.labels.forAuestionsCall",
  COMMON_LABELS_CITATIONISSUE = "common.labels.citationIssue",
  COMMON_LABELS_CITATIONNUMBER = "common.labels.citationNumber",
  COMMON_LABELS_FROM = "common.labels.from",
  COMMON_LABELS_TO = "common.labels.to",
  COMMON_LABELS_FROMDOLLAR = "common.labels.fromDollar",
  COMMON_LABELS_TODOLLAR = "common.labels.toDollar",
  COMMON_LABELS_FILTERS = "common.labels.filters",
  COMMON_LABELS_FILTERSACTIVE = "common.labels.filtersActive",
  COMMON_LABELS_VIOLATIONTYPE = "common.labels.violationType",
  COMMON_LABELS_BOOTING = "common.labels.booting",
  COMMON_LABELS_STANDARD = "common.labels.standard",
  COMMON_PAGES_PAYFORYOURCITATION = "common.pages.payForYourCitation",
  COMMON_PAGES_FINDYOURCITATION = "common.pages.findYourCitation",
  COMMON_PAGES_ENTERYOURCITATIONNUMBER = "common.pages.enterYourCitationNumber",
  COMMON_PAGES_FORQUESTIONSCALL = "common.pages.forQuestionsCall",
  COMMON_PAGES_SOMETHINGWENTWRONG = "common.pages.somethingWentWrong",
  COMMON_PAGES_SOMETHINGWENTWRONGPLEASETRYLATER = "common.pages.somethingWentWrongPleaseTryLater",
  COMMON_PAGES_YOUREMAILHASBEENUNSUBSCRIBED = "common.pages.yourEmailHasBeenUnsubscribed",
  COMMON_PAGES_CITATIONCANNOTBEFOUND = "common.pages.citationCannotBeFound",
  COMMON_PAGES_PAYMENTCONFIGURATIONPROBLEM = "common.pages.paymentConfigurationProblem",
  COMMON_PAGES_PAYMENTCONFIRMATIONWILLBESENT = "common.pages.paymentConfirmationWillBeSent",
  COMMON_PAGES_THEPROVIDEDPHONENUMBERWILLIDENTIFY = "common.pages.theProvidedPhoneNumberWillIdentify",
  COMMON_PAGES_CONTACTDATA = "common.pages.contactData",
  COMMON_PAGES_DISPUTEREASON = "common.pages.disputeReason",
  COMMON_PAGES_ENTERDISPUTE = "common.pages.enterDispute",
  COMMON_PAGES_WELCOME = "common.pages.welcome",
  COMMON_PAGES_YOURCITATIONSUCCESSFULLYPAID = "common.pages.yourCitationSuccessfullyPaid",
  COMMON_PAGES_RECEIPTHASBEENEMAILED = "common.pages.receiptHasBeenEmailed",
  COMMON_PAGES_UNPAIDCITATION = "common.pages.unpaidCitation",
  COMMON_PAGES_THEREAREUNPAIDCITATIONSISSUED = "common.pages.thereAreUnpaidCitationsIssued",
  COMMON_PAGES_COUNTDOWNTOOLTIPMSG = "common.pages.countdownTooltipMsg",
  COMMON_PAGES_EMAILSUBSCRIPTION = "common.pages.emailSubscription",
  COMMON_FORMS_CITATIONID = "common.forms.citationId",
  COMMON_FORMS_PLATENUMBER = "common.forms.plateNumber",
  COMMON_FORMS_MAKE = "common.forms.make",
  COMMON_FORMS_FIELDISREQUIRED = "common.forms.fieldIsRequired",
  COMMON_FORMS_MAXCHARACTER = "common.forms.maxCharacter",
  COMMON_FORMS_MAXCHARACTER10 = "common.forms.maxCharacter10",
  COMMON_FORMS_MAXCHARACTER50 = "common.forms.maxCharacter50",
  COMMON_FORMS_MAXCHARACTER100 = "common.forms.maxCharacter100",
  COMMON_FORMS_MAXCHARACTER150 = "common.forms.maxCharacter150",
  COMMON_FORMS_MAXCHARACTER200 = "common.forms.maxCharacter200",
  COMMON_FORMS_MAXCHARACTER500 = "common.forms.maxCharacter500",
  COMMON_FORMS_FIRSTNAME = "common.forms.firstName",
  COMMON_FORMS_LASTNAME = "common.forms.lastName",
  COMMON_FORMS_TYPEADDRESSFORLOOKUP = "common.forms.typeAddressForLookup",
  COMMON_FORMS_STREET1 = "common.forms.street1",
  COMMON_FORMS_STREET2 = "common.forms.street2",
  COMMON_FORMS_ZIPCODE = "common.forms.zipCode",
  COMMON_FORMS_COUNTY = "common.forms.county",
  COMMON_FORMS_CITY = "common.forms.city",
  COMMON_FORMS_STATE = "common.forms.state",
  COMMON_FORMS_COUNTRY = "common.forms.country",
  COMMON_FORMS_EMAIL = "common.forms.email",
  COMMON_FORMS_PHONE = "common.forms.phone",
  COMMON_FORMS_ADDRESSLOOKUP = "common.forms.addressLookup",
  COMMON_FORMS_IAMREGISTEREDOWNER = "common.forms.iAmRegisteredOwner",
  COMMON_FORMS_IAGREETHATMYCONTACT = "common.forms.iAgreeThatMyContact",
  COMMON_FORMS_PREFIX = "common.forms.prefix",
  COMMON_FORMS_NONE = "common.forms.none",
  COMMON_FORMS_ENTERVALIDEMAIL = "common.forms.enterValidEmail",
  COMMON_FORMS_INCORRECTDATEFORMAT = "common.forms.incorrectDateFormat",
  COMMON_FORMS_DATETOERROR = "common.forms.dateToError",
  COMMON_FORMS_AMOUNTTOERROR = "common.forms.amountToError",
  COMMON_FORMS_CITATIONCANNOTBEPAID = "common.forms.citationCannotBePaid",
  COMMON_FORMS_DISPUTEDETAILS = "common.forms.disputeDetails",
  COMMON_FORMS_DISPUTE = "common.forms.dispute",
  COMMON_FORMS_ANSWER = "common.forms.answer",
  COMMON_FORMS_NUMBERTYPE = "common.forms.numberType",
  COMMON_BUTTONS_SUBMIT = "common.buttons.submit",
  COMMON_BUTTONS_PAY = "common.buttons.pay",
  COMMON_BUTTONS_DETAILS = "common.buttons.details",
  COMMON_BUTTONS_CANCEL = "common.buttons.cancel",
  COMMON_BUTTONS_GOBACKTOCITATIONPAYMENT = "common.buttons.goBackToCitationPayment",
  COMMON_BUTTONS_SELECTALL = "common.buttons.selectAll",
  COMMON_BUTTONS_UNSELECTALL = "common.buttons.unselectAll",
  COMMON_BUTTONS_FILTER = "common.buttons.filter",
  COMMON_BUTTONS_CLEARALL = "common.buttons.clearAll",
  COMMON_BUTTONS_CLOSE = "common.buttons.close",
  COMMON_BUTTONS_OPENDISPUTE = "common.buttons.openDispute",
  COMMON_DIALOGS_OK = "common.dialogs.ok",
  COMMON_DIALOGS_ERROR = "common.dialogs.error",
  COMMON_DIALOGS_SUCCESS = "common.dialogs.success",
  COMMON_DIALOGS_UNEXPECTEDERRORPLEASETRYAGAIN = "common.dialogs.unexpectedErrorPleaseTryAgain",
  COMMON_DIALOGS_UPCHARGEPAYMENTREQUIRED = "common.dialogs.upchargePaymentRequired",
  COMMON_DIALOGS_YOUMUSTPAYATLEAST = "common.dialogs.youMustPayAtLeast",
  COMMON_DIALOGS_CITATION = "common.dialogs.citation",
  COMMON_DIALOGS_CITATIONS = "common.dialogs.citations",
  COMMON_DIALOGS_INCITATIONS = "common.dialogs.inCitations",
  COMMON_DIALOGS_DUETOEXCESSIVE = "common.dialogs.dueToExcessive",
  COMMON_DIALOGS_MORETHAN = "common.dialogs.moreThan",
  COMMON_DIALOGS_INUNPAIDCITATIONS = "common.dialogs.inUnpaidCitations",
  COMMON_DIALOGS_UNPAIDCITATIONS = "common.dialogs.unpaidCitations",
  COMMON_DIALOGS_FEESTOOLTIP = "common.dialogs.feesTooltip",
  COMMON_DIALOGS_APPEALNOTFOUND = "common.dialogs.appealNotFound",
  COMMON_DIALOGS_DISPUTEALREADYEXISTS = "common.dialogs.disputeAlreadyExists",
  COMMON_DIALOGS_ATTACHMENTS = "common.dialogs.attachments",
  COMMON_DIALOGS_MAX2FILES = "common.dialogs.max2Files",
  COMMON_DIALOGS_DRAGANDDROP = "common.dialogs.dragAndDrop",
  COMMON_DIALOGS_TOOLARGEFILE = "common.dialogs.tooLargeFile",
  COMMON_DIALOGS_TOOMUCHFILES = "common.dialogs.tooMuchFiles",
  COMMON_DIALOGS_ERRORDURINGTHEUPLOAD = "common.dialogs.errorDuringTheUpload",
  COMMON_DIALOGS_INVALIDFORMAT = "common.dialogs.invalidFormat",
};

export type TranslationKeys = keyof typeof Translations

export default Translations;