export enum IFetchStatus {
  INIT,
  LOADING,
  ERROR,
  SUCCESS,
}
export type IFetchStatusObj = {
  init: boolean;
  loading: boolean;
  error: boolean;
  success: boolean;
};
