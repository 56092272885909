import { Grid } from "@material-ui/core";
import { ICitationFilters } from "API/Interfaces";
import { useCitationContext } from "Contexts";
import { Formik } from "formik";
import {
  FormCancelButton,
  FormSubmitButton,
  LabelTextField,
} from "Layout/FormComponents";
import DateRangeFields from "Layout/FormComponents/DateRangeFields";
import LabelFromTo from "Layout/FormComponents/LabelFromTo";
import styled from "styled-components";
import { citationFiltersInit, citationFiltersFields as f } from "./fields";
import FiltersWrapper from "./FiltersWrapper";
import citationFiltersSchema from "./schema";

export interface CitationFiltersProps {
  resetForm: (arg?: any) => void;
}

const ButtonsWrapper = styled.div`
  display: flex;
  padding: 30px 0;
  justify-content: center;
  > *:not(:first-child) {
    margin-left: 15px;
  }
`;

const CitationListFilters: React.FC<CitationFiltersProps> = ({ resetForm }) => {
  const { getFilteredData } = useCitationContext();
  const handleSubmit = (filters: ICitationFilters) => {
    resetForm();
    getFilteredData(filters);
  };
  return (
    <FiltersWrapper>
      <Formik
        initialValues={citationFiltersInit}
        onSubmit={handleSubmit}
        validationSchema={citationFiltersSchema}
        enableReinitialize
      >
        {({ handleSubmit, handleReset, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <LabelTextField
                  label="COMMON_FORMS_CITATIONID"
                  placeholderTranslation="COMMON_FORMS_CITATIONID"
                  fieldName={f.citationNumber}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <LabelTextField
                  label="COMMON_LABELS_LOCATION"
                  placeholderTranslation="COMMON_LABELS_LOCATION"
                  fieldName={f.locationSearchPhrase}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <LabelTextField
                  label="COMMON_LABELS_VIOLATION"
                  placeholderTranslation="COMMON_LABELS_VIOLATION"
                  fieldName={f.violationSearchPhrase}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DateRangeFields
                  label="COMMON_LABELS_CITATIONISSUE"
                  from={{ fieldName: f.issueDateFrom }}
                  to={{ fieldName: f.issueDateTo }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <LabelFromTo
                  label="COMMON_LABELS_AMOUNT"
                  from={f.amountFrom}
                  to={f.amountTo}
                />
              </Grid>
            </Grid>
            <ButtonsWrapper>
              <FormCancelButton
                disabled={!dirty}
                onClick={handleReset}
                translation="COMMON_BUTTONS_CLEARALL"
              />
              <FormSubmitButton
                translation="COMMON_BUTTONS_FILTER"
                style={{ width: "100%" }}
              />
            </ButtonsWrapper>
          </form>
        )}
      </Formik>
    </FiltersWrapper>
  );
};

export default CitationListFilters;
