import { useCarDataContext } from "Contexts";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import {
  Title,
  CitationSwitch,
  SearchPaper,
  CitationIdForm,
} from "./Components";
import PlateNumberForm from "./Components/PlateNumberForm/PlateNumberForm";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormBox = styled.div`
  margin: 0;
  border-radius: 8px;
  padding: 20px 10px;
  ${(_) => _.theme.breakpoints.up("md")} {
    padding: 20px 40px;
    margin: 40px 0;
    border: 1px solid ${(_) => _.theme.palette.neutral.main};
  }
`;

export const CitationSearch: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ noInit: boolean }>();
  const { getCarData, setStatusInit } = useCarDataContext();

  useEffect(() => {
    !state?.noInit && setStatusInit();
    if (id) getCarData(id);
  }, [getCarData, id, setStatusInit, state?.noInit]);

  return (
    <>
      <SearchPaper>
        <Container>
          <Title />
          <FormBox>
            <CitationSwitch handleChange={setTabValue} value={tabValue} />
            {tabValue === 0 && <CitationIdForm />}
            {tabValue === 1 && <PlateNumberForm />}
          </FormBox>
        </Container>
      </SearchPaper>
    </>
  );
};

export default CitationSearch;
