import { Box } from "@material-ui/core";
import { ICitation, ViolationTypeEnum } from "API/Interfaces";
import { i18nt } from "Translation";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppTypography } from "Layout/Components";
import React from "react";
import styled from "styled-components";
import AppealIcon from "./Appeals/AppealIcon";
import CitationCheckbox from "./CitationCheckbox";
import CitationSummary from "./CitationSummary";

export interface CitationDetailsProps {
  citation: ICitation;
  disabled: boolean;
  phone: string;
  isBootingInLocation: boolean;
}

export const violationTypeLabel: {
  [key in ViolationTypeEnum]: string;
} = {
  [ViolationTypeEnum.Booting]: i18nt("COMMON_LABELS_BOOTING"),
  [ViolationTypeEnum.Standard]: i18nt("COMMON_LABELS_STANDARD"),
};

const CitationDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 20px;

  border-bottom: 1px solid ${(_) => _.theme.palette.neutral.main};
  :last-child {
    border-bottom: none;
  }
  ${(_) => _.theme.breakpoints.up("md")} {
    flex-direction: row;
    align-items: center;
  }
`;

const ViolationWrapper = styled.div`
  min-width: 210px;
`;

const ViolationTypeWrapper = styled.div`
  min-width: 160px;
`;

const CitationDetails: React.FC<CitationDetailsProps> = ({
  citation,
  disabled,
  phone,
  isBootingInLocation,
}) => {
  const { up } = useBreakpoints();

  return (
    <CitationDetailsWrapper>
      <Box display="flex" justifyContent="space-between">
        <CitationCheckbox
          citation={citation}
          disabled={disabled}
          phone={phone}
          isBootingInLocation={isBootingInLocation}
        />
        {up("md") && <AppealIcon citation={citation} />}
      </Box>
      <ViolationWrapper>
        <Box display="flex" justifyContent="space-between">
          <AppTypography>{citation.violation}</AppTypography>
        </Box>
      </ViolationWrapper>
      <ViolationTypeWrapper>
        <AppTypography>
          {violationTypeLabel[citation.violationType]}
        </AppTypography>
      </ViolationTypeWrapper>
      <CitationSummary citation={citation} />
    </CitationDetailsWrapper>
  );
};

export default CitationDetails;
