import { Grid } from "@material-ui/core";
import { AppealStatus, CheckboxType } from "API/Interfaces";
import CitationServiceApi from "API/Services/CitationServiceApi";
import { useCitationContext, useLoaderContext } from "Contexts";
import { useAppealContext } from "Contexts/AppealContext";
import { useErrorContext } from "Contexts/ErrorContext";
import { usePopupContext } from "Contexts/PopupContext";
import { Formik, Form } from "formik";
import { useTranslate } from "Hooks";
import { AppTypography, AppButton } from "Layout/Components";
import AppDialog from "Layout/Components/AppDialog";
import Attachments from "Layout/Components/AppDropzone";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import { FormSubmitButton, FormTextField } from "Layout/FormComponents";
import {
  appealFormFieldNames as f,
  initialAppealFormData,
  appealFormValidationSchema,
  PaymentForm,
  IDisputeForm,
  appealFormToRequest,
} from "Pages/PaymentDetails/Components";
import { useState } from "react";
import styled from "styled-components";
import { isErrorCode } from "Utils/utils";
import * as yup from "yup";

const StyledFormTextField = styled(FormTextField)`
  .MuiInputBase-root {
    height: auto;
  }
`;

export const AppealForm: React.FC = () => {
  const t = useTranslate();
  const { showAppealPopup, setShowAppealPopup, citationId, setCitationId } =
    useAppealContext();
  const { locations, getFilteredData, filters, setSelectedLocation } =
    useCitationContext();
  const { setError } = useErrorContext();
  const { setLoading } = useLoaderContext();
  const { setPopup, showPopup } = usePopupContext();
  const [fileIds, setFileIds] = useState<string[]>([]);

  const handleSubmit = (v: IDisputeForm) => {
    setLoading(true);
    setPopup({
      variant: "info",
      titleTranslation: "COMMON_FORMS_DISPUTE",
      descriptionTitle: t("COMMON_DIALOGS_SUCCESS"),
    });

    CitationServiceApi.setNewAppeal(citationId, appealFormToRequest(v, fileIds))
      .then(() => {
        showPopup();
        setCitationId("");
        setSelectedLocation((location) => {
          if (location) {
            const index = location.citations.findIndex(
              ({ id }) => id === citationId
            );
            if (index >= 0) {
              location.citations[index].appealStatus = AppealStatus.Open;
              location.citations[index].canOpenDispute = false;
            }
          }
          return location;
        });
        if (locations?.length) getFilteredData(filters);
        setShowAppealPopup(false);
      })
      .catch((e) =>
        setError(
          isErrorCode(e, "ResourceExists")
            ? t("COMMON_DIALOGS_DISPUTEALREADYEXISTS")
            : undefined
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <AppDialog
      maxWidth="xl"
      open={showAppealPopup}
      onClose={() => setShowAppealPopup(false)}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialAppealFormData}
        validationSchema={yup.object(appealFormValidationSchema)}
      >
        {({ isValid }) => (
          <Form>
            <Grid container spacing={4} justifyContent="space-between">
              <Grid container item xs={6} alignItems="flex-start">
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <AppTypography
                      variant="h2"
                      weight="bold"
                      translation="COMMON_PAGES_DISPUTEREASON"
                    />
                    <AppTypography
                      translation="COMMON_PAGES_ENTERDISPUTE"
                      paddingTop={1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledFormTextField
                      fieldName={f.reason}
                      multiline
                      translationLabel="COMMON_FORMS_MAXCHARACTER500"
                      inputRequired
                      rows={10}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <AppTypography
                      variant="h2"
                      weight="bold"
                      translation="COMMON_DIALOGS_ATTACHMENTS"
                    />
                    <AppTypography
                      translation="COMMON_DIALOGS_MAX2FILES"
                      paddingTop={1}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ minHeight: "210px" }}>
                    <Attachments fileIds={fileIds} setFileIds={setFileIds} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={6}>
                <PaymentForm checkbox={CheckboxType.RegisteredOwner} />
              </Grid>
              <ButtonWrapper marginBottom={2}>
                <AppButton
                  variant="outlined"
                  translation="COMMON_BUTTONS_CLOSE"
                  onClick={() => setShowAppealPopup(false)}
                />
                <FormSubmitButton
                  translation="COMMON_BUTTONS_OPENDISPUTE"
                  disabled={!isValid}
                />
              </ButtonWrapper>
            </Grid>
          </Form>
        )}
      </Formik>
    </AppDialog>
  );
};

export default AppealForm;
