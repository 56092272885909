import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

export interface LoadingProps {
  show: boolean;
}

const LoadingBar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #6b6b6b38;
  z-index: 1400;
`;

const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <>
      {props.show && (
        <LoadingBar>
          <CircularProgress />
        </LoadingBar>
      )}
    </>
  );
};

export default Loading;
