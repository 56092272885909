import { ICitation } from "API/Interfaces";
import { PopupIcons } from "Assets/Icons";
import { useTranslate } from "Hooks";
import AppDialog from "Layout/Components/AppDialog";
import CitationDetailsWithoutButton from "Pages/PaymentDetails/Components/CitationDetails/CitationDetailsWithoutButton";
import React, { ReactNode, useCallback, useContext } from "react";
import { TranslationKeys } from "Translation";

export interface IPopupProps {
  variant: "info" | "error" | "citation";
  buttonOkText?: TranslationKeys;
  buttonOkClick?: () => void;
  titleTranslation?: TranslationKeys | "";
  cancelButtonText?: TranslationKeys | "";
  title?: string;
  description?: string;
  descriptionTitle?: string;
  descriptionTitleTranslation?: TranslationKeys | null;
  descriptionTranslation?: TranslationKeys | null;
  Icon?: React.FC | null;
  citationDetails?: ICitation | null;
  phone?: string;
  descriptionNode?: ReactNode;
}
const initProps: Required<IPopupProps> = {
  variant: "info",
  buttonOkText: "COMMON_BUTTONS_CLOSE",
  buttonOkClick: () => undefined,
  cancelButtonText: "",
  titleTranslation: "",
  descriptionTranslation: null,
  title: "",
  description: "",
  descriptionTitle: "",
  descriptionTitleTranslation: null,
  Icon: PopupIcons.Success,
  citationDetails: null,
  phone: "",
  descriptionNode: null,
};
export type PopupContextType = {
  setPopup: (arg: IPopupProps) => void;
  showPopup: (arg?: boolean) => void;
};
export const PopupContext = React.createContext<PopupContextType>({
  setPopup: () => undefined,
  showPopup: () => undefined,
});
export const usePopupContext = () => useContext(PopupContext);

export const PopupProvider: React.FC = (props) => {
  const t = useTranslate();
  const [showPopup, setShowPopup] = React.useState(false);
  const [popupProps, setPopupProps] =
    React.useState<Required<IPopupProps>>(initProps);

  const setPopup = useCallback((args: IPopupProps) => {
    let mergedProps = { ...initProps, ...args };

    if (mergedProps.variant === "info") {
      mergedProps.Icon = PopupIcons.Success;
      mergedProps.descriptionTitleTranslation = "COMMON_DIALOGS_SUCCESS";
      mergedProps.citationDetails = null;
      mergedProps.phone = "";
    }

    if (mergedProps.variant === "error") {
      mergedProps.Icon = PopupIcons.Error;
      mergedProps.titleTranslation = "COMMON_DIALOGS_ERROR";
      mergedProps.descriptionTitleTranslation = "COMMON_DIALOGS_ERROR";
      if (args?.descriptionNode)
        mergedProps.descriptionNode = args.descriptionNode;
      mergedProps.citationDetails = null;
      mergedProps.phone = "";
      if (!args.description) {
        mergedProps.descriptionTranslation =
          "COMMON_DIALOGS_UNEXPECTEDERRORPLEASETRYAGAIN";
      }
    }
    if (mergedProps.variant === "citation") {
      mergedProps.Icon = null;
    }

    mergedProps = {
      ...mergedProps,
      ...args,
    };
    setPopupProps(mergedProps);
  }, []);

  const handleOpen = useCallback((arg = true) => setShowPopup(arg), []);

  const handleClose = () => {
    popupProps.buttonOkClick();
    setShowPopup(false);
  };

  return (
    <PopupContext.Provider value={{ showPopup: handleOpen, setPopup }}>
      {props.children}
      <AppDialog
        open={showPopup}
        onClose={handleClose}
        agreeAction={handleClose}
        agreeButton={t(popupProps.buttonOkText)}
        cancelButton={
          popupProps.cancelButtonText ? t(popupProps.cancelButtonText) : ""
        }
        handleOpen={setShowPopup}
        Icon={popupProps.Icon || undefined}
        title={
          popupProps.titleTranslation
            ? t(popupProps.titleTranslation)
            : popupProps.title
        }
        descriptionTitle={
          popupProps.descriptionTitleTranslation
            ? t(popupProps.descriptionTitleTranslation)
            : popupProps.descriptionTitle
        }
        description={`${
          popupProps.descriptionTranslation
            ? t(popupProps.descriptionTranslation)
            : popupProps.description
        }`}
      >
        {popupProps.citationDetails && (
          <CitationDetailsWithoutButton
            citation={popupProps.citationDetails}
            phoneNumber={popupProps.phone}
          />
        )}
        {popupProps?.descriptionNode}
      </AppDialog>
    </PopupContext.Provider>
  );
};

export default PopupProvider;
