import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AppTypography, CountdownTimer } from "Layout/Components";
import { TezLogo } from "Assets/Images/TezLogo";
import { useCarDataContext } from "Contexts/CarDataContext";
import { useDictionariesContext } from "Contexts/DictionaryContext";
import { useTimerContext } from "Contexts/TimerContext";
import { useLocation, matchPath } from "react-router-dom";
import { routes } from "Routing/routes";

interface AppTopBarProps {}

const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  min-height: 54px;
  padding: 0 12px;
  ${(_) => _.theme.breakpoints.up("md")} {
    min-height: 90px;
    padding: 0 50px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div`
  height: 30px;
  > * {
    height: 100%;
  }
  ${(_) => _.theme.breakpoints.up("md")} {
    height: 55px;
  }
`;

const CarDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${(_) => _.theme.breakpoints.up("md")} {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid ${(_) => _.theme.palette.neutral.main};
    align-items: center;
  }
`;

const CaptionText = styled.div`
  background-color: ${(_) => _.theme.palette.neutral.secound};
  padding: 3px 10px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PageTitle = styled((props) => <AppTypography {...props} />)<{
  $status: boolean;
}>`
  display: ${(_) => (_.$status ? "none" : false)};
  ${(_) => _.theme.breakpoints.up("md")} {
    display: block;
  }
`;

export const AppTopBar: React.FC<AppTopBarProps> = () => {
  const { pathname } = useLocation();
  const [carDetails, setCarDetails] = useState({ carMake: "", state: "" });
  const { carData, status } = useCarDataContext();
  const { dictionaries } = useDictionariesContext();
  const { carMakes, states } = dictionaries;
  const { timerExpire } = useTimerContext();

  useEffect(() => {
    setCarDetails({
      carMake: carMakes.find((e) => e.id === carData.makeId && e)?.name || "",
      state:
        states.find((e) => e.id === carData.stateId && e.symbol)?.symbol || "",
    });
    // eslint-disable-next-line
  }, [carData]);

  return (
    <TopBarContainer>
      <Wrapper>
        <PageTitle
          variant="h1"
          weight="bold"
          translation={
            matchPath(pathname, routes.mailUnassignment)
              ? "COMMON_PAGES_EMAILSUBSCRIPTION"
              : "COMMON_PAGES_PAYFORYOURCITATION"
          }
          $status={status.success}
        />
        {carData.plate && (
          <CarDetailsWrapper>
            <AppTypography variant="h3" weight="bold">
              {carData.plate}
            </AppTypography>
            <CaptionText>
              <AppTypography variant="caption">
                {carDetails.carMake}, {carDetails.state}
              </AppTypography>
            </CaptionText>
          </CarDetailsWrapper>
        )}
      </Wrapper>
      <Wrapper>
        {!!timerExpire && <CountdownTimer />}
        <LogoWrapper>
          <TezLogo />
        </LogoWrapper>
      </Wrapper>
    </TopBarContainer>
  );
};

export default AppTopBar;
