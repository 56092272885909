const GENERAL = "general";
const CITATIONS = "citations";
const PAYMENT = "payment";
const PAYMENT_TOKEN = "payment-token";
const REDIRECT = "redirect";
const FILES = "files";

const dictionaries = `${GENERAL}/dictionaries`;
const citations = {
  root: `${CITATIONS}`,
  searchData: `${CITATIONS}/search-data`,
  payment: `${CITATIONS}/${PAYMENT}`,
  paymentRedirect: (id: string) => `${REDIRECT}/${PAYMENT_TOKEN}/${id}`,
  paymentStatus: (id: string) => `${CITATIONS}/${PAYMENT}/${id}/status`,
  details: `${CITATIONS}/details`,
  mailUnassignment: (token: string) => `${REDIRECT}/mail-unassignment/${token}`,
  addAppeal: (id: string) => `${CITATIONS}/${id}/appeal/`,
  appealDetails: (id: string) => `${CITATIONS}/${id}/appeal/details`,
};

const files = {
  attachment: `${FILES}/attachment`,
  files: `${FILES}`,
};

export const urls = {
  dictionaries,
  citations,
  files,
};
