import { Box } from "@material-ui/core";
import { AppTypography } from "Layout/Components";
import { FormTextField, FormTextFieldProps } from "Layout/FormComponents";
import React from "react";
import { TranslationKeys } from "Translation";
import CloseIcon from "@material-ui/icons/Close";
import { useFormikContext } from "formik";
import styled from "styled-components";
export const FieldsWrapper = styled(Box)`
  display: flex;
  flex-grow: 1;
  > *:not(:last-child) {
    margin-right: ${(_) => _.theme.spacing(2)}px;
  }
`;

export interface LabelFieldProps {
  label?: TranslationKeys;
}

export const LabelWrapper: React.FC<LabelFieldProps> = (props) => (
  <Box display="flex" flexDirection="column" width="100%">
    {props.label && (
      <AppTypography weight="bold" translation={props.label} size={1.1} />
    )}
    <FieldsWrapper>{props.children}</FieldsWrapper>
  </Box>
);

export const TextFieldWithClear: React.FC<FormTextFieldProps> = (props) => {
  const { setFieldValue, values, initialValues } = useFormikContext<any>();
  return (
    <FormTextField
      endAdornmentIcon={
        <CloseIcon
          color="primary"
          style={{
            cursor: "pointer",
            pointerEvents: values[props.fieldName] ? "auto" : "none",
            opacity: values[props.fieldName] ? 1 : 0,
          }}
          onClick={() =>
            setFieldValue(props.fieldName, initialValues[props.fieldName])
          }
        />
      }
      {...props}
    />
  );
};

export const LabelTextField: React.FC<LabelFieldProps & FormTextFieldProps> = (
  props
) => {
  const { label, ...rest } = props;
  return (
    <LabelWrapper label={label}>
      <TextFieldWithClear {...rest} />
    </LabelWrapper>
  );
};

export default LabelTextField;
