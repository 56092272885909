import React from "react";
import styled, { css } from "styled-components";

export interface ButtonWrapperProps {
  /** MUI spacing */
  topSpacing?: number;
  bottomSpacing?: number;
  justify?: React.CSSProperties["justifyContent"];
  /** MUI spacing */
  marginBottom?: number;
}

const Wrapper = styled.div<{
  $topSpacing?: number;
  $bottomSpacing?: number;
  $justify?: React.CSSProperties["justifyContent"];
  $marginBottom?: number;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${(_) =>
    css`
      justify-content: ${_.$justify};
      margin-bottom: ${_.theme.spacing(_.$marginBottom)}px;
      padding-top: ${_.theme.spacing(_.$topSpacing)}px;
      padding-bottom: ${_.theme.spacing(_.$bottomSpacing)}px;
    `}
  > *:not(:first-child) {
    margin-left: 15px;
  }
`;
Wrapper.defaultProps = {
  $justify: "center",
  $marginBottom: 0,
  $bottomSpacing: 0,
  $topSpacing: 0,
};
export const ButtonWrapper: React.FC<ButtonWrapperProps> = ({
  topSpacing,
  justify,
  marginBottom,
  bottomSpacing,
  ...props
}) => {
  return (
    <Wrapper
      $topSpacing={topSpacing}
      $justify={justify}
      $marginBottom={marginBottom}
      $bottomSpacing={bottomSpacing}
      {...props}
    >
      {props.children}
    </Wrapper>
  );
};

export default ButtonWrapper;
