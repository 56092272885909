import React from "react";
import { Tooltip, TooltipProps } from "@material-ui/core";
import { TranslationKeys } from "Translation";
import { AppTypography } from ".";
import { InfoIcon } from "Assets/Icons";
import useBreakpoints from "Hooks/useBreakpoints";

export interface AppTooltipProps extends Partial<TooltipProps> {
  translation?: TranslationKeys;
}

const AppTooltip: React.FC<AppTooltipProps> = (props) => {
  const { down } = useBreakpoints();

  const { translation, children, placement, ...rest } = props;
  return (
    <Tooltip
      title={<AppTypography translation={translation} />}
      placement={down("sm") ? placement : undefined}
      {...rest}
    >
      {children || <InfoIcon />}
    </Tooltip>
  );
};

export default AppTooltip;
