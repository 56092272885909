import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { ICitationFilters, ILocation } from "API/Interfaces";
import useGenericList from "Hooks/useGenericList";
import CitationServiceApi from "API/Services/CitationServiceApi";
import { useCarDataContext } from "Contexts";
import { Redirect } from "react-router";
import { routes } from "Routing/routes";
import { values } from "lodash";
import { useTimerContext } from "Contexts/TimerContext";
import { getTimeToCountdown } from "Utils/utils";
import AppealProvider from "./AppealContext";

export interface CitationContextType {
  locations: ILocation[];
  selectedLocation: ILocation | null;
  setSelectedLocation: React.Dispatch<React.SetStateAction<ILocation | null>>;
  page: number;
  numberOfPages: number;
  getData: (p?: number, s?: string, params?: any) => Promise<ILocation[]>;
  getFilteredData: (params?: any) => void;
  filters: ICitationFilters | null;
}

export const CitationContext = createContext<CitationContextType>(
  {} as CitationContextType
);

export const useCitationContext = () => useContext(CitationContext);

export const CitationContextProvider: React.FC = ({ children }) => {
  const { carData } = useCarDataContext();
  const { data, page, getData, numberOfPages, getClearData, restData } =
    useGenericList<ILocation>(CitationServiceApi.getCitations, carData);
  const filters = useRef<ICitationFilters | null>(null);

  const { state } = useLocation<{ location: ILocation }>();
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(
    state?.location || null
  );

  const { timerExpire, setTimerExpire } = useTimerContext();

  useEffect(() => {
    if (data && !timerExpire) {
      setTimerExpire(restData.expirationDateUTC.getTime());
    }
    if (state?.location) {
      setSelectedLocation(state.location);
    } else if (checkIsSingleData() && !filters.current) {
      setSelectedLocation(data && data[0]);
    }
    window.sessionStorage.setItem("CarData", JSON.stringify(carData));
    // eslint-disable-next-line
  }, [data]);

  const getFilteredData = useCallback(
    async (f: ICitationFilters | null) => {
      filters.current = values(f).some((param) => !!param) ? f : null;
      getClearData({ ...carData, ...f });
    },
    [carData, getClearData]
  );

  useEffect(() => {
    if (data && data.length) {
      const timeout = setTimeout(() => {
        setTimerExpire(0);
        getFilteredData(filters.current);
        if (selectedLocation) {
          CitationServiceApi.getSelectedCitations(
            selectedLocation.citations.map((e) => e.id)
          ).then(setSelectedLocation);
        }
      }, getTimeToCountdown(restData.expirationDateUTC.getTime()));
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [
    data,
    getFilteredData,
    restData?.expirationDateUTC,
    selectedLocation,
    setTimerExpire,
  ]);

  const checkIsSingleData = () =>
    data &&
    numberOfPages === 1 &&
    data.length === 1 &&
    data[0].citations.length === 1;

  return (
    <CitationContext.Provider
      value={{
        selectedLocation,
        locations: data ? data : [],
        setSelectedLocation,
        page,
        numberOfPages,
        getData,
        getFilteredData,
        filters: filters.current,
      }}
    >
      <AppealProvider>
        {checkIsSingleData() && !filters.current && selectedLocation && (
          <Redirect to={routes.pay} />
        )}
        {data && children}
      </AppealProvider>
    </CitationContext.Provider>
  );
};
