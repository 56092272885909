import { Box, Button, makeStyles, Theme } from "@material-ui/core";
import { ICitation } from "API/Interfaces";
import { usePopupContext } from "Contexts/PopupContext";
import { useTranslate } from "Hooks";
import { AppTypography } from "Layout/Components";
import React from "react";
import styled from "styled-components";

interface CitationsListProps {
  citations: ICitation[];
  phone: string;
}

const CitationsContainer = styled.div`
  padding: 5px;
  border-radius: 12px;
  border: 1px solid;
  border-color: ${(_) => _.theme.palette.neutral.main};
  height: 300px;
  margin-bottom: 20px;
`;

const ListContainer = styled.div`
  padding: 15px;
  height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f2f2f2;

    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;

    border-radius: 10px;
  }
`;

const ListDataItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: ${(_) => _.theme.palette.neutral.main};
`;

const useStyles = makeStyles((theme: Theme) => {
  return {
    detailsButton: {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: "12px",
      padding: 0,
    },
  };
});

export const CitationsList: React.FC<CitationsListProps> = ({
  citations,
  phone,
}) => {
  const classes = useStyles();
  const t = useTranslate();

  const { setPopup, showPopup } = usePopupContext();

  return (
    <CitationsContainer>
      <ListContainer>
        {citations.map((e) => {
          return (
            <React.Fragment key={e.id}>
              <ListDataItem>
                <Box>
                  <AppTypography variant="body1">{e.violation}</AppTypography>
                  <AppTypography variant="h2" weight="bold" size={1}>
                    {e.number}
                  </AppTypography>
                </Box>
                <Box>
                  <Button
                    className={classes.detailsButton}
                    onClick={() => {
                      setPopup({
                        variant: "citation",
                        citationDetails: e,
                        phone: phone,
                      });
                      showPopup();
                    }}
                  >
                    {t("COMMON_BUTTONS_DETAILS")}
                  </Button>
                </Box>
              </ListDataItem>
              <Separator />
            </React.Fragment>
          );
        })}
      </ListContainer>
    </CitationsContainer>
  );
};

export default CitationsList;
