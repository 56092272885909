import { MenuItem } from "@material-ui/core";
import React from "react";
import { ISelectDictionary } from "API/Interfaces";
import { FormSelectField, FormSelectFieldProps } from "Layout/FormComponents";
import { useTranslate } from "Hooks";
import { TranslationKeys } from "Translation";

export interface DictionarySelectProps extends FormSelectFieldProps {
  dictionary: ISelectDictionary;
  emptyLabel?: TranslationKeys;
}

export const DictionarySelect: React.FC<DictionarySelectProps> = ({
  dictionary,
  emptyLabel,
  ...rest
}) => {
  const t = useTranslate();

  return (
    <FormSelectField MenuProps={{ style: { maxHeight: 500 } }} {...rest}>
      {emptyLabel && (
        <MenuItem value="" disabled>
          <em>{t(emptyLabel)}</em>
        </MenuItem>
      )}
      {dictionary?.map((item: any) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name || item.value || item.symbol}
        </MenuItem>
      ))}
    </FormSelectField>
  );
};

export default DictionarySelect;
