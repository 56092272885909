import { Accordion, AccordionDetails, RadioGroup } from "@material-ui/core";
import { CitationType, ILocation } from "API/Interfaces";
import { useFormikContext } from "formik";
import { citationListFields, ICitationListForm } from "../schema";
import CitationDetails from "./CitationDetails";
import LocationAccordion from "./LocationAccordion";
import { isEqual } from "lodash";
import styled from "styled-components";
import { ChangeEvent } from "react";

export interface LocationListProps {
  locations: ILocation[];
}

const CitationAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LocationList: React.FC<LocationListProps> = ({ locations }) => {
  const { values, setFieldValue } = useFormikContext<ICitationListForm>();
  const handleChangeLocation = (e: ChangeEvent<HTMLInputElement>) => {
    const location = JSON.parse(e.currentTarget.value) as ILocation;
    const isBootingCitations = location.citations.some(
      (c) => c.citationType === CitationType.BootingCitation
    );
    setFieldValue(citationListFields.location, location);
    isBootingCitations &&
      setFieldValue(citationListFields.citations, location.citations);
  };

  return (
    <RadioGroup
      value={JSON.stringify(values.location)}
      onChange={handleChangeLocation}
    >
      {locations.map((location) => (
        <Accordion key={location.name}>
          <LocationAccordion location={location} />
          <CitationAccordionDetails>
            {location.citations.map((citation) => (
              <CitationDetails
                key={citation.id}
                isBootingInLocation={location.citations.some(
                  (c) => c.citationType === CitationType.BootingCitation
                )}
                citation={citation}
                phone={location.phoneNumber}
                disabled={!isEqual(values.location, location)}
              />
            ))}
          </CitationAccordionDetails>
        </Accordion>
      ))}
    </RadioGroup>
  );
};

export default LocationList;
