import { Grid, IconButton } from "@material-ui/core";
import {
  AddFileIcon,
  CloseIcon,
  RejectedAddFileIcon,
  SucceededAddFileIcon,
} from "Assets/Icons";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { themeComponents } from "../theme";
import AppTypography from "./AppTypography";
import { usePopupContext } from "Contexts/PopupContext";
import { useTranslate } from "Hooks";
import FileServiceApi, { AddedAttachments } from "API/Services/FileService";
import { MAX_FILE_COUNT, MAX_FILE_SIZE } from "Assets/consts";

export interface DropzoneProps {
  setFileIds: (ids: string[]) => void;
  fileIds: string[];
}

const AddFileSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #9275ff;
  border-style: dashed;
  background-color: rgba(123, 89, 251, 0.05);
  color: ${themeComponents.secondary};
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: 100%;
`;

const GridWithShadow = styled(Grid)`
  box-shadow: 0 0 4px;
  border-radius: 6px;
  background-color: #fafafa;
  padding: 10px 0 5px 0;
  margin: 7px 0;
`;

const Attachments: React.FC<DropzoneProps> = ({ setFileIds, fileIds }) => {
  const t = useTranslate();
  const [files, setFiles] = useState<AddedAttachments[]>([]);
  const { setPopup, showPopup } = usePopupContext();

  const onRemove = (index: number) => {
    let filesToSave = [...files];
    filesToSave.splice(index, 1);
    setFiles(filesToSave);
    setFileIds(filesToSave.map((f) => f.fileId));
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    maxFiles: 2,
    maxSize: MAX_FILE_SIZE,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
      "application/vnd.oasis.opendocument.text": [".odt"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "video/x-msvideo": [".avi"],
      "video/mpeg": [".mpg", ".mpfg"],
      "image/bmp": [".bmp"],
      "image/gif": [".gif"],
      "application/msword": [".doc"],
      "application/pdf": [".pdf"],
      "application/rtf": [".rtf"],
      "text/plain": [".txt"],
      "text/csv": [".csv"],
    },
    onDrop: async (acceptedFiles, fileRejections) => {
      const isFileTooLarge =
        fileRejections.length > 0 &&
        fileRejections[0].file.size > MAX_FILE_SIZE;
      const isTooMuchFiles =
        files.length + acceptedFiles.length > MAX_FILE_COUNT;
      const isFileTypeInvalid =
        fileRejections.length > 0 &&
        fileRejections[0].errors[0].code === "file-invalid-type";
      if (isFileTooLarge) {
        setPopup({
          variant: "error",
          description: t("COMMON_DIALOGS_TOOLARGEFILE"),
        });
        showPopup();
      } else if (isFileTypeInvalid) {
        setPopup({
          variant: "error",
          description: t("COMMON_DIALOGS_INVALIDFORMAT"),
        });
        showPopup();
      } else if (isTooMuchFiles) {
        setPopup({
          variant: "error",
          description: t("COMMON_DIALOGS_TOOMUCHFILES"),
        });
        showPopup();
      } else {
        acceptedFiles.forEach((file, index) => {
          const newFile = files;
          FileServiceApi.addAttachment(file)
            .then((res) => {
              newFile.push(res.data[0]);
              setFiles(newFile);
              setFileIds(newFile.map((f) => f.fileId));
            })
            .catch(() => {
              const f = acceptedFiles.find((f) => f.name === file.name);
              if (f) {
                newFile.push({
                  fileId: `${file.name}_${index}`,
                  fileName: file?.name ?? "file",
                  isRejected: true,
                });
              }
            })
            .finally(() => setFiles([...newFile]));
        });
      }
    },
  });

  const thumbs =
    files.length > 0 && acceptedFiles ? (
      <Grid container item>
        {files.map((file, index) => {
          return (
            <GridWithShadow container item key={index} alignItems="center">
              <Grid item xs={2}>
                {file.isRejected ? (
                  <RejectedAddFileIcon />
                ) : (
                  <SucceededAddFileIcon />
                )}
              </Grid>
              <Grid item xs={9}>
                <AppTypography
                  weight={700}
                  variant="subtitle1"
                  color={file.isRejected ? "error" : "secondary"}
                >
                  {file.fileName}
                </AppTypography>
                {file.isRejected ? (
                  <AppTypography
                    variant="subtitle2"
                    translation="COMMON_DIALOGS_ERRORDURINGTHEUPLOAD"
                    style={{ color: themeComponents.neutral }}
                  />
                ) : null}
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(index);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </GridWithShadow>
          );
        })}
      </Grid>
    ) : (
      <Grid container item alignItems="center" style={{ marginTop: "30px" }}>
        <AddFileIcon />
        <AppTypography
          weight={700}
          variant="h6"
          translation="COMMON_DIALOGS_DRAGANDDROP"
        />
      </Grid>
    );

  return (
    <>
      <AddFileSection>
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{ height: "160px" }}
        >
          <input {...getInputProps()} />
          {thumbs}
        </div>
      </AddFileSection>
    </>
  );
};

export default Attachments;
