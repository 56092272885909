import { Dialog, DialogContent, Box } from "@material-ui/core";
import { AppButton, AppTypography } from "Layout/Components";
import React, { useState } from "react";
import { ICitationListForm } from "../schema";
import { checkUpcharges } from "../Utils/utils";

const useCitationUpcharges = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [description, setDescription] = useState("");

  const isUpchargeRequired = (form: ICitationListForm): boolean => {
    const { isRequired, description } = checkUpcharges(form);
    if (isRequired) {
      setLocationName(form.location?.name || "");
      setDescription(description);
      setIsDialogOpen(true);
    }
    return isRequired;
  };

  const UpchargeDialog: React.FC = () => (
    <Dialog fullWidth open={isDialogOpen}>
      <AppTypography
        size={1.25}
        weight={700}
        translation="COMMON_DIALOGS_UPCHARGEPAYMENTREQUIRED"
        style={{ padding: 16 }}
      />
      <DialogContent dividers>
        <Box display="flex" flexDirection="column">
          <AppTypography weight={700}>{locationName}</AppTypography>
          <AppTypography paddingTop={2}>{description}</AppTypography>
        </Box>
        <Box display="flex" justifyContent="center" paddingY={2}>
          <AppButton
            variant="outlined"
            translation="COMMON_BUTTONS_CLOSE"
            onClick={() => setIsDialogOpen(false)}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );

  return { isUpchargeRequired, UpchargeDialog } as const;
};

export default useCitationUpcharges;
