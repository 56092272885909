import { ICitationFilters } from "API/Interfaces";
import { i18nt } from "Translation";
import * as Yup from "yup";

export type YupSchemaKeys<T> = {
  [K in keyof T]: any;
};

const citationFiltersSchema: { [K in keyof ICitationFilters]: any } = {
  citationNumber: Yup.string(),
  locationSearchPhrase: Yup.string(),
  violationSearchPhrase: Yup.string(),
  issueDateFrom: Yup.date()
    .typeError(i18nt("COMMON_FORMS_INCORRECTDATEFORMAT"))
    .nullable(),
  issueDateTo: Yup.date()
    .typeError(i18nt("COMMON_FORMS_INCORRECTDATEFORMAT"))
    .min(Yup.ref("issueDateFrom"), i18nt("COMMON_FORMS_DATETOERROR"))
    .nullable(),
  amountFrom: Yup.number().nullable(),
  amountTo: Yup.number()
    .min(Yup.ref("amountFrom"), i18nt("COMMON_FORMS_AMOUNTTOERROR"))
    .nullable(),
};

export default Yup.object().shape(citationFiltersSchema);
