import { useFormikContext } from "formik";
import { AppTextFieldProps } from "Layout/Components";
import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { FormTextField } from "./FormTextField";

export interface FormNumberInputProps extends NumberFormatProps<any> {
  fieldName: string;
  textFieldProps?: AppTextFieldProps;
}

export const FormNumberInput: React.FC<FormNumberInputProps> = ({
  fieldName,
  textFieldProps,
  ...props
}) => {
  const [untouchedError, setUntouchedError] = React.useState(false);
  const ref = React.useRef<number | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (!untouchedError && values[fieldName]) {
      setUntouchedError(true);
    }
  });
  const { setFieldValue, values, validateField } = useFormikContext<any>();

  return (
    <NumberFormat
      value={values[fieldName] !== null ? values[fieldName] : ""}
      onValueChange={(e) => {
        ref.current = e.floatValue ?? null;
      }}
      onBlur={() => {
        setFieldValue(fieldName, ref.current);
        validateField(fieldName);
      }}
      type="tel"
      customInput={(p) => {
        return (
          <FormTextField
            fieldName={fieldName}
            untouchedError={untouchedError}
            {...p}
            {...textFieldProps}
          />
        );
      }}
      {...props}
    />
  );
};

export default FormNumberInput;
