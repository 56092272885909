import { IFetchStatus, IFetchStatusObj } from "API/Interfaces";
import React, { useMemo } from "react";

const { INIT, LOADING, ERROR, SUCCESS } = IFetchStatus;
export const useFetchStatus = () => {
  const [status, setStatus] = React.useState<IFetchStatus>(INIT);

  const setFetchStatus = useMemo(
    () => ({
      init: () => setStatus(INIT),
      loading: () => setStatus(LOADING),
      error: () => setStatus(ERROR),
      success: () => setStatus(SUCCESS),
    }),
    []
  );

  const fetchStatus: IFetchStatusObj = useMemo(
    () => ({
      init: status === INIT,
      loading: status === LOADING,
      error: status === ERROR,
      success: status === SUCCESS,
    }),
    [status]
  );

  return [fetchStatus, setFetchStatus] as const;
};

export default useFetchStatus;
