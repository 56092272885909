import Loading from "Layout/Components/Loading";
import React, { useContext } from "react";

export type LoaderContextType = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
};
export const LoaderContext = React.createContext<LoaderContextType>({
  setLoading: () => undefined,
  isLoading: false,
});
export const useLoaderContext = () => useContext(LoaderContext);

export const LoaderProvider: React.FC = (props) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <LoaderContext.Provider value={{ isLoading: loading, setLoading }}>
      <Loading show={loading} />
      {props.children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
