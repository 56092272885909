import Button, {
  ButtonProps as MUIButtonProps,
} from "@material-ui/core/Button";
import styled, { css } from "styled-components";
import React from "react";
import { useTranslate } from "Hooks";
import { TranslationKeys } from "Translation";
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

export interface AppButtonProps extends MUIButtonProps {
  letterCase?: "lowercase" | "uppercase" | "none";
  hasHoverBg?: boolean;
  fontWeight?: number | string;
  minWidth?: number;
  topGutter?: number;
  textAlign?: "left" | "center" | "right" | "inherit";
  fontSize?: string;
  /** text is to auto translate */
  translation?: TranslationKeys;
  loading?: boolean;
}

const ButtonWrapper = styled.div<AppButtonProps>`
  padding-top: ${(_) => _.topGutter}px;
  text-align: ${(_) => _.textAlign};
  position: relative;
  button {
    ${(_) =>
      _.fontWeight &&
      css`
        font-weight: ${_.fontWeight};
      `}
    font-size: ${(_) => _.fontSize && _.fontSize};
    ${(_) =>
      _.letterCase &&
      css`
        text-transform: ${_.letterCase};
      `}
    min-width: ${(_) => (_.minWidth ? `${_.minWidth}px` : "auto")};
    &.MuiButton-outlinedPrimary {
      background-color: #fff;
    }
    :hover {
      ${(_) =>
        !_.hasHoverBg
          ? css`
              background-color: inherit;
            `
          : {}}
    }
  }
  .MuiButton-startIcon {
    margin-top: -2px;
    margin-right: 4px;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -15,
      marginLeft: -15,
    },
  })
);

export const AppButton: React.FC<AppButtonProps> = ({
  letterCase,
  hasHoverBg,
  fontWeight,
  minWidth,
  topGutter,
  textAlign,
  translation,
  loading,
  fontSize,
  ...props
}) => {
  const t = useTranslate();
  const classes = useStyles();
  return (
    <ButtonWrapper
      letterCase={letterCase}
      hasHoverBg={hasHoverBg}
      fontWeight={fontWeight}
      minWidth={minWidth}
      topGutter={topGutter}
      textAlign={textAlign}
      fontSize={fontSize}
    >
      <Button data-testid="button" disabled={loading} {...props}>
        {props.children || t(translation)}
      </Button>
      {loading && (
        <CircularProgress size={30} className={classes.buttonProgress} />
      )}
    </ButtonWrapper>
  );
};
AppButton.defaultProps = {
  hasHoverBg: true,
  topGutter: 0,
  fontWeight: 700,
  textAlign: "inherit",
  type: "button",
  color: "primary",
  variant: "contained",
  minWidth: 160,
};
export default AppButton;
