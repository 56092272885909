import React from "react";
import { AppPaper } from "Layout/Components";
import styled from "styled-components";

interface PaymentStatusPaperProps {}

const StyledPaper = styled(AppPaper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: calc(100% - 20px);
  padding: 16px 20px;
  > * {
    margin: 10px 0;
  }
  ${(_) => _.theme.breakpoints.up("lg")} {
    width: 1100px;
  }
`;

export const PaymentStatusPaper: React.FC<PaymentStatusPaperProps> = ({
  children,
}) => {
  return <StyledPaper>{children}</StyledPaper>;
};

export default PaymentStatusPaper;
