import { Box } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTranslate } from "Hooks";
import { AppTypography } from "Layout/Components";
import AppDollar from "Layout/Components/AppDollar";
import React from "react";
import styled from "styled-components";
import { ICitationListForm } from "../schema";

const TotalSummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  ${(_) => _.theme.breakpoints.up("sm")} {
    margin-right: 50px;
  }
`;

const TotalSummary: React.FC = () => {
  const t = useTranslate();
  const { values } = useFormikContext<ICitationListForm>();
  const amount = values.citations
    .map((cit) => cit.paymentDetails.total)
    .reduce((sum, value) => sum + value, 0);
  return (
    <TotalSummaryWrapper>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        marginRight={2}
      >
        <AppTypography size={1.2} weight={700}>
          {t("COMMON_LABELS_TOTAL")}:
        </AppTypography>
        <AppTypography>
          {values.citations.length}
          {`\u00A0`}
          {t("COMMON_LABELS_SELECTED")}
        </AppTypography>
      </Box>

      <AppDollar
        typoProps={{ size: 1.5, weight: 700, color: "secondary" }}
        amount={amount}
      />
    </TotalSummaryWrapper>
  );
};
export default TotalSummary;
