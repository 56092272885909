import React from "react";
import { useCarDataContext, useCitationContext } from "Contexts";
import CitationListWrapper from "./Components/CitationListWrapper";
import { Formik } from "formik";
import { ICitationListForm, initialCitationListForm } from "./schema";
import { FormSubmitButton } from "Layout/FormComponents";
import TotalSummary from "./Components/TotalSummary";
import styled from "styled-components";
import useBreakpoints from "Hooks/useBreakpoints";
import { AppPagination } from "Layout/Components";
import CitationHeaders from "./Components/CitationHeaders";
import LocationList from "./Components/LocationList";
import { useHistory } from "react-router";
import { routes } from "Routing/routes";
import CitationListFilters from "./Components/CitationFilters/CitationFilters";
import useCitationUpcharges from "./Hooks/useCitationUpcharges";

const Wrapper = styled.div`
  width: 100%;
  max-width: ${(_) => _.theme.breakpoints.values.xl}px;
`;
const SummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid ${(_) => _.theme.palette.neutral.main};
  margin-top: 20px;
  ${(_) => _.theme.breakpoints.up("sm")} {
    justify-content: flex-end;
  }
`;
const Pagination = styled(AppPagination)`
  display: flex;
  max-width: ${(_) => _.theme.breakpoints.values.xl}px;
  justify-content: center;
  ${(_) => _.theme.breakpoints.up("sm")} {
    justify-content: flex-end;
    margin: 0 auto;
  }
`;
const SubmitButton = styled(FormSubmitButton)`
  max-width: 120px;
`;

export const CitationList: React.FC = () => {
  const { locations, page, numberOfPages, getData, setSelectedLocation } =
    useCitationContext();
  const { carData } = useCarDataContext();
  const { isUpchargeRequired, UpchargeDialog } = useCitationUpcharges();
  const { up } = useBreakpoints();
  const { push } = useHistory();

  const handleSubmit = (v: ICitationListForm) => {
    if (v?.location && !isUpchargeRequired(v)) {
      setSelectedLocation({ ...v.location, citations: v.citations });
      push(routes.pay);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialCitationListForm}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleSubmit, values, resetForm }) => (
          <Wrapper>
            <CitationListFilters resetForm={resetForm} />
            <CitationListWrapper>
              <CitationHeaders />
              <form onSubmit={handleSubmit}>
                <LocationList locations={locations} />
                <SummaryWrapper>
                  <TotalSummary />
                  <SubmitButton
                    minWidth={up("sm") ? 120 : 160}
                    translation="COMMON_BUTTONS_PAY"
                    disabled={!(values.location && values.citations.length)}
                  />
                </SummaryWrapper>
              </form>
            </CitationListWrapper>
            <Pagination
              pages={numberOfPages}
              currentPage={page}
              callback={(p) => {
                if (p !== page) {
                  getData(p, "", carData);
                  resetForm();
                }
              }}
              topPadding={1}
            />
          </Wrapper>
        )}
      </Formik>
      <UpchargeDialog />
    </>
  );
};

export default CitationList;
