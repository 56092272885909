import React from "react";
import {
  CssBaseline,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { theme } from "Layout/theme";
import {
  CarDataProvider,
  LoaderProvider,
  DictionariesProvider,
  ErrorProvider,
} from "Contexts";
import AppRouter from "Routing/AppRouter";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import PopupProvider from "Contexts/PopupContext";
import TimerContextProvider from "Contexts/TimerContext";

const App: React.FC = () => {
  return (
    <Router>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <LoaderProvider>
                <PopupProvider>
                  <TimerContextProvider>
                    <ErrorProvider>
                      <DictionariesProvider>
                        <CarDataProvider>
                          <AppRouter />
                        </CarDataProvider>
                      </DictionariesProvider>
                    </ErrorProvider>
                  </TimerContextProvider>
                </PopupProvider>
              </LoaderProvider>
            </LocalizationProvider>
          </StyledThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Router>
  );
};

export default App;
