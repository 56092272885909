import { config } from "./config";
import axios from "axios";

export interface IResponseApi<T> {
  data: T;
  errors: any[];
}
export const apiClient = axios.create({
  baseURL: config.API_URL_PATH,
  timeout: 100000,
  headers: {
    Version: "1.0",
    "Content-Type": "application/json",
    secretKey: config.SECRET_KEY,
  },
});
