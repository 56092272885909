export interface ICarData {
  plate: string;
  makeId: number;
  stateId: string;
  isRental?: boolean;
  citationNumber?: string;
}

export interface ILocation {
  name: string;
  phoneNumber: string;
  citations: ICitation[];
  expirationDateUTC: Date;
  upchargeDetails: IUpchargeDetails | null;
}

export interface ICitation {
  id: string;
  citationType: CitationType;
  number: string;
  violation: string;
  violationType: ViolationTypeEnum;
  address: string;
  zoneName: string;
  lotName: string;
  citationDate: Date;
  paymentDetails: IPaymentDetails;
  appealStatus: AppealStatus | null;
  canOpenDispute: boolean;
}

export interface IPaymentDetails {
  amount: number;
  fee: number;
  discount: number;
  total: number;
}

export interface IUpchargeDetails {
  upchargeAmount: number;
  upchargeType: UpchargeType;
  criteria: {
    criteria: CriteriaType;
    value: number;
  };
}

export enum UpchargeType {
  HouseHold = 1,
  Plate = 2,
}

export enum CriteriaType {
  NumberOfUnpaidCitations = 1,
  AmountOfUnpaidCitations = 2,
}

export enum CitationType {
  CarCitation = 1,
  BootingCitation = 2,
}

export enum ViolationTypeEnum {
  Standard = 1,
  Booting = 2,
}

export enum CheckboxType {
  RegisteredOwner,
  HandleDispute,
}

export enum AppealStatus {
  Open = 1,
  Closed = 2,
}

export interface ICitationFilters {
  citationNumber: string;
  locationSearchPhrase: string;
  violationSearchPhrase: string;
  issueDateFrom: Date | null;
  issueDateTo: Date | null;
  amountFrom: number | null;
  amountTo: number | null;
}

export interface ILocationDetails {
  citations: ICitation[];
  exiprationDateUTC: Date;
  upcharge: IUpchargeDetails | null;
  locationName: string;
  locationPhoneNumber: string;
}

interface AppealReason {
  reason: string;
  createdDate: Date;
}
export interface IAppealDetails {
  appeal: AppealReason;
  answer: AppealReason | null;
  attachments: IAttachments[];
}

export interface IAttachments {
  id: string;
  fileName: string;
}
