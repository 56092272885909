import { Box, BoxProps } from "@material-ui/core";
import { TranslationKeys } from "Translation";
import { AppTypography, AppTypographyProps } from ".";

export interface AppDollarProps {
  amount: number;
  label?: TranslationKeys;
  discount?: boolean;
  textAlign?: "left" | "center" | "right";
  containerProps?: BoxProps;
  typoProps?: AppTypographyProps;
}
const AppDollar: React.FC<AppDollarProps> = ({
  amount,
  label,
  discount,
  containerProps,
  typoProps,
  textAlign = "left",
}) => {
  const value = discount
    ? `($\u00A0${(amount / 100).toFixed(2)})`
    : `$\u00A0${(amount / 100).toFixed(2)}`;
  return (
    <Box {...containerProps} textAlign={textAlign}>
      <AppTypography {...typoProps}>{value}</AppTypography>
      <AppTypography {...typoProps} color="textSecondary" translation={label} />
    </Box>
  );
};

export default AppDollar;
