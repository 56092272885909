import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const Error: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="124" height="124" viewBox="0 0 124 124" fill="none">
    <circle cx="62" cy="62" r="62" fill="#F82222" fillOpacity="0.1" />
    <circle cx="62" cy="62" r="47" fill="#F82222" fillOpacity="0.15" />
    <circle
      cx="62"
      cy="62"
      r="29"
      fill="#FFE5E5"
      stroke="#FF3D3D"
      strokeWidth="2"
    />
    <path
      d="M53 53L71 71"
      stroke="#FF3D3D"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M71 53L53 71"
      stroke="#FF3D3D"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </SvgIcon>
);
Error.defaultProps = {
  color: "disabled",
};
export default Error;
