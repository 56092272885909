import AppealForm from "Pages/CitationList/Components/Appeals/AppealForm";
import React, { useCallback, useContext } from "react";

export interface AppealContextType {
  showAppealPopup: boolean;
  setShowAppealPopup: React.Dispatch<React.SetStateAction<boolean>>;
  citationId: string;
  setCitationId: React.Dispatch<React.SetStateAction<string>>;
  showAppealFormPopup: (id: string) => void;
}

export const AppealContext = React.createContext<AppealContextType>(
  {} as AppealContextType
);

export const useAppealContext = () => useContext(AppealContext);

export const AppealProvider: React.FC = (props) => {
  const [showAppealPopup, setShowAppealPopup] = React.useState(false);
  const [citationId, setCitationId] = React.useState("");

  const showAppealFormPopup = useCallback((id: string) => {
    setCitationId(id);
    setShowAppealPopup(true);
  }, []);

  return (
    <AppealContext.Provider
      value={{
        showAppealPopup,
        setShowAppealPopup,
        citationId,
        setCitationId,
        showAppealFormPopup,
      }}
    >
      {props.children}
      <AppealForm />
    </AppealContext.Provider>
  );
};

export default AppealProvider;
