import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandedArrow } from "Assets/Icons";
import { useCitationContext } from "Contexts";
import { useTranslate } from "Hooks";
import { AppTypography } from "Layout/Components";
import ButtonWrapper from "Layout/Components/ButtonWrapper";
import styled from "styled-components";

export interface FiletrsWrapperProps {}

const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  padding: 0 12px;
  padding-bottom: 10px;
  width: 100%;
  &:before {
    display: none;
  }
  ${(_) => _.theme.breakpoints.up("xl")} {
    padding: 8px 0;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 0 !important;
  padding: 0;
  ${(_) => _.theme.breakpoints.up("xl")} {
    padding: 8px;
  }
`;

const FiltersWrapper: React.FC<FiletrsWrapperProps> = (props) => {
  const t = useTranslate();
  const { filters } = useCitationContext();
  return (
    <StyledAccordion square>
      <ButtonWrapper justify="flex-end">
        <StyledAccordionSummary expandIcon={<ExpandedArrow expanded={false} />}>
          <AppTypography
            size={1.1}
            weight="bold"
            color="primary"
            paddingRight={1}
          >
            {t(
              filters ? "COMMON_LABELS_FILTERSACTIVE" : "COMMON_LABELS_FILTERS"
            )}
          </AppTypography>
        </StyledAccordionSummary>
      </ButtonWrapper>
      <AccordionDetails style={{ justifyContent: "center" }}>
        {props.children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default FiltersWrapper;
