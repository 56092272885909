import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const Success: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="124" height="124" viewBox="0 0 124 124" fill="none">
    <circle cx="62" cy="62" r="62" fill="#00C3FF" fillOpacity="0.1" />
    <circle cx="62" cy="62" r="47" fill="#00C3FF" fillOpacity="0.15" />
    <circle
      cx="62"
      cy="62"
      r="29"
      fill="#E5F9FF"
      stroke="#00C3FF"
      strokeWidth="2"
    />
    <path
      d="M50 62.2976L58.5775 71L74 54"
      fill="#E5F9FF"
      stroke="#00C3FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
Success.defaultProps = {
  color: "disabled",
};
export default Success;
