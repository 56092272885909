import React from "react";
import styled from "styled-components";
import { AppTopBar } from "./Components";
const Layout = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #e5e5e5;
  min-height: 100vh;
  max-height: 100vh;
  background-size: cover;
  &::-webkit-scrollbar {
    width: 0em;
    ${(_) => _.theme.breakpoints.up("md")} {
      width: 0.4em;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: #c4c4c4;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
  }
  overflow: overlay;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-width: 100%;
`;

export const PageLayout: React.FC = ({ children }) => {
  return (
    <Layout>
      <Content>
        <AppTopBar />
        {children}
      </Content>
    </Layout>
  );
};

export default PageLayout;
