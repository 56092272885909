import { Checkbox, FormControlLabel } from "@material-ui/core";
import { AppealStatus, CitationType, ICitation } from "API/Interfaces";
import { usePopupContext } from "Contexts/PopupContext";
import { useTimerContext } from "Contexts/TimerContext";
import { useFormikContext } from "formik";
import { AppTypography } from "Layout/Components";
import React, { useEffect } from "react";
import styled from "styled-components";
import { citationListFields, ICitationListForm } from "../schema";

export interface CitationCheckboxProps {
  citation: ICitation;
  disabled?: boolean;
  phone: string;
  isBootingInLocation: boolean;
}
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  ${(_) => _.theme.breakpoints.up("md")} {
    min-width: 200px;
  }
`;
const StyledControlLabel = styled(FormControlLabel)`
  margin-right: 0;
`;
const StyledCheckbox = styled(Checkbox)`
  color: ${(_) => _.theme.palette.primary.main};
  &checked {
    color: ${(_) => _.theme.palette.primary.main};
  }
`;

const CitationNumber = styled(AppTypography)`
  cursor: pointer;
`;

const CitationCheckbox: React.FC<CitationCheckboxProps> = ({
  citation,
  disabled,
  phone,
  isBootingInLocation,
}) => {
  const { setPopup, showPopup } = usePopupContext();
  const { timerExpire } = useTimerContext();
  const isBooting = citation.citationType === CitationType.BootingCitation;
  const isDisputeOpen = citation.appealStatus === AppealStatus.Open;
  const {
    values: { citations },
    setFieldValue,
    resetForm,
  } = useFormikContext<ICitationListForm>();

  const handleChangeCitation = (checked: boolean) => {
    const newCitations = checked
      ? [...citations, citation]
      : citations.filter((c) => c.id !== citation.id);
    setFieldValue(citationListFields.citations, newCitations);
  };
  useEffect(() => {
    timerExpire === 0 && resetForm();
  }, [resetForm, timerExpire]);
  return (
    <CheckboxWrapper>
      <StyledControlLabel
        control={
          <StyledCheckbox
            color="default"
            disabled={
              disabled || isBooting || isDisputeOpen || isBootingInLocation
            }
            checked={
              isBooting || citations.includes(citation) || isBootingInLocation
            }
          />
        }
        label=""
        onChange={(_, checked) => handleChangeCitation(checked)}
      />
      <CitationNumber
        onClick={() => {
          setPopup({
            variant: "citation",
            citationDetails: citation,
            phone: phone,
          });
          showPopup();
        }}
        color="primary"
      >
        {citation.number}
      </CitationNumber>
    </CheckboxWrapper>
  );
};

export default CitationCheckbox;
