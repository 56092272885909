import EmailServiceApi from "API/Services/EmailServiceApi";
import { CitationErrorIcon, CitationInfoIcon } from "Assets/Icons";
import { useLoaderContext } from "Contexts";
import { useFetchStatus } from "Hooks";
import { AppPaper, AppTypography } from "Layout/Components";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import styled from "styled-components";

const StyledPaper = styled(AppPaper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1100px;
`;

const UnsubscribeEmail: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const { setLoading } = useLoaderContext();
  const [{ success, error }, setFetchStatus] = useFetchStatus();

  useEffect(() => {
    setLoading(true);
    EmailServiceApi.unassign(token)
      .then(() => setFetchStatus.success())
      .catch(() => setFetchStatus.error())
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return success || error ? (
    <StyledPaper>
      {success ? <CitationInfoIcon /> : <CitationErrorIcon />}
      <AppTypography
        paddingTop={2}
        weight={700}
        align="center"
        variant="h5"
        translation={
          success ? "COMMON_DIALOGS_SUCCESS" : "COMMON_DIALOGS_ERROR"
        }
      />
      <AppTypography
        paddingTop={1}
        align="center"
        translation={
          success
            ? "COMMON_PAGES_YOUREMAILHASBEENUNSUBSCRIBED"
            : "COMMON_PAGES_SOMETHINGWENTWRONGPLEASETRYLATER"
        }
      />
    </StyledPaper>
  ) : null;
};

export default UnsubscribeEmail;
