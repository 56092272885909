import { useFormikContext } from "formik";
import { useTranslate } from "Hooks";
import { AppTextField, AppTextFieldProps } from "Layout/Components";
import React from "react";
import { TranslationKeys } from "Translation";

interface Props {
  fieldName: string;
  labelTranslation?: TranslationKeys;
  placeholderTranslation?: TranslationKeys;
  inputRequired?: boolean;
  endAdornmentIcon?: any;
  untouchedError?: boolean;
  cleanOnUnmount?: boolean;
  maxWidth?: number;
}
export type FormTextFieldProps = Props & AppTextFieldProps;

export const FormTextField: React.FC<FormTextFieldProps> = ({
  fieldName,
  labelTranslation,
  inputRequired,
  endAdornmentIcon,
  InputProps,
  untouchedError,
  cleanOnUnmount,
  placeholderTranslation,
  ...props
}) => {
  const t = useTranslate();
  const {
    errors,
    values,
    handleChange,
    touched,
    handleBlur,
    setFieldValue,
    setFieldError,
  } = useFormikContext<any>();

  React.useEffect(
    () => () => {
      if (cleanOnUnmount) {
        setFieldValue(fieldName, "");
        setFieldError(fieldName, undefined);
      }
    },
    // eslint-disable-next-line
    []
  );

  const isTouched = () => (untouchedError ? true : touched[fieldName]);
  return (
    <>
      <AppTextField
        fullWidth
        name={fieldName}
        label={t(labelTranslation)}
        value={values[fieldName] || ""}
        onBlur={handleBlur}
        onChange={handleChange}
        InputLabelProps={{ required: inputRequired, ...props.InputLabelProps }}
        error={isTouched() && Boolean(errors[fieldName])}
        helperText={isTouched() && errors[fieldName]}
        InputProps={{
          endAdornment: endAdornmentIcon,
          ...InputProps,
        }}
        placeholder={
          placeholderTranslation ? t(placeholderTranslation) : props.placeholder
        }
        {...props}
      />
    </>
  );
};

export default FormTextField;
