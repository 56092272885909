import { ICitation } from "API/Interfaces";
import useBreakpoints from "Hooks/useBreakpoints";
import AppDollar from "Layout/Components/AppDollar";
import React from "react";
import styled from "styled-components";
import AppealIcon from "./Appeals/AppealIcon";

export interface CitationSummaryProps {
  citation: ICitation;
}

const CitationSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  ${(_) => _.theme.breakpoints.up("md")} {
    margin-top: 0;
    min-width: 350px;
    max-width: 350px;
    justify-content: flex-end;
    > div {
      text-align: right;
      width: 20%;
    }
  }
  ${(_) => _.theme.breakpoints.up("lg")} {
    min-width: 450px;
    max-width: 450px;
  }
  ${(_) => _.theme.breakpoints.up("xl")} {
    min-width: 540px;
  }
`;

const CitationSummary: React.FC<CitationSummaryProps> = ({ citation }) => {
  const { up } = useBreakpoints();
  const amount = citation.paymentDetails;
  return (
    <CitationSummaryWrapper>
      <>
        <AppDollar
          amount={amount.amount}
          label={up("md") ? "COMMON_LABELS_AMOUNT" : undefined}
          textAlign="center"
        />
        <AppDollar
          amount={amount.fee}
          label={up("md") ? "COMMON_LABELS_FEES" : undefined}
          textAlign="center"
        />
        <AppDollar
          discount
          amount={amount.discount}
          label={up("md") ? "COMMON_LABELS_DISCOUNTS" : undefined}
          textAlign="center"
        />
        <AppDollar
          amount={amount.total}
          label={up("md") ? "COMMON_LABELS_TOTAL" : undefined}
          textAlign="center"
          typoProps={{ weight: 700 }}
        />
        {!up("md") && (
          <div>
            <AppealIcon citation={citation} />
          </div>
        )}
      </>
    </CitationSummaryWrapper>
  );
};

export default CitationSummary;
