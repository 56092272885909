import { Formik } from "formik";
import { FormTextField, FormSubmitButton } from "Layout/FormComponents";
import {
  initialData,
  citationIdSearchFieldNames,
  ICitationIdSearchForm,
  validationSchema,
} from "./FormFields";
import styled from "styled-components";
import { useCarDataContext } from "Contexts/CarDataContext";
import * as yup from "yup";

interface CitationIdFormProps {}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin: 10px 0;
  }
`;

export const CitationIdForm: React.FC<CitationIdFormProps> = () => {
  const { getCarData, status } = useCarDataContext();

  const handleSubmit = (v: ICitationIdSearchForm) => {
    getCarData(v.citationId);
  };

  return (
    <Formik
      onSubmit={(v) => handleSubmit(v)}
      initialValues={initialData}
      validationSchema={yup.object(validationSchema)}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormTextField
            fieldName={citationIdSearchFieldNames.citationId}
            translationLabel="COMMON_FORMS_CITATIONID"
          />
          <FormSubmitButton loading={status.loading} />
        </Form>
      )}
    </Formik>
  );
};

export default CitationIdForm;
