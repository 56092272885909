import React, { useState, useEffect } from "react";
import { AppTypography } from "Layout/Components";
import { CitationInfoIcon, CitationErrorIcon } from "Assets/Icons";
import styled from "styled-components";
import { useCarDataContext } from "Contexts/CarDataContext";
import { TranslationKeys } from "Translation";
import { ErrorCodes } from "API/Interfaces/Errors";

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin: 10px 0;
  }
`;

export const Title: React.FC = () => {
  const { status, errorCode } = useCarDataContext();
  const [message, setMessage] = useState<TranslationKeys>(
    "COMMON_PAGES_PAYFORYOURCITATION"
  );

  useEffect(() => {
    if (!errorCode) setMessage("COMMON_PAGES_PAYFORYOURCITATION");
    else if (errorCode === ErrorCodes.InvalidRequest)
      setMessage("COMMON_PAGES_PAYMENTCONFIGURATIONPROBLEM");
    else setMessage("COMMON_PAGES_CITATIONCANNOTBEFOUND");
  }, [errorCode]);

  return (
    <TitleWrapper>
      {!status.error ? <CitationInfoIcon /> : <CitationErrorIcon />}
      <AppTypography
        variant="h2"
        weight="bold"
        color={!status.error ? "textPrimary" : "error"}
        translation={
          !status.error
            ? "COMMON_PAGES_FINDYOURCITATION"
            : "COMMON_PAGES_SOMETHINGWENTWRONG"
        }
      />
      <AppTypography variant="subtitle2" align="center" translation={message} />
    </TitleWrapper>
  );
};
