import React from "react";
import styled from "styled-components";
import { CallLink, SummaryBox } from ".";
import { ICitation } from "API/Interfaces";
import CitationsList from "./CitationsList";
import { mergeWith, add } from "lodash";

interface MultipleCitationDetailsProps {
  phoneNumber: string;
  citations: ICitation[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;
  width: 100%;
  ${(_) => _.theme.breakpoints.up("md")} {
    order: 2;
  }
`;

export const MultipleCitationDetails: React.FC<MultipleCitationDetailsProps> =
  ({ phoneNumber, citations }) => {
    const summary = citations
      .map((e) => e.paymentDetails)
      .reduce((prev, next) => mergeWith({}, prev, next, add), {
        amount: 0,
        discount: 0,
        fee: 0,
        total: 0,
      });
    return (
      <Container>
        <CitationsList citations={citations} phone={phoneNumber} />
        <SummaryBox summary={summary} />
        <CallLink phone={phoneNumber} />
      </Container>
    );
  };

export default MultipleCitationDetails;
