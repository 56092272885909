import { paymentDetailFieldNames as f } from ".";
import { Grid } from "@material-ui/core";
import {
  FormTextField,
  FormCheckbox,
  FormHelperText,
  DictionarySelect,
} from "Layout/FormComponents";
import styled from "styled-components";
import { AppTypography } from "Layout/Components";
import { useDictionariesContext } from "Contexts";
import FormAddressAutocomplete from "./FormAddressAutocomplete";
import { CheckboxType } from "API/Interfaces";

interface PaymentFormProps {
  checkbox: CheckboxType;
}

const NoPaddingGrid = styled(Grid)`
  &.MuiGrid-item {
    padding: 0 8px;
  }
`;

const NoBottomPaddingGrid = styled(Grid)`
  &.MuiGrid-item {
    padding-bottom: 0;
  }
`;

export const PaymentForm: React.FC<PaymentFormProps> = ({ checkbox }) => {
  const { dictionaries } = useDictionariesContext();
  const { countryCodes, phoneCountryCodes } = dictionaries;

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppTypography
          variant="h2"
          weight="bold"
          translation="COMMON_PAGES_CONTACTDATA"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormTextField
          fieldName={f.firstName}
          translationLabel="COMMON_FORMS_FIRSTNAME"
          inputRequired
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormTextField
          fieldName={f.lastName}
          translationLabel="COMMON_FORMS_LASTNAME"
          inputRequired
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <FormAddressAutocomplete />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormTextField
          fieldName={f.street1}
          translationLabel="COMMON_FORMS_STREET1"
          inputRequired
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormTextField
          fieldName={f.street2}
          translationLabel="COMMON_FORMS_STREET2"
        />
      </Grid>
      <Grid item md={4} xs={4}>
        <FormTextField
          fieldName={f.zipCode}
          translationLabel="COMMON_FORMS_ZIPCODE"
          inputRequired
        />
      </Grid>
      <Grid item md={8} xs={8}>
        <FormTextField
          fieldName={f.county}
          translationLabel="COMMON_FORMS_COUNTY"
          inputRequired
        />
      </Grid>
      <Grid item md={8} xs={8}>
        <FormTextField
          fieldName={f.city}
          translationLabel="COMMON_FORMS_CITY"
          inputRequired
        />
      </Grid>
      <Grid item md={4} xs={4}>
        <FormTextField
          fieldName={f.state}
          translationLabel="COMMON_FORMS_STATE"
          inputRequired
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <DictionarySelect
          fieldName={f.country}
          dictionary={countryCodes}
          translationLabel="COMMON_FORMS_COUNTRY"
          inputRequired
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <FormTextField
          fieldName={f.email}
          translationLabel="COMMON_FORMS_EMAIL"
          inputRequired
        />
        <FormHelperText translationLabel="COMMON_PAGES_PAYMENTCONFIRMATIONWILLBESENT" />
      </Grid>
      <NoBottomPaddingGrid item md={3} xs={4}>
        <DictionarySelect
          fieldName={f.phoneCountryCodeId}
          dictionary={phoneCountryCodes}
          translationLabel="COMMON_FORMS_PREFIX"
          inputRequired
        />
      </NoBottomPaddingGrid>
      <NoBottomPaddingGrid item md={9} xs={8}>
        <FormTextField
          fieldName={f.phoneNumber}
          translationLabel="COMMON_FORMS_PHONE"
          inputRequired
        />
      </NoBottomPaddingGrid>
      <NoPaddingGrid item md={12} xs={12}>
        <FormHelperText translationLabel="COMMON_PAGES_THEPROVIDEDPHONENUMBERWILLIDENTIFY" />
      </NoPaddingGrid>
      <Grid item md={12} xs={12}>
        <FormCheckbox
          fieldName={f.isRegisteredOwner}
          labelTranslation={
            checkbox === CheckboxType.RegisteredOwner
              ? "COMMON_FORMS_IAGREETHATMYCONTACT"
              : "COMMON_FORMS_IAMREGISTEREDOWNER"
          }
        />
      </Grid>
    </Grid>
  );
};

export default PaymentForm;
