import { Formik } from "formik";
import {
  FormTextField,
  FormSubmitButton,
  DictionarySelect,
} from "Layout/FormComponents";
import {
  initialData,
  validationSchema,
  plateNumberSearchFieldNames,
  IPlateNumberForm,
} from "./FormFields";
import styled from "styled-components";
import { useCarDataContext } from "Contexts/CarDataContext";
import { useDictionariesContext } from "Contexts/DictionaryContext";
import * as yup from "yup";
import { Box } from "@material-ui/core";

interface PlateNumberFormProps {}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin: 10px 0;
  }
`;

export const PlateNumberForm: React.FC<PlateNumberFormProps> = () => {
  const { status, checkCarData } = useCarDataContext();
  const { dictionaries } = useDictionariesContext();

  const handleSubmit = (v: IPlateNumberForm) => {
    checkCarData({
      plate: v.plate.replace(/ /g, ""),
      makeId: v.makeId as number,
      stateId: v.stateId,
    });
  };

  return (
    <Formik
      onSubmit={(v) => handleSubmit(v)}
      initialValues={initialData}
      validationSchema={yup.object(validationSchema)}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormTextField
            fieldName={plateNumberSearchFieldNames.plate}
            translationLabel="COMMON_FORMS_PLATENUMBER"
          />
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box width="48%">
              <DictionarySelect
                dictionary={dictionaries.carMakes}
                fieldName={plateNumberSearchFieldNames.makeId}
                translationLabel="COMMON_FORMS_MAKE"
              />
            </Box>
            <Box width="48%">
              <DictionarySelect
                dictionary={dictionaries.states}
                fieldName={plateNumberSearchFieldNames.stateId}
                translationLabel="COMMON_FORMS_STATE"
              />
            </Box>
          </Box>

          <FormSubmitButton loading={status.loading} />
        </Form>
      )}
    </Formik>
  );
};

export default PlateNumberForm;
