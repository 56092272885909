import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { useTheme } from "@material-ui/styles";
import useWindowSize from "./useWindowSize";

const useBreakpoints = () => {
  const theme = useTheme<any>();
  const windowSize = useWindowSize();
  const up = (size: Breakpoint) =>
    windowSize.width < theme.breakpoints.values[size];
  const down = (size: Breakpoint) =>
    windowSize.width > theme.breakpoints.values[size];
  return { up, down } as const;
};

export default useBreakpoints;
