import React from "react";

export const CitationInfoIcon = () => {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="62" cy="62" r="62" fill="#00C3FF" fillOpacity="0.1" />
      <circle cx="62" cy="62" r="47" fill="#00C3FF" fillOpacity="0.15" />
      <rect
        x="40"
        y="28"
        width="44"
        height="58"
        rx="6"
        fill="#E5F9FF"
        stroke="#00C3FF"
        strokeWidth="2"
      />
      <path
        d="M55 86H69V98.0568C69 98.8702 68.0806 99.3433 67.4188 98.8705L62.5812 95.4152C62.2335 95.1668 61.7665 95.1668 61.4188 95.4152L56.5812 98.8705C55.9194 99.3433 55 98.8702 55 98.0568V86Z"
        fill="#E5F9FF"
        stroke="#00C3FF"
        strokeWidth="2"
      />
      <mask id="path-5-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.9725 36H62.0275C62.2762 37.125 64.1837 38 66.5 38C68.8163 38 70.7238 37.125 70.9725 36H71V38V47.5643C71 51.5809 68.5968 55.2077 64.898 56.7733L62 58L59.102 56.7733C55.4032 55.2077 53 51.5809 53 47.5643V38V36H53.0275C53.2762 37.125 55.1837 38 57.5 38C59.8163 38 61.7238 37.125 61.9725 36Z"
        />
      </mask>
      <path
        d="M62.0275 36L63.9803 35.5682L63.6336 34H62.0275V36ZM61.9725 36V34H60.3664L60.0197 35.5682L61.9725 36ZM70.9725 36V34H69.3664L69.0197 35.5682L70.9725 36ZM71 36H73V34H71V36ZM64.898 56.7733L65.6776 58.6151L64.898 56.7733ZM62 58L61.2204 59.8418L62 60.1718L62.7796 59.8418L62 58ZM59.102 56.7733L58.3224 58.6151H58.3224L59.102 56.7733ZM53 36V34H51V36H53ZM53.0275 36L54.9803 35.5682L54.6336 34H53.0275V36ZM62.0275 34H61.9725V38H62.0275V34ZM66.5 36C65.5734 36 64.8059 35.8219 64.3101 35.5985C63.7141 35.3299 63.8992 35.2012 63.9803 35.5682L60.0746 36.4318C60.4045 37.9238 61.6677 38.7951 62.6667 39.2453C63.7659 39.7406 65.1104 40 66.5 40V36ZM69.0197 35.5682C69.1008 35.2012 69.2859 35.3299 68.6899 35.5985C68.1941 35.8219 67.4266 36 66.5 36V40C67.8896 40 69.2341 39.7406 70.3333 39.2453C71.3323 38.7951 72.5955 37.9238 72.9254 36.4318L69.0197 35.5682ZM71 34H70.9725V38H71V34ZM73 38V36H69V38H73ZM69 38V47.5643H73V38H69ZM69 47.5643C69 50.7776 67.0775 53.679 64.1184 54.9315L65.6776 58.6151C70.1162 56.7364 73 52.3842 73 47.5643H69ZM64.1184 54.9315L61.2204 56.1582L62.7796 59.8418L65.6776 58.6151L64.1184 54.9315ZM62.7796 56.1582L59.8816 54.9315L58.3224 58.6151L61.2204 59.8418L62.7796 56.1582ZM59.8816 54.9315C56.9225 53.679 55 50.7776 55 47.5643H51C51 52.3842 53.8838 56.7364 58.3224 58.6151L59.8816 54.9315ZM55 47.5643V38H51V47.5643H55ZM51 36V38H55V36H51ZM53.0275 34H53V38H53.0275V34ZM57.5 36C56.5734 36 55.8059 35.8219 55.3101 35.5985C54.7141 35.3299 54.8992 35.2012 54.9803 35.5682L51.0746 36.4318C51.4045 37.9238 52.6677 38.7951 53.6667 39.2453C54.7659 39.7406 56.1104 40 57.5 40V36ZM60.0197 35.5682C60.1008 35.2012 60.2859 35.3299 59.6899 35.5985C59.1941 35.8219 58.4266 36 57.5 36V40C58.8896 40 60.2341 39.7406 61.3333 39.2453C62.3323 38.7951 63.5955 37.9238 63.9254 36.4318L60.0197 35.5682Z"
        fill="#00C3FF"
        mask="url(#path-5-inside-1)"
      />
      <path
        d="M48 64H62"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M48 70H76"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M48 76H76"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const ExpandedLocationArrow = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="rotate(-180 10 10)" id="svg_3">
        <circle
          cx="10"
          cy="10"
          r="9"
          transform="rotate(180 10 10)"
          stroke="#00C3FF"
          strokeWidth="1.5"
        />
        <path
          d="M14 11L10 7L6 11"
          stroke="#00C3FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const ExpandedArrow = (props: any) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="12" stroke="#00C3FF" strokeWidth="1.5" />
      <path
        d="M7.3999 11.4L12.9999 17L18.5999 11.4"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CitationErrorIcon = () => {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="62" cy="62" r="62" fill="#F82222" fillOpacity="0.1" />
      <circle cx="62" cy="62" r="47" fill="#F82222" fillOpacity="0.15" />
      <rect
        x="40"
        y="28"
        width="44"
        height="58"
        rx="6"
        fill="#FFE5E5"
        stroke="#FF3D3D"
        strokeWidth="2"
      />
      <path
        d="M55 86H69V98.0568C69 98.8702 68.0806 99.3433 67.4188 98.8705L62.5812 95.4152C62.2335 95.1668 61.7665 95.1668 61.4188 95.4152L56.5812 98.8705C55.9194 99.3433 55 98.8702 55 98.0568V86Z"
        fill="#FFE5E5"
        stroke="#FF3D3D"
        strokeWidth="2"
      />
      <mask id="path-5-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.9725 36H62.0275C62.2762 37.125 64.1837 38 66.5 38C68.8163 38 70.7238 37.125 70.9725 36H71V38V47.5643C71 51.5809 68.5968 55.2077 64.898 56.7733L62 58L59.102 56.7733C55.4032 55.2077 53 51.5809 53 47.5643V38V36H53.0275C53.2762 37.125 55.1837 38 57.5 38C59.8163 38 61.7238 37.125 61.9725 36Z"
        />
      </mask>
      <path
        d="M62.0275 36L63.9803 35.5682L63.6336 34H62.0275V36ZM61.9725 36V34H60.3664L60.0197 35.5682L61.9725 36ZM70.9725 36V34H69.3664L69.0197 35.5682L70.9725 36ZM71 36H73V34H71V36ZM64.898 56.7733L65.6776 58.6151L64.898 56.7733ZM62 58L61.2204 59.8418L62 60.1718L62.7796 59.8418L62 58ZM59.102 56.7733L58.3224 58.6151H58.3224L59.102 56.7733ZM53 36V34H51V36H53ZM53.0275 36L54.9803 35.5682L54.6336 34H53.0275V36ZM62.0275 34H61.9725V38H62.0275V34ZM66.5 36C65.5734 36 64.8059 35.8219 64.3101 35.5985C63.7141 35.3299 63.8992 35.2012 63.9803 35.5682L60.0746 36.4318C60.4045 37.9238 61.6677 38.7951 62.6667 39.2453C63.7659 39.7406 65.1104 40 66.5 40V36ZM69.0197 35.5682C69.1008 35.2012 69.2859 35.3299 68.6899 35.5985C68.1941 35.8219 67.4266 36 66.5 36V40C67.8896 40 69.2341 39.7406 70.3333 39.2453C71.3323 38.7951 72.5955 37.9238 72.9254 36.4318L69.0197 35.5682ZM71 34H70.9725V38H71V34ZM73 38V36H69V38H73ZM69 38V47.5643H73V38H69ZM69 47.5643C69 50.7776 67.0775 53.679 64.1184 54.9315L65.6776 58.6151C70.1162 56.7364 73 52.3842 73 47.5643H69ZM64.1184 54.9315L61.2204 56.1582L62.7796 59.8418L65.6776 58.6151L64.1184 54.9315ZM62.7796 56.1582L59.8816 54.9315L58.3224 58.6151L61.2204 59.8418L62.7796 56.1582ZM59.8816 54.9315C56.9225 53.679 55 50.7776 55 47.5643H51C51 52.3842 53.8838 56.7364 58.3224 58.6151L59.8816 54.9315ZM55 47.5643V38H51V47.5643H55ZM51 36V38H55V36H51ZM53.0275 34H53V38H53.0275V34ZM57.5 36C56.5734 36 55.8059 35.8219 55.3101 35.5985C54.7141 35.3299 54.8992 35.2012 54.9803 35.5682L51.0746 36.4318C51.4045 37.9238 52.6677 38.7951 53.6667 39.2453C54.7659 39.7406 56.1104 40 57.5 40V36ZM60.0197 35.5682C60.1008 35.2012 60.2859 35.3299 59.6899 35.5985C59.1941 35.8219 58.4266 36 57.5 36V40C58.8896 40 60.2341 39.7406 61.3333 39.2453C62.3323 38.7951 63.5955 37.9238 63.9254 36.4318L60.0197 35.5682Z"
        fill="#FF3D3D"
        mask="url(#path-5-inside-1)"
      />
      <path
        d="M48 64H62"
        stroke="#FF3D3D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M48 70H76"
        stroke="#FF3D3D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M48 76H76"
        stroke="#FF3D3D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const LocationMark = () => {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6818 2.86215C12.8656 -0.95405 6.67835 -0.95405 2.86215 2.86215C-0.95405 6.67835 -0.95405 12.8656 2.86215 16.6818L8.11363 21.9333C9.02952 22.8492 10.5145 22.8492 11.4304 21.9333L16.6818 16.6818C20.498 12.8656 20.498 6.67835 16.6818 2.86215ZM9.77159 13.6799C11.9304 13.6799 13.6804 11.9299 13.6804 9.7711C13.6804 7.61233 11.9304 5.8623 9.77159 5.8623C7.61282 5.8623 5.86279 7.61233 5.86279 9.7711C5.86279 11.9299 7.61282 13.6799 9.77159 13.6799Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export const ZoneMark = () => {
  return (
    <svg
      width="22"
      height="27"
      viewBox="0 0 22 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7991 3.62878C14.0441 -0.12626 7.95597 -0.12626 4.20092 3.62878C0.445883 7.38382 0.445883 13.4719 4.20092 17.227L9.36824 22.3943C10.2695 23.2955 11.7306 23.2955 12.6318 22.3943L17.7991 17.227C21.5542 13.4719 21.5542 7.38382 17.7991 3.62878ZM11 15.8125C14.1559 15.8125 16.7143 13.2541 16.7143 10.0982C16.7143 6.9423 14.1559 4.38393 11 4.38393C7.8441 4.38393 5.28573 6.9423 5.28573 10.0982C5.28573 13.2541 7.8441 15.8125 11 15.8125Z"
        fill="#C4C4C4"
      />
      <path
        d="M3.14286 20C1.80081 20.6309 1 21.4274 1 22.2932C1 24.3404 5.47715 26 11 26C16.5228 26 21 24.3404 21 22.2932C21 21.4274 20.1992 20.6309 18.8571 20"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9 8H13L9 12H13"
        stroke="#C4C4C4"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ParkingMeter = () => {
  return (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="16" rx="2" fill="#C4C4C4" />
      <rect x="3" y="3" width="8" height="10" rx="1" fill="white" />
      <rect x="6" y="14" width="2" height="10" fill="#C4C4C4" />
      <rect x="3" y="22" width="8" height="2" rx="1" fill="#C4C4C4" />
    </svg>
  );
};

export const CalendarIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6C0 3.79086 1.79086 2 4 2H18C20.2091 2 22 3.79086 22 6H0Z"
        fill="#C4C4C4"
      />
      <path
        d="M22 18C22 20.2091 20.2091 22 18 22L4 22C1.79086 22 -1.75079e-06 20.2091 -1.55766e-06 18L-6.8343e-07 8L22 8L22 18Z"
        fill="#C4C4C4"
      />
      <rect x="5" width="2" height="4" rx="1" fill="#C4C4C4" />
      <rect x="15" width="2" height="4" rx="1" fill="#C4C4C4" />
    </svg>
  );
};

export const CallIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M11.7857 17.2857C7.88022 17.2857 4.71423 14.1197 4.71423 10.2142"
          stroke="currentColor"
          strokeWidth="2.35714"
        />
        <path
          d="M10.9999 7.07131C13.1696 7.07131 14.9285 8.83019 14.9285 10.9999"
          stroke="currentColor"
          strokeWidth="1.02143"
          strokeLinecap="round"
        />
        <path
          d="M11 3.92845C14.9054 3.92845 18.0714 7.09444 18.0714 10.9999"
          stroke="currentColor"
          strokeWidth="1.02143"
          strokeLinecap="round"
        />
        <path
          d="M4.71423 6.28559V10.704C4.71423 10.7846 4.82105 10.813 4.86102 10.743L7.18535 6.67542C7.48467 6.15161 7.10645 5.49988 6.50316 5.49988H5.49995C5.06601 5.49988 4.71423 5.85165 4.71423 6.28559Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2.35714"
        />
        <path
          d="M15.7143 17.2856L11.2959 17.2856C11.2153 17.2856 11.1869 17.1788 11.2569 17.1389L15.3245 14.8145C15.8483 14.5152 16.5 14.8934 16.5 15.4967L16.5 16.4999C16.5 16.9339 16.1482 17.2856 15.7143 17.2856Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2.35714"
        />
      </g>
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12L15 15"
        stroke="#00C3FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="7" cy="7" r="6" stroke="#00C3FF" strokeWidth="2" />
    </svg>
  );
};

export const TimerIcon = () => {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 6.5L18 5"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="13" r="9" stroke="#00C3FF" strokeWidth="1.5" />
      <path
        d="M6 1H14"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8V13L13 16"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const InfoIcon = React.forwardRef<SVGSVGElement>((props, ref) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <circle cx="6" cy="6.5" r="6" fill="#00C3FF" />
      <path
        d="M7.5 4C7.5 4.82843 6.82843 5.5 6 5.5C5.17157 5.5 4.5 4.82843 4.5 4C4.5 3.17157 5.17157 2.5 6 2.5C6.82843 2.5 7.5 3.17157 7.5 4Z"
        fill="white"
      />
      <path d="M6 6.5V10.5" stroke="white" strokeWidth="2" />
    </svg>
  );
});

export const AppealBlueIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#00C3FF"
        fillRule="evenodd"
        d="M19 5a2 2 0 00-2-2H3a2 2 0 00-2 2v8a2 2 0 002 2h3.172a2 2 0 011.414.586l1.707 1.707a1 1 0 001.414 0l1.707-1.707A2 2 0 0113.828 15H17a2 2 0 002-2V5z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
        d="M10 6v3.5"
      ></path>
      <circle cx="10" cy="13" r="1" fill="#fff"></circle>
    </svg>
  );
};

export const AppealYellowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FCD735"
        fillRule="evenodd"
        d="M19 5a2 2 0 00-2-2H3a2 2 0 00-2 2v8a2 2 0 002 2h3.172a2 2 0 011.414.586l1.707 1.707a1 1 0 001.414 0l1.707-1.707A2 2 0 0113.828 15H17a2 2 0 002-2V5z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
        d="M10 6v3.5"
      ></path>
      <circle cx="10" cy="13" r="1" fill="#fff"></circle>
    </svg>
  );
};

export const AppealRedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#F82222"
        fillRule="evenodd"
        d="M19 5a2 2 0 00-2-2H3a2 2 0 00-2 2v8a2 2 0 002 2h3.172a2 2 0 011.414.586l1.707 1.707a1 1 0 001.414 0l1.707-1.707A2 2 0 0113.828 15H17a2 2 0 002-2V5z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M8 7l4 4M12 7l-4 4"
      ></path>
    </svg>
  );
};

export const AddFileIcon = () => {
  return (
    <svg
      width="68"
      height="83"
      viewBox="0 0 68 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4" filter="url(#filter0_d_3533_228)">
        <rect
          x="26.5894"
          y="39.3594"
          width="35.7903"
          height="35.7903"
          rx="7.9534"
          stroke="#7B59FB"
          strokeWidth="1.7043"
          strokeLinecap="round"
          strokeDasharray="3.41 6.82"
        />
        <path
          d="M27.7241 3C37.1367 3 44.7671 10.6304 44.7671 20.043L44.7671 51.8566C44.7671 54.9941 42.2236 57.5376 39.0861 57.5376L10.6811 57.5376C7.54357 57.5376 5.0001 54.9941 5.0001 51.8566L5.0001 8.681C5.0001 5.54347 7.54357 3 10.6811 3L27.7241 3Z"
          fill="#D7CDFE"
        />
        <rect
          x="37.9537"
          y="35.9492"
          width="1.7043"
          height="26.1326"
          rx="0.85215"
          transform="rotate(90 37.9537 35.9492)"
          fill="#7B59FB"
        />
        <rect
          x="24.8873"
          y="28.5645"
          width="1.7043"
          height="13.0663"
          rx="0.85215"
          transform="rotate(90 24.8873 28.5645)"
          fill="#7B59FB"
        />
        <rect
          x="37.9537"
          y="43.3359"
          width="1.7043"
          height="26.1326"
          rx="0.85215"
          transform="rotate(90 37.9537 43.3359)"
          fill="#7B59FB"
        />
        <path
          d="M27.7262 3C37.1388 3 44.7692 10.6304 44.7692 20.043H33.4072C30.2696 20.043 27.7262 17.4995 27.7262 14.362V3Z"
          fill="#7B59FB"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3533_228"
          x="0.455293"
          y="0.727601"
          width="67.3214"
          height="82.0914"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.2724" />
          <feGaussianBlur stdDeviation="2.2724" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.88825 0 0 0 0 0.90125 0 0 0 0 0.95 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3533_228"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3533_228"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const SucceededAddFileIcon = () => {
  return (
    <svg
      width="42"
      height="48"
      viewBox="0 0 42 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6" filter="url(#filter0_d_3533_425)">
        <path
          d="M18.3794 0.749999C24.8016 0.75 30.0078 5.95623 30.0078 12.3784L30.0078 34.0849C30.0078 36.2256 28.2724 37.961 26.1317 37.961L6.75094 37.961C4.6102 37.961 2.87479 36.2256 2.87479 34.0849L2.87479 4.62614C2.87479 2.48541 4.6102 0.749999 6.75093 0.749999L18.3794 0.749999Z"
          fill="#D7CDFE"
        />
        <rect
          x="25.356"
          y="23.2314"
          width="1.16284"
          height="17.8303"
          rx="0.581422"
          transform="rotate(90 25.356 23.2314)"
          fill="#7B59FB"
        />
        <rect
          x="16.4419"
          y="18.1934"
          width="1.16284"
          height="8.91514"
          rx="0.581422"
          transform="rotate(90 16.4419 18.1934)"
          fill="#7B59FB"
        />
        <rect
          x="25.356"
          y="28.2715"
          width="1.16284"
          height="17.8303"
          rx="0.581422"
          transform="rotate(90 25.356 28.2715)"
          fill="#7B59FB"
        />
        <path
          d="M18.3804 0.75C24.8026 0.75 30.0088 5.95623 30.0088 12.3784H22.2565C20.1158 12.3784 18.3804 10.643 18.3804 8.50229V0.75Z"
          fill="#7B59FB"
        />
        <circle
          cx="31.1704"
          cy="36.4136"
          r="6.39564"
          fill="white"
          stroke="#7B59FB"
          strokeWidth="1.16284"
        />
        <path
          d="M27.6821 35.2502L30.3143 37.9731C30.9238 38.6036 31.9344 38.6036 32.5438 37.9731L38.923 31.374"
          stroke="#7B59FB"
          strokeWidth="1.16284"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3533_425"
          x="0.936713"
          y="0.75"
          width="40.5057"
          height="46.5168"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.93807" />
          <feGaussianBlur stdDeviation="0.969037" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.890196 0 0 0 0 0.901961 0 0 0 0 0.94902 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3533_425"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3533_425"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const RejectedAddFileIcon = () => {
  return (
    <svg
      width="42"
      height="47"
      viewBox="0 0 42 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3533_448)">
        <path
          d="M18.3794 0.749999C24.8016 0.75 30.0078 5.95623 30.0078 12.3784L30.0078 34.0849C30.0078 36.2256 28.2724 37.961 26.1317 37.961L6.75094 37.961C4.6102 37.961 2.87479 36.2256 2.87479 34.0849L2.87479 4.62614C2.87479 2.48541 4.6102 0.749999 6.75094 0.749999L18.3794 0.749999Z"
          fill="#F9B0BD"
        />
        <rect
          x="25.3555"
          y="23.2324"
          width="1.16284"
          height="17.8303"
          rx="0.581422"
          transform="rotate(90 25.3555 23.2324)"
          fill="#ED1C42"
        />
        <rect
          x="16.4409"
          y="18.1934"
          width="1.16284"
          height="8.91514"
          rx="0.581422"
          transform="rotate(90 16.4409 18.1934)"
          fill="#ED1C42"
        />
        <rect
          x="25.3555"
          y="28.2715"
          width="1.16284"
          height="17.8303"
          rx="0.581422"
          transform="rotate(90 25.3555 28.2715)"
          fill="#ED1C42"
        />
        <path
          d="M18.3794 0.75C24.8016 0.75 30.0078 5.95623 30.0078 12.3784H22.2555C20.1148 12.3784 18.3794 10.643 18.3794 8.50229V0.75Z"
          fill="#ED1C42"
        />
        <path
          d="M30.3927 27.936C30.6635 27.463 31.3365 27.463 31.6073 27.936L39.0717 40.9723C39.348 41.4549 38.9978 42.0437 38.4644 42.0437H23.5356C23.0022 42.0437 22.652 41.4549 22.9283 40.9723L30.3927 27.936Z"
          fill="white"
          stroke="#ED1C24"
          strokeWidth="1.1625"
        />
        <path
          d="M31.6255 36.7313C31.6255 37.0523 31.3653 37.3125 31.0442 37.3125C30.7232 37.3125 30.463 37.0523 30.463 36.7312L30.463 33.7981C30.463 33.4771 30.7232 33.2169 31.0442 33.2169C31.3653 33.2169 31.6255 33.4771 31.6255 33.7981L31.6255 36.7313Z"
          fill="#ED1C24"
        />
        <path
          d="M30.4635 38.8758C30.4635 38.5548 30.7237 38.2945 31.0447 38.2945C31.3657 38.2945 31.626 38.5548 31.626 38.8758C31.626 39.1968 31.3657 39.457 31.0447 39.457C30.7237 39.457 30.4635 39.1968 30.4635 38.8758Z"
          fill="#ED1C24"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3533_448"
          x="0.937286"
          y="0.75"
          width="40.7502"
          height="45.75"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.9375" />
          <feGaussianBlur stdDeviation="0.96875" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.890196 0 0 0 0 0.901961 0 0 0 0 0.94902 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3533_448"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3533_448"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L13 13"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13 1L1 13"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12V14C2 16.2091 3.79086 18 6 18H14C16.2091 18 18 16.2091 18 14V12"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10 3V13"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6 9L10 13L14 9"
        stroke="#00C3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
