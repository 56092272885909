import { AppTypography } from "Layout/Components";
import React from "react";
import styled from "styled-components";
import {
  LocationMark,
  ZoneMark,
  ParkingMeter,
  CalendarIcon,
} from "Assets/Icons/Icons";
import { CallLink, IconLabel, SummaryBox } from ".";
import { ICitation } from "API/Interfaces";
import { format } from "date-fns";
import AppealIcon, {
  appealIcons,
} from "Pages/CitationList/Components/Appeals/AppealIcon";
import { useTranslate } from "Hooks";

interface CitationDetailsProps {
  phoneNumber: string;
  citation: ICitation;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;
  width: 100%;
  ${(_) => _.theme.breakpoints.up("md")} {
    order: 2;
  }
`;

const CitationParams = styled.div`
  margin-top: 24px;
  margin-bottom: 44px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledAppTypography = styled(AppTypography)<{ $colors: string }>`
  padding: 5px;
  color: ${({ $colors }) => $colors};
`;

export const CitationDetails: React.FC<CitationDetailsProps> = ({
  phoneNumber,
  citation,
}) => {
  const t = useTranslate();
  return (
    <Container>
      <Wrapper>
        <AppTypography variant="caption">{citation.violation}</AppTypography>
        <AppealIcon citation={citation}>
          <StyledAppTypography
            weight={700}
            $colors={
              citation.appealStatus === null
                ? "#00C3FF"
                : appealIcons[citation.appealStatus].color
            }
          >
            {t("COMMON_FORMS_DISPUTE")}
          </StyledAppTypography>
        </AppealIcon>
      </Wrapper>

      <AppTypography variant="h3" weight="bold">
        {citation.number}
      </AppTypography>
      <CitationParams>
        <IconLabel Icon={LocationMark} label={citation.address} />
        <IconLabel Icon={ZoneMark} label={citation.zoneName} />
        <IconLabel Icon={ParkingMeter} label={citation.lotName} />
        <IconLabel
          Icon={CalendarIcon}
          label={format(new Date(citation.citationDate), "MM/dd/yyyy hh:mm a")}
        />
      </CitationParams>
      <SummaryBox summary={citation.paymentDetails} />
      <CallLink phone={phoneNumber} />
    </Container>
  );
};

export default CitationDetails;
