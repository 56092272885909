import { FormDatePicker, FormDatePickerProps } from "Layout/FormComponents";
import { TranslationKeys } from "Translation";
import { LabelWrapper } from "./LabelField";

export interface DateRangeFieldsProps {
  label: TranslationKeys;
  from: FormDatePickerProps;
  to: FormDatePickerProps;
}

const DateRangeFields: React.FC<DateRangeFieldsProps> = (props) => {
  return (
    <LabelWrapper label={props.label}>
      <FormDatePicker
        placeholderTranslation="COMMON_LABELS_FROM"
        {...props.from}
      />
      <FormDatePicker placeholderTranslation="COMMON_LABELS_TO" {...props.to} />
    </LabelWrapper>
  );
};

export default DateRangeFields;
