import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { ICarData, IFetchStatusObj } from "API/Interfaces";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "Routing/routes";
import { useFetchStatus } from "Hooks";
import CarDataServiceApi from "API/Services/CarDataService";
import { useLoaderContext } from "Contexts";
import { getErrorCode } from "Utils/utils";
import { ErrorCodes } from "API/Interfaces/Errors";

export interface CarDataContextType {
  carData: ICarData;
  setCarData: (arg: ICarData) => void;
  citationId: string;
  setCitationId: (arg: string) => void;
  status: IFetchStatusObj;
  setCitationFromLink: React.Dispatch<React.SetStateAction<boolean>>;
  getCarData: (arg: string, redirect?: boolean) => Promise<void>;
  setStatusInit: () => void;
  setStatusError: (errorCode?: ErrorCodes) => void;
  checkCarData: (arg: ICarData) => void;
  errorCode: ErrorCodes | null;
}

export const CarDataContext = createContext<CarDataContextType>(
  {} as CarDataContextType
);

export const useCarDataContext = () => useContext(CarDataContext);

export const CarDataProvider: React.FC = ({ children }) => {
  const { setLoading } = useLoaderContext();
  const [status, setStatus] = useFetchStatus();
  const [citationId, setCitationId] = useState<string>("");
  const [errorCode, setErrorCode] = useState<ErrorCodes | null>(null);
  const [citationFromLink, setCitationFromLink] = useState(false);
  const { pathname } = useLocation();
  const [sessionCarData] = useState(CarDataServiceApi.getStorageCar());
  const [carData, setCarData] = useState<ICarData>({
    plate: "",
    makeId: 0,
    stateId: "",
  });
  const { push } = useHistory();

  useEffect(() => {
    if (pathname === routes.citation || pathname === routes.pay) {
      sessionCarData && checkCarData(sessionCarData);
    }
    if (citationId) {
      setLoading(true);
      setCitationFromLink(true);
      getCarData(citationId)
        .catch(() => setLoading(false))
        .finally(() => setCitationFromLink(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citationId]);

  const getCarData = useCallback(
    async (citId: string, redirect = true): Promise<void> => {
      setStatus.loading();
      return await CarDataServiceApi.getCarData(citId)
        .then((res) => {
          setErrorCode(null);
          CarDataServiceApi.setStorageCar(res);
          setCarData({
            ...res,
            citationNumber: res?.isRental ? citId : undefined,
          });
          setStatus.success();
          redirect && push(routes.citation);
        })
        .catch((err) => {
          setErrorCode(getErrorCode(err));
          setStatus.error();
          setLoading(false);
          throw err;
        });
    },
    [push, setLoading, setStatus]
  );

  const checkCarData = async (carData: ICarData) => {
    setStatus.loading();
    return await CarDataServiceApi.checkCarData(carData)
      .then((res) => {
        setErrorCode(null);
        CarDataServiceApi.setStorageCar(res);
        setCarData(res);
        setStatus.success();
        push(routes.citation);
      })
      .catch((err) => {
        setErrorCode(getErrorCode(err));
        setStatus.error();
        setLoading(false);
      });
  };

  //This funtion will be run when user return to main page
  const setStatusInit = useCallback(() => {
    setStatus.init();
    setCarData({ plate: "", makeId: 0, stateId: "" });
    window.sessionStorage.clear();
  }, [setStatus]);

  const setStatusError = useCallback(
    (errorCode?: ErrorCodes) => {
      setErrorCode(errorCode || null);
      setStatus.error();
      setCarData({ plate: "", makeId: 0, stateId: "" });
      window.sessionStorage.clear();
    },
    [setStatus]
  );

  return (
    <CarDataContext.Provider
      value={{
        carData,
        setCarData,
        citationId,
        setCitationId,
        status,
        setCitationFromLink,
        getCarData,
        setStatusInit,
        setStatusError,
        checkCarData,
        errorCode,
      }}
    >
      {!citationFromLink && children}
    </CarDataContext.Provider>
  );
};
