import { IAppealDetails } from "API/Interfaces";
import CitationServiceApi from "API/Services/CitationServiceApi";
import { useErrorContext } from "Contexts/ErrorContext";
import { useTranslate } from "Hooks";
import React, { useEffect, useState } from "react";
import { isErrorCode } from "Utils/utils";
import DisputeDetails from "../Components/Appeals/DisputeDetails";

const useAppealDetails = () => {
  const t = useTranslate();
  const [isOpen, setOpen] = useState(false);
  const [appealDetails, setAppealDetails] = useState<IAppealDetails | null>(
    null
  );
  const { setError } = useErrorContext();

  useEffect(() => {
    !isOpen && setAppealDetails(null);
  }, [isOpen]);

  const showAppealDetailsDialog = (id: string) => {
    setOpen(true);
    CitationServiceApi.getAppealDetails(id)
      .then(setAppealDetails)
      .catch((err) => {
        setError(
          isErrorCode(err, "ResourceNotFound")
            ? t("COMMON_DIALOGS_APPEALNOTFOUND")
            : undefined
        );
        setOpen(false);
      });
  };

  const AppealDetailsDialog = (
    <DisputeDetails
      cancelButton={t("COMMON_BUTTONS_CLOSE")}
      cancelAction={() => setOpen(false)}
      appealDetails={appealDetails}
      open={isOpen}
    />
  );

  return { showAppealDetailsDialog, AppealDetailsDialog };
};

export default useAppealDetails;
