import React from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  Suggestion,
} from "react-places-autocomplete";
import { InputAdornment, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect } from "react";
import { getAddressFields } from "Utils/utils";
import { GeocodeResult } from "API/Interfaces";
import { useFormikContext } from "formik";
import { useTranslate } from "Hooks";
import { SearchIcon } from "Assets/Icons";
import { TranslationKeys } from "Translation";
import { useDictionariesContext } from "Contexts";
import { paymentDetailFieldNames as f } from ".";

interface AutocompleteProps {
  placeholder?: TranslationKeys;
}

const FormAddressAutocomplete: React.FC<AutocompleteProps> = ({
  placeholder = "COMMON_FORMS_ADDRESSLOOKUP",
}) => {
  const [value, setValue] = React.useState<Suggestion | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const { setFieldValue } = useFormikContext();
  const { dictionaries } = useDictionariesContext();
  const t = useTranslate();
  useEffect(() => {
    if (value) {
      geocodeByPlaceId(value.placeId)
        .then(async (res) => {
          const { street, state, postalCode, county, city, countryCode } =
            getAddressFields(res[0] as unknown as GeocodeResult);
          const country = dictionaries.countryCodes.find(
            (c) => c.countryCode === countryCode
          );
          if (country) {
            setFieldValue(f.country, country.id);
            setFieldValue(f.county, county);
            setFieldValue(f.city, city);
            setFieldValue(f.state, state);
            setFieldValue(f.street1, street);
            setFieldValue(f.zipCode, postalCode);
          }
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <PlacesAutocomplete value={inputValue} onChange={setInputValue}>
      {({ getInputProps, suggestions, loading }) => {
        return (
          <Autocomplete
            autoComplete
            includeInputInList
            filterSelectedOptions
            filterOptions={(x) => x}
            size="small"
            loading={loading}
            options={suggestions.map((suggestion) => suggestion)}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            value={value}
            onChange={(_: any, newValue: any) => {
              setValue(newValue);
            }}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment style={{ paddingLeft: 8 }} position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                {...getInputProps({
                  placeholder: t(placeholder),
                })}
                variant="outlined"
                fullWidth
              />
            )}
          />
        );
      }}
    </PlacesAutocomplete>
  );
};

export default FormAddressAutocomplete;
