import { CallIcon } from "Assets/Icons";
import { useTranslate } from "Hooks";
import { AppTypography } from "Layout/Components";
import React from "react";
import styled from "styled-components";

interface CallLinkProps {
  phone: string;
}

const Link = styled.a`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  color: ${(_) => _.theme.palette.primary.main};
  text-decoration: none;
  > * {
    margin: 0 5px;
  }
  ${(_) => _.theme.breakpoints.up("md")} {
    color: ${(_) => _.theme.palette.neutral.main};
    pointer-events: none;
    cursor: default;
  }
`;

export const CallLink: React.FC<CallLinkProps> = ({ phone }) => {
  const t = useTranslate();
  return (
    <Link href={`tel:${phone}`}>
      <CallIcon />
      <AppTypography variant="body1">
        {t("COMMON_LABELS_FORAUESTIONSCALL")}: {phone}
      </AppTypography>
    </Link>
  );
};

export default CallLink;
