import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { TranslationKeys } from "Translation";
import { useTranslate } from "Hooks";

export interface FormCheckboxProps extends CheckboxProps {
  fieldName: string;
  labelTranslation?: TranslationKeys;
}

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)<{ $error: boolean }>`
  padding: 4px 9px;
  > span {
    color: ${({ $error }) => ($error ? "#f44336" : undefined)};
  }
`;

export const FormCheckbox: React.FC<FormCheckboxProps> = (props) => {
  const t = useTranslate();
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<any>();
  const { fieldName, labelTranslation, color, required, ...rest } = props;

  return (
    <Main>
      <FormControlLabel
        control={
          <StyledCheckbox
            checked={values[fieldName]}
            onChange={handleChange}
            onBlur={handleBlur}
            name={fieldName}
            $error={!!errors[fieldName] && !!touched[fieldName]}
            color={color || "primary"}
            {...rest}
          />
        }
        label={
          <span
            style={{
              color:
                !!errors[fieldName] && !!touched[fieldName]
                  ? "#f44336"
                  : "inherit",
            }}
          >
            {t(labelTranslation)}
          </span>
        }
      />
    </Main>
  );
};

export default FormCheckbox;
