import { GeocodeResult } from "API/Interfaces";
import { ErrorCodes } from "../API/Interfaces/Errors";

export const stringVal = (val?: string | null | undefined) => {
  if (!val) return "";
  return val;
};
export const getAddressFields = (arg?: GeocodeResult, withLocation = true) => {
  if (!arg)
    return {
      county: "",
      city: "",
      state: "",
      street: "",
      postalCode: "",
      countryCode: "",
    };
  let streetNumber = stringVal(
    arg.address_components.find((el) => el.types.includes("street_number"))
      ?.long_name
  );
  streetNumber += streetNumber ? " " : "";
  const streetAddress = stringVal(
    arg.address_components.find((el) => el.types.includes("route"))?.long_name
  );
  const county = arg.address_components.find((el) =>
    el.types.includes("administrative_area_level_2")
  )?.long_name;
  const state = arg.address_components.find((el) =>
    el.types.includes("administrative_area_level_1")
  )?.long_name;
  const city = arg.address_components.find((el) =>
    el.types.includes("locality")
  )?.long_name;
  const postalCode = arg.address_components.find((el) =>
    el.types.includes("postal_code")
  )?.long_name;
  const location = withLocation && {
    lat: arg.geometry.location.lat(),
    lng: arg.geometry.location.lng(),
  };
  const countryCode =
    arg.address_components.find((el) => el.types.includes("country"))?.[
      "short_name"
    ] || "";
  return {
    county: stringVal(county),
    city: stringVal(city),
    state: stringVal(state),
    postalCode: stringVal(postalCode),
    street: streetNumber + streetAddress,
    location,
    countryCode,
  };
};
export const getErrorCode = (error: any): ErrorCodes => {
  if (error?.response?.data?.errors[0]?.errorCode) {
    return error.response.data.errors[0].errorCode as number;
  }
  return ErrorCodes.OtherError;
};
export const isErrorCode = (err: any, arg: keyof typeof ErrorCodes) => {
  const errorCode = err?.errorCode ? err.errorCode : getErrorCode(err);
  return errorCode === ErrorCodes[arg];
};

//Use instead of toIsoString to avoid UTC offset
export const toLocalIsoString = (date: Date | null): string | null => {
  if (date) {
    const tzoffset = new Date(date).getTimezoneOffset() * 60000;
    return new Date(date.getTime() - tzoffset).toISOString().slice(0, -1);
  } else return null;
};

export const getTimeToCountdown = (timeUTC: number): number => {
  const currentDate = new Date();
  let time: number =
    currentDate.getTime() + currentDate.getTimezoneOffset() * 60000 - timeUTC;

  return Math.abs(time);
};
