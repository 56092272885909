import { apiClient, IResponseApi, urls } from "API/Client";
import {
  IAppealDetails,
  IDisputeData,
  ILocation,
  IPagesExpiration,
} from "API/Interfaces";
import { toLocalIsoString } from "Utils/utils";

const CitationServiceApi = {
  /*
  API: _[GET] /citations_
  DOC: https://confluence.lbpro.pl/pages/viewpage.action?pageId=133007161
  */
  getCitations: async (
    startingPageNumber: number,
    search: string,
    params: any
  ) => {
    if (params?.amountFrom) params.amountFrom *= 100;
    if (params?.amountTo) params.amountTo *= 100;
    if (params?.issueDateFrom)
      params.issueDateFrom = toLocalIsoString(params.issueDateFrom);
    if (params?.issueDateTo)
      params.issueDateTo = toLocalIsoString(params.issueDateTo);
    const res = await apiClient.get<IResponseApi<IPagesExpiration<ILocation>>>(
      urls.citations.root,
      {
        params: {
          startingPageNumber,
          ...params,
        },
      }
    );
    res.data.data.expirationDateUTC = new Date(res.data.data.expirationDateUTC);
    return res.data.data;
  },
  /*
  API: [GET] /citations/details
  DOC: https://confluence.lbpro.pl/pages/viewpage.action?pageId=138346645
  */
  getSelectedCitations: async (ids: string[]): Promise<ILocation> => {
    const params = new URLSearchParams(ids.map((id) => ["CitationIds", id]));
    const res = await apiClient.get<{ data: ILocation }>(
      urls.citations.details,
      {
        params,
      }
    );
    res.data.data.expirationDateUTC = new Date(res.data.data.expirationDateUTC);
    return res.data.data;
  },
  getStorageCitations: (): ILocation | null => {
    const citations = window.sessionStorage.getItem("Citations");
    return citations ? (JSON.parse(citations) as ILocation) : null;
  },
  setStorageCitations: (citations: ILocation): void =>
    window.sessionStorage.setItem("Citations", JSON.stringify(citations)),
  clearStorageCitations: (): void =>
    window.sessionStorage.removeItem("Citations"),
  setNewAppeal: async (id: string, data: IDisputeData): Promise<void> => {
    await apiClient.post<void>(urls.citations.addAppeal(id), data);
  },
  getAppealDetails: async (id: string) => {
    const res = await apiClient.get<IResponseApi<IAppealDetails>>(
      urls.citations.appealDetails(id)
    );
    res.data.data.appeal.createdDate = new Date(
      res.data.data.appeal.createdDate
    );
    if (res.data.data.answer)
      res.data.data.answer.createdDate = new Date(
        res.data.data.answer.createdDate
      );

    return res.data.data;
  },
};

export default CitationServiceApi;
