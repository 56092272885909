import { useCitationContext } from "Contexts";
import { checkUpchargeSummary } from "../Utils/utils";

const useSummaryUpcharges = () => {
  const { locations, selectedLocation } = useCitationContext();

  return checkUpchargeSummary(locations, selectedLocation);
};

export default useSummaryUpcharges;
