import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

interface IconLabelProps {
  Icon: () => JSX.Element;
  label: string;
  column?: boolean;
}

const Wrapper = styled.div<{ $column?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${(_) => (_.$column ? "column" : "row")};
  padding: 4px 0;
  word-break: break-all;
  > div {
    margin-bottom: ${(_) => (_.$column ? "4px" : 0)};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  min-width: 30px;
`;

export const IconLabel: React.FC<IconLabelProps> = (props) => {
  const { Icon, label, column } = props;
  return (
    <Wrapper $column={column} {...props}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Typography>{label}</Typography>
    </Wrapper>
  );
};

export default IconLabel;
