import * as yup from "yup";
import { i18nt } from "Translation";
import { IDisputeData, IPaymentData } from "API/Interfaces";

export interface IPaymentDetailForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneCountryCodeId: number | string;
  street1: string;
  street2: string | null;
  zipCode: string;
  county: string;
  city: string;
  state: string;
  country: string;
  isRegisteredOwner: boolean;
}

export interface IDisputeForm extends IPaymentDetailForm {
  reason: string;
}

export type PaymentDetailsKeys = keyof IPaymentDetailForm;

export type AppealFormKeys = keyof IDisputeForm;

export interface PaymentDetailFieldNamesType {
  firstName: PaymentDetailsKeys;
  lastName: PaymentDetailsKeys;
  email: PaymentDetailsKeys;
  phoneNumber: PaymentDetailsKeys;
  phoneCountryCodeId: PaymentDetailsKeys;
  street1: PaymentDetailsKeys;
  street2: PaymentDetailsKeys;
  zipCode: PaymentDetailsKeys;
  county: PaymentDetailsKeys;
  city: PaymentDetailsKeys;
  state: PaymentDetailsKeys;
  country: PaymentDetailsKeys;
  isRegisteredOwner: PaymentDetailsKeys;
}

export interface AppealFormFieldNamesType extends PaymentDetailFieldNamesType {
  reason: AppealFormKeys;
}

export const paymentDetailFieldNames: PaymentDetailFieldNamesType = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  phoneNumber: "phoneNumber",
  phoneCountryCodeId: "phoneCountryCodeId",
  street1: "street1",
  street2: "street2",
  zipCode: "zipCode",
  county: "county",
  city: "city",
  state: "state",
  country: "country",
  isRegisteredOwner: "isRegisteredOwner",
};

export const appealFormFieldNames: AppealFormFieldNamesType = {
  ...paymentDetailFieldNames,
  reason: "reason",
};

const stringRequireMaxVaildation = (max: 50 | 100 | 200 | 500) =>
  yup
    .string()
    .max(max, i18nt(`COMMON_FORMS_MAXCHARACTER${max}`))
    .required(i18nt("COMMON_FORMS_FIELDISREQUIRED"));

export const validationSchema = {
  firstName: stringRequireMaxVaildation(200),
  lastName: stringRequireMaxVaildation(200),
  email: yup
    .string()
    .email(i18nt(`COMMON_FORMS_ENTERVALIDEMAIL`))
    .max(200, i18nt(`COMMON_FORMS_MAXCHARACTER200`))
    .required(i18nt("COMMON_FORMS_FIELDISREQUIRED")),
  phoneNumber: yup.number().typeError(i18nt("COMMON_FORMS_NUMBERTYPE")),
  phoneCountryCodeId: yup
    .number()
    .required(i18nt("COMMON_FORMS_FIELDISREQUIRED")),
  street1: stringRequireMaxVaildation(200),
  street2: yup
    .string()
    .max(200, i18nt("COMMON_FORMS_MAXCHARACTER200"))
    .nullable(),
  zipCode: stringRequireMaxVaildation(50),
  county: stringRequireMaxVaildation(50),
  city: stringRequireMaxVaildation(200),
  state: stringRequireMaxVaildation(50),
  country: yup.string().required(i18nt("COMMON_FORMS_FIELDISREQUIRED")),
  isRegisteredOwner: yup.boolean(),
};

export const appealFormValidationSchema = {
  ...validationSchema,
  reason: stringRequireMaxVaildation(500),
  isRegisteredOwner: yup.boolean().isTrue(),
};

export const initialData: IPaymentDetailForm = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  phoneCountryCodeId: "1",
  street1: "",
  street2: null,
  zipCode: "",
  county: "",
  city: "",
  state: "",
  country: "",
  isRegisteredOwner: false,
};

export const initialAppealFormData = {
  ...initialData,
  reason: "",
};

export const paymentFormToRequest = (
  f: IPaymentDetailForm,
  citations: string[],
  plate: string
): IPaymentData => ({
  contactDetails: {
    firstName: f.firstName,
    lastName: f.lastName,
    addressDetails: {
      street1: f.street1,
      street2: f.street2 || null,
      zipCode: f.zipCode,
      county: f.county,
      city: f.city,
      state: f.state,
      country: Number(f.country),
    },
    contact: {
      emailAddress: f.email,
      mobilePhoneCountryCodeId: Number(f.phoneCountryCodeId),
      mobilePhoneNumber: f.phoneNumber,
    },
  },
  isRegisteredOwner: f.isRegisteredOwner,
  citationIds: citations,
  plate,
});

export const appealFormToRequest = (
  f: IDisputeForm,
  attachmentIds: string[]
): IDisputeData => ({
  firstName: f.firstName,
  lastName: f.lastName,
  reason: f.reason,
  addressDetails: {
    street1: f.street1,
    street2: f.street2 || null,
    zipCode: f.zipCode,
    county: f.county,
    city: f.city,
    state: f.state,
    country: Number(f.country),
  },
  contact: {
    emailAddress: f.email,
    mobilePhoneCountryCodeId: Number(f.phoneCountryCodeId),
    mobilePhoneNumber: f.phoneNumber,
  },
  attachmentIds: attachmentIds,
});
