import { useTranslate } from "Hooks";
import { FormCurrencyInput } from "./FormCurrencyInput";
import { TranslationKeys } from "Translation";
import { LabelWrapper } from "./LabelField";
import CloseIcon from "@material-ui/icons/Close";
import { useFormikContext } from "formik";

export interface LabelFromToProps {
  label: TranslationKeys;
  from: string;
  to: string;
}

const LabelFromTo: React.FC<LabelFromToProps> = ({ label, from, to }) => {
  const t = useTranslate();
  const { setFieldValue, values, initialValues } = useFormikContext<any>();
  return (
    <LabelWrapper label={label}>
      <FormCurrencyInput
        fieldName={from}
        textFieldProps={{
          placeholder: t("COMMON_LABELS_FROMDOLLAR"),
          endAdornmentIcon: (
            <CloseIcon
              color="primary"
              style={{
                cursor: "pointer",
                pointerEvents: values[from] ? "auto" : "none",
                opacity: values[from] ? 1 : 0,
              }}
              onClick={() => setFieldValue(from, initialValues[from])}
            />
          ),
        }}
      />
      <FormCurrencyInput
        fieldName={to}
        textFieldProps={{
          placeholder: t("COMMON_LABELS_TODOLLAR"),
          endAdornmentIcon: (
            <CloseIcon
              color="primary"
              style={{
                cursor: "pointer",
                pointerEvents: values[to] ? "auto" : "none",
                opacity: values[to] ? 1 : 0,
              }}
              onClick={() => setFieldValue(to, initialValues[to])}
            />
          ),
        }}
      />
    </LabelWrapper>
  );
};

export default LabelFromTo;
